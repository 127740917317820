import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  MenuItem,
  Select,
  Toolbar as MuiToolbar,
  Button,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  IconButton,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import {
  ViewState,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  DragDropProvider,
  GroupingPanel,
  WeekView,
  DayView,
  MonthView,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useAdmin } from "../../context/admin_context";
import { blue, orange } from "@mui/material/colors";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const demoData = [
  {
    title: "Website Re-Design Plan",
    priorityId: 2,
    startDate: new Date(2018, 3, 30, 9, 30),
    endDate: new Date(2018, 4, 3, 11, 30),
    id: 0,
  },
  {
    title: "Book Flights to San Fran for Sales Trip",
    priorityId: 1,
    startDate: new Date(2018, 4, 10, 10, 0),
    endDate: new Date(2018, 4, 10, 12, 0),
    id: 1,
  },
  {
    title: "Install New Router in Dev Room",
    priorityId: 3,
    startDate: new Date(2018, 4, 7, 13),
    endDate: new Date(2018, 4, 7, 15, 30),
    id: 2,
  },
  {
    title: "New Brochures",
    priorityId: 2,
    startDate: new Date(2018, 4, 7, 13, 0),
    endDate: new Date(2018, 4, 7, 15, 15),
    id: 5,
  },
  {
    title: "Install New Database",
    priorityId: 1,
    startDate: new Date(2018, 4, 8, 9),
    endDate: new Date(2018, 4, 8, 12, 15),
    id: 6,
  },
  {
    title: "Approve New Online Marketing Strategy",
    priorityId: 3,
    startDate: new Date(2018, 4, 9, 12, 0),
    endDate: new Date(2018, 4, 9, 14, 0),
    id: 7,
  },
  {
    title: "Upgrade Personal Computers",
    priorityId: 1,
    startDate: new Date(2018, 4, 7, 9),
    endDate: new Date(2018, 4, 7, 11, 30),
    id: 8,
  },
  {
    title: "Prepare 2018 Marketing Plan",
    priorityId: 2,
    startDate: new Date(2018, 4, 10, 11, 0),
    endDate: new Date(2018, 4, 10, 13, 30),
    id: 9,
  },
  {
    title: "Brochure Design Review",
    priorityId: 3,
    startDate: new Date(2018, 4, 9, 11, 0),
    endDate: new Date(2018, 4, 9, 13, 30),
    id: 10,
  },
  {
    title: "Upgrade Server Hardware",
    priorityId: 1,
    startDate: new Date(2018, 4, 11, 9, 0),
    endDate: new Date(2018, 4, 11, 15, 0),
    id: 11,
  },
  {
    title: "Submit New Website Design",
    priorityId: 2,
    startDate: new Date(2018, 4, 11, 16, 30),
    endDate: new Date(2018, 4, 11, 18, 0),
    id: 12,
  },
  {
    title: "Launch New Website",
    priorityId: 3,
    startDate: new Date(2018, 4, 11, 12, 20),
    endDate: new Date(2018, 4, 11, 14, 0),
    id: 13,
  },
  {
    title: "Google AdWords Strategy",
    priorityId: 1,
    startDate: new Date(2018, 4, 14, 9, 0, 0),
    endDate: new Date(2018, 4, 14, 12, 0, 0),
    id: 14,
  },
  {
    title: "Rollout of New Website and Marketing Brochures",
    priorityId: 1,
    startDate: new Date(2018, 4, 14, 13, 0, 0),
    endDate: new Date(2018, 4, 14, 15, 30, 0),
    id: 15,
  },
  {
    title: "Non-Compete Agreements",
    priorityId: 3,
    startDate: new Date(2018, 4, 15, 13, 0, 0),
    endDate: new Date(2018, 4, 15, 15, 45, 0),
    id: 16,
  },
  {
    title: "Approve Hiring of John Jeffers",
    priorityId: 2,
    startDate: new Date(2018, 4, 15, 9, 0, 0),
    endDate: new Date(2018, 4, 15, 12, 0, 0),
    id: 17,
  },
  {
    title: "Update NDA Agreement",
    priorityId: 1,
    startDate: new Date(2018, 4, 15, 11, 0, 0),
    endDate: new Date(2018, 4, 15, 14, 15, 0),
    id: 18,
  },
  {
    title: "Submit Signed NDA",
    priorityId: 3,
    startDate: new Date(2018, 4, 16, 13, 0, 0),
    endDate: new Date(2018, 4, 16, 15, 0, 0),
    id: 21,
  },
  {
    title: "Review Revenue Projections",
    priorityId: 2,
    startDate: new Date(2018, 4, 16, 11, 0, 0),
    endDate: new Date(2018, 4, 16, 14, 0, 0),
    id: 22,
  },
  {
    title: "Comment on Revenue Projections",
    priorityId: 2,
    startDate: new Date(2018, 4, 14, 10, 0, 0),
    endDate: new Date(2018, 4, 14, 13, 0, 0),
    id: 23,
  },
  {
    title: "Provide New Health Insurance Docs",
    priorityId: 3,
    startDate: new Date(2018, 4, 18, 12, 0, 0),
    endDate: new Date(2018, 4, 18, 15, 0, 0),
    id: 24,
  },
  {
    title: "Review Changes to Health Insurance Coverage",
    priorityId: 2,
    startDate: new Date(2018, 4, 17, 9, 0, 0),
    endDate: new Date(2018, 4, 17, 13, 0, 0),
    id: 25,
  },
  {
    title: "Review Training Course for any Ommissions",
    priorityId: 1,
    startDate: new Date(2018, 4, 17, 11, 0, 0),
    endDate: new Date(2018, 4, 17, 14, 0, 0),
    id: 26,
  },
  {
    title: "Website Re-Design Plan",
    priorityId: 3,
    startDate: new Date(2018, 4, 21, 9, 30),
    endDate: new Date(2018, 4, 21, 11, 30),
    id: 27,
  },
  {
    title: "Book Flights to San Fran for Sales Trip",
    priorityId: 1,
    startDate: new Date(2018, 4, 24, 10, 0),
    endDate: new Date(2018, 4, 24, 12, 0),
    id: 28,
  },
  {
    title: "Install New Router in Dev Room",
    priorityId: 1,
    startDate: new Date(2018, 4, 21, 13),
    endDate: new Date(2018, 4, 21, 15, 30),
    id: 29,
  },
  {
    title: "Approve Personal Computer Upgrade Plan",
    priorityId: 3,
    startDate: new Date(2018, 4, 22, 10, 0),
    endDate: new Date(2018, 4, 22, 11, 0),
    id: 30,
  },
  {
    title: "Final Budget Review",
    priorityId: 2,
    startDate: new Date(2018, 4, 22, 12, 0),
    endDate: new Date(2018, 4, 22, 13, 35),
    id: 31,
  },
  {
    title: "New Brochures",
    priorityId: 2,
    startDate: new Date(2018, 4, 21, 13, 0),
    endDate: new Date(2018, 4, 21, 15, 15),
    id: 32,
  },
  {
    title: "Install New Database",
    priorityId: 3,
    startDate: new Date(2018, 4, 22, 9),
    endDate: new Date(2018, 4, 22, 12, 15),
    id: 33,
  },
  {
    title: "Approve New Online Marketing Strategy",
    priorityId: 2,
    startDate: new Date(2018, 4, 23, 12, 0),
    endDate: new Date(2018, 4, 23, 14, 0),
    id: 34,
  },
  {
    title: "Upgrade Personal Computers",
    priorityId: 1,
    startDate: new Date(2018, 4, 21, 9),
    endDate: new Date(2018, 4, 21, 11, 30),
    id: 35,
  },
  {
    title: "Prepare 2018 Marketing Plan",
    priorityId: 3,
    startDate: new Date(2018, 4, 24, 11, 0),
    endDate: new Date(2018, 4, 24, 13, 30),
    id: 36,
  },
  {
    title: "Brochure Design Review",
    priorityId: 1,
    startDate: new Date(2018, 4, 23, 11, 0),
    endDate: new Date(2018, 4, 23, 13, 30),
    id: 37,
  },
  {
    title: "Create Icons for Website",
    priorityId: 2,
    startDate: new Date(2018, 4, 25, 10, 0),
    endDate: new Date(2018, 4, 25, 11, 30),
    id: 38,
  },
  {
    title: "Upgrade Server Hardware",
    priorityId: 1,
    startDate: new Date(2018, 4, 25, 9, 0),
    endDate: new Date(2018, 4, 25, 15, 0),
    id: 39,
  },
  {
    title: "Submit New Website Design",
    priorityId: 3,
    startDate: new Date(2018, 4, 25, 16, 30),
    endDate: new Date(2018, 4, 25, 18, 0),
    id: 40,
  },
  {
    title: "Launch New Website",
    priorityId: 2,
    startDate: new Date(2018, 4, 25, 12, 20),
    endDate: new Date(2018, 4, 25, 14, 0),
    id: 41,
  },
  {
    title: "Google AdWords Strategy",
    priorityId: 1,
    startDate: new Date(2018, 4, 28, 9, 0, 0),
    endDate: new Date(2018, 4, 28, 12, 0, 0),
    id: 42,
  },
  {
    title: "Rollout of New Website and Marketing Brochures",
    priorityId: 3,
    startDate: new Date(2018, 4, 28, 13, 0, 0),
    endDate: new Date(2018, 4, 28, 15, 30, 0),
    id: 43,
  },
  {
    title: "Non-Compete Agreements",
    priorityId: 2,
    startDate: new Date(2018, 4, 29, 13, 0, 0),
    endDate: new Date(2018, 4, 29, 15, 45, 0),
    id: 44,
  },
  {
    title: "Approve Hiring of John Jeffers",
    priorityId: 2,
    startDate: new Date(2018, 4, 29, 9, 0, 0),
    endDate: new Date(2018, 4, 29, 12, 0, 0),
    id: 45,
  },
  {
    title: "Update NDA Agreement",
    priorityId: 3,
    startDate: new Date(2018, 4, 29, 11, 0, 0),
    endDate: new Date(2018, 4, 29, 14, 15, 0),
    id: 46,
  },
  {
    title: "Update Employee Files with New NDA",
    priorityId: 1,
    startDate: new Date(2018, 5, 1, 9, 0, 0),
    endDate: new Date(2018, 5, 1, 11, 45, 0),
    id: 47,
  },
  {
    title: "Submit Questions Regarding New NDA",
    priorityId: 1,
    startDate: new Date(2018, 4, 30, 10, 0, 0),
    endDate: new Date(2018, 4, 30, 11, 30, 0),
    id: 48,
  },
  {
    title: "Submit Signed NDA",
    priorityId: 3,
    startDate: new Date(2018, 4, 30, 13, 0, 0),
    endDate: new Date(2018, 4, 30, 15, 0, 0),
    id: 49,
  },
  {
    title: "Review Revenue Projections",
    priorityId: 2,
    startDate: new Date(2018, 4, 30, 11, 0, 0),
    endDate: new Date(2018, 4, 30, 14, 0, 0),
    id: 50,
  },
  {
    title: "Comment on Revenue Projections",
    priorityId: 2,
    startDate: new Date(2018, 4, 28, 10, 0, 0),
    endDate: new Date(2018, 4, 28, 13, 0, 0),
    id: 51,
  },
  {
    title: "Provide New Health Insurance Docs",
    priorityId: 3,
    startDate: new Date(2018, 5, 1, 12, 0, 0),
    endDate: new Date(2018, 5, 1, 15, 0, 0),
    id: 52,
  },
  {
    title: "Review Changes to Health Insurance Coverage",
    priorityId: 2,
    startDate: new Date(2018, 4, 31, 9, 0, 0),
    endDate: new Date(2018, 4, 31, 13, 0, 0),
    id: 53,
  },
  {
    title: "Review Training Course for any Ommissions",
    priorityId: 1,
    startDate: new Date(2018, 4, 31, 11, 0, 0),
    endDate: new Date(2018, 4, 31, 14, 0, 0),
    id: 54,
  },
  {
    title: "Approve New Online Marketing Strategy",
    priorityId: 3,
    startDate: new Date(2018, 4, 28, 12, 0),
    endDate: new Date(2018, 4, 28, 14, 0),
    allDay: true,
    id: 55,
  },
  {
    title: "Install New Router in Dev Room",
    priorityId: 1,
    startDate: new Date(2018, 4, 29, 13),
    endDate: new Date(2018, 4, 29, 15, 30),
    allDay: true,
    id: 56,
  },
  {
    title: "Google AdWords Strategy",
    priorityId: 1,
    startDate: new Date(2018, 4, 31, 9, 0, 0),
    endDate: new Date(2018, 4, 31, 12, 0, 0),
    allDay: true,
    id: 57,
  },
  {
    title: "Review Changes to Health Insurance Coverage",
    priorityId: 2,
    startDate: new Date(2018, 5, 1, 9, 0, 0),
    endDate: new Date(2018, 5, 1, 13, 0, 0),
    allDay: true,
    id: 58,
  },
];

const priorityData = [
  {
    text: "Marcos Hardy",
    id: 1,
  },
  {
    text: "Alex Hardy",
    id: 2,
  },
  {
    text: "Employee Hardy",
    id: 3,
  },
  {
    text: "Maria Hardy",
    id: 4,
  },
  {
    text: "Cleaner",
    id: 5,
  },
];

const SingleProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentViewName, setCurrentViewName] = useState("Week");
  const [data, setData] = useState(demoData);
  const [filterExpanded, setFilterExpanded] = useState([false, false]);
  const [dateValue, setDateValue] = useState(new Date("2014-08-18T21:11:54"));
  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();

  const { locations, selectedStore, setSelectedStore } = useAdmin();

  const onSelectStore = (e) => {
    setSelectedStore(e.target.value);
  };

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  const resourceData = [
    {
      fieldName: "priorityId",
      title: "Priority",
      instances: [
        { text: "Marcos Hardy", id: 1, color: blue },
        { text: "Alex Hardy", id: 2, color: orange },
        { text: "Employee Hardy", id: 2, color: orange },
        { text: "High Priority", id: 2, color: orange },
        { text: "High Priority", id: 2, color: orange },
        { text: "High Priority", id: 2, color: orange },
        { text: "High Priority", id: 2, color: orange },
      ],
    },
  ];

  const grouping = [
    {
      resourceName: "priorityId",
    },
  ];

  const groupOrientation = (viewName) => viewName.split(" ")[0];

  const commitChanges = ({ added, changed, deleted }) => {
    setData((oldS) => {
      let newState = [...oldS];
      if (added) {
        const startingAddedId =
          newState.length > 0 ? newState[newState.length - 1].id + 1 : 0;
        newState = [...newState, { id: startingAddedId, ...added }];
      }
      if (changed) {
        newState = newState.map((appointment) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment
        );
      }
      if (deleted !== undefined) {
        newState = newState.filter((appointment) => appointment.id !== deleted);
      }
      return newState;
    });
  };

  const DayScaleCell = (props) => {
    const { startDate, today } = props;
    if (today) {
      return <WeekView.DayScaleCell {...props} />;
    }
    if (startDate.getDay() === 0 || startDate.getDay() === 6) {
      return <WeekView.DayScaleCell {...props} />;
    }
    return <WeekView.DayScaleCell {...props} />;
  };

  const TimeScaleComp = (props) => {
    console.log("props:", props);

    return (
      <WeekView.TimeScaleLayout
        {...props}
        style={{
          border: "1px solid red",
          display: "none",
          width: 0,
        }}
        groupOrientation="Horizontal"
        showAllDayTitle={false}></WeekView.TimeScaleLayout>
    );
  };
  const TimeScaleTickCellComp = (props) => {
    console.log("props:", props);

    return (
      <WeekView.Layout
        {...props}
        style={{
          border: "1px solid red",
        }}></WeekView.Layout>
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <div className="nav-container">
          <MuiToolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            {/* <h4 style={{ marginLeft: "15%", color: "#444" }}>
              Select Epos Group:
            </h4> */}
          </MuiToolbar>
        </div>
        <div className="content-container">
          <div className="content-left">
            <div className="scheduler-header">
              <Select
                variant="filled"
                className="select"
                value={selectedStore || ""}
                label="Select Store"
                onChange={onSelectStore}>
                {locations &&
                  locations?.map((store, i) => {
                    return (
                      <MenuItem key={i} value={store.store_id}>
                        {store?.store_id}
                      </MenuItem>
                    );
                  })}
              </Select>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // label="Select Date"
                  inputFormat="MM/dd/yyyy"
                  value={dateValue}
                  onChange={(val) => {
                    setDateValue(val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="select"
                      variant="filled"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              <Select
                variant="filled"
                className="select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentViewName}
                onChange={(e) => setCurrentViewName(e.target.value)}
                label="Change View">
                <MenuItem value="Day">Day View</MenuItem>
                <MenuItem value="Week">Week View</MenuItem>
                <MenuItem value="Month">Month View</MenuItem>
              </Select>
              <Button variant="contained">Publish</Button>
            </div>
            <div
              style={{
                height: "40%",
                overflow: "scroll",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}>
              <div
                style={{
                  alignSelf: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "20%",
                }}>
                <IconButton
                  onClick={() => {
                    setFilterExpanded((oldS) => {
                      let newState = [...oldS];
                      newState[0] = !newState[0];
                      return newState;
                    });
                  }}
                  style={{ marginRight: "10px" }}>
                  {filterExpanded[0] ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowRightIcon />
                  )}
                </IconButton>
                <h3>Positions</h3>
              </div>

              <div
                style={{
                  opacity: filterExpanded[0] ? 1 : 0,
                  height: "80%",
                  transition: "opacity 0.25s",
                }}>
                <TextField
                  style={{ margin: "0 5%" }}
                  id="standard-basic"
                  label="Filter"
                  variant="standard"
                />
                <FormControl style={{ margin: "0 5%" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group">
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="content-right">
            <Scheduler data={data}>
              <ViewState
                defaultCurrentDate={dateValue}
                currentDate={dateValue}
                defaultCurrentViewName="Month"
                currentViewName={currentViewName}
                onCurrentViewNameChange={(view) => setCurrentViewName(view)}
              />
              <EditingState onCommitChanges={commitChanges} />
              {currentViewName !== "Month" && (
                <GroupingState
                  grouping={grouping}
                  groupOrientation={() => "Vertical"}
                />
              )}
              <DayView cellDuration={720} />
              <WeekView
                cellDuration={720}
                dayScaleCellComponent={DayScaleCell}
                timeScaleLayoutComponent={TimeScaleComp}
                // layoutComponent={TimeScaleTickCellComp}
              />
              <MonthView />
              <Appointments />
              {/* <AllDayPanel /> */}
              <Resources data={resourceData} mainResourceName="priorityId" />
              {currentViewName !== "Month" && <IntegratedGrouping />}
              <IntegratedEditing />
              <AppointmentTooltip />
              <AppointmentForm />
              {currentViewName !== "Month" && <GroupingPanel />}
              <DragDropProvider />
            </Scheduler>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60px;
    width: 90%;
    margin: 0 5%;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    border-bottom: 0.5px solid #ccc;
  }

  .container {
    width: 100%;
  }
  .content-container {
    background-color: #f7f7f7;
    width: 98%;
    height: 98%;
    display: grid;
    grid-template-columns: 1fr 6fr;
    margin: 1%;
    border: 1px solid #ccc;

    /* border: 1px solid red; */
  }
  .content-left {
    height: 100%;
    background-color: white;
    border-right: 1px solid #ccc;
    overflow: scroll;

    /* border: 1px solid red; */
  }
  .content-right {
    overflow: scroll;
    height: 100%;
  }

  .scheduler-header {
    border-bottom: 1px solid #ccc;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid blue; */
  }
  .filter-container: {
    height: 40%;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;
    padding: 0 5%;
  }
`;

export default SingleProduct;
