import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAdmin } from "../../context/admin_context";
import Navigation from "../navigation/Navigation";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import {
  Select,
  Toolbar,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { createNewTaxGroup, getTaxGroups } from "../../helpers/helpers";
import RefreshIcon from "@mui/icons-material/Refresh";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { useSnackbar } from "notistack";

const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const TaxGroups = () => {
    const [taxGroupsLoading, setTaxGroupsLoading] = useState(null);
    const [taxGroups, setTaxGroups] = useState([]);
    const [newTaxGroupDialogOpen, setNewTaxGroupDialogOpen] = useState(false);
    const [newTaxGroupValue, setNewTaxGroupValue] = useState({
      name: "",
      taxRate: 0,
    });

    const getTaxGroupsHelper = () => {
      getTaxGroups(setTaxGroups, setTaxGroupsLoading, enqueueSnackbar);
    };

    const handleClose = () => {
      setNewTaxGroupDialogOpen(false);
    };

    const addNewTaxGroupHelper = async () => {
      let newTaxGroupValueCopy = { ...newTaxGroupValue };
      newTaxGroupValueCopy.taxRate = Number(newTaxGroupValueCopy.taxRate);
      setTaxGroupsLoading(true);
      const newTaxGroupResp = await createNewTaxGroup(
        newTaxGroupValueCopy,
        enqueueSnackbar
      );
      if (newTaxGroupResp) {
        enqueueSnackbar(`Successfully created new tax group`, {
          variant: "success",
        });
        setTaxGroups((oldS) => {
          let newState = [...oldS];
          console.log("newTaxGroupResp:", newTaxGroupResp);
          newState.push(newTaxGroupResp);
          console.log("newState:", newState);

          return newState;
        });
        setNewTaxGroupDialogOpen(false);
      }
      setTaxGroupsLoading(false);
    };

    useEffect(() => {
      getTaxGroupsHelper();
    }, []);

    return (
      <div style={{ width: "100%" }}>
        <Dialog open={newTaxGroupDialogOpen} onClose={handleClose}>
          <DialogTitle>New Tax Group</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tax rates are configured by you depending on destination and
              products sold. You fully control the calculation of your taxes;
              set up taxes per customer location (country, state, zip/postal
              code), add tax per group of products, set up tax-free products.
            </DialogContentText>
            <div style={{ display: "grid", gridTemplateRows: "1fr 1fr" }}>
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  required
                  id="name"
                  label="Tax Group Name"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={newTaxGroupValue.name}
                  onChange={(e) => {
                    const text = e.target.value;
                    setNewTaxGroupValue((oldS) => {
                      let newState = { ...oldS };
                      newState.name = text;
                      return newState;
                    });
                  }}
                />
              </div>
              <div>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label="Tax Rate"
                  type="number"
                  fullWidth
                  variant="standard"
                  helperText="Enter the percentage value. E.g 20% -> 20"
                  value={newTaxGroupValue.taxRate}
                  onChange={(e) => {
                    const text = e.target.value;
                    setNewTaxGroupValue((oldS) => {
                      let newState = { ...oldS };
                      newState.taxRate = text;
                      return newState;
                    });
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={addNewTaxGroupHelper}>Confirm</Button>
          </DialogActions>
        </Dialog>
        <IconButton
          onClick={getTaxGroupsHelper}
          style={{ position: "absolute", right: "15%", top: "5%" }}
          aria-label="expand row">
          <RefreshIcon style={{ fontSize: 30 }} />
        </IconButton>
        {taxGroupsLoading && <Loader />}
        {taxGroups && taxGroups?.length > 0 ? (
          <TableContainer
            sx={{ width: "80%", margin: "0 10%" }}
            component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tax Group name</TableCell>
                  <TableCell align="right">Tax ID</TableCell>
                  <TableCell align="right">Tax Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taxGroups.map((taxG, i) => {
                  return (
                    <TableRow
                      key={taxG.taxId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        {taxG.name}
                      </TableCell>
                      <TableCell align="right">{taxG.taxId}</TableCell>
                      <TableCell align="right">{taxG.taxRate}%</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <h4>No Tax Groups found</h4>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1%",
          }}>
          <Tooltip title="Add Tax Group">
            <IconButton onClick={() => setNewTaxGroupDialogOpen(true)}>
              <ControlPointIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <Wrapper style={{ backgroundColor: colors.backgroundColor }}>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>

        <div className="content-container">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <p>Tax Groups</p>
            </AccordionSummary>
            <AccordionDetails>
              <TaxGroups />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // height: 10vh;
  display: flex;
  position: relative;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;

    /* height: 100vh; */
    /* boredr: 1px solid red; */
  }

  .content-container {
    margin: 4% 2%;
    height: 90vh;
    /* border: 1px solid red; */
  }
`;

export default Settings;
