import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  TextField,
  Button,
  Modal,
  Box,
  Divider,
  Toolbar,
  Slide,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import MapView from "../../components/MapView";
import SimpleNavBar from "../../components/LocationBreadCrumb";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import { updateStoreDetails } from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { StoreData } from "../../classes/classes";
import { useSnackbar } from "notistack";

const StoreDetails = () => {
  const [viewImg, setViewImg] = useState(false);
  let { locationId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { locations } = useAdmin();

  console.log("locations:", locations);
  console.log("locationId:", locationId);

  const { enqueueSnackbar } = useSnackbar();

  let location = null;
  if (locationId) {
    [location] = locations.filter(
      (locationData) => locationData.locationId === locationId
    );
  }

  const [storeDetails, setStoreDetails] = useState({
    locationId: locationId,
    name: "",
    email: "",
    img: "",
    directions: "",
    openingTimes: "",
  });

  let navigate = useNavigate();

  if (!location) {
    navigate("/");
  }

  let days = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

  const updateHours = (e, type, i) => {
    const text = e.target.value;
    console.log("text:", text);

    // let isValid = false;
    // if (text.slice(-2) === "am") {
    //     isValid = true
    // }

    let storeDetailsCopy = { ...storeDetails };

    if (storeDetailsCopy?.openingTimes) {
      storeDetailsCopy.openingTimes[i] = {
        ...storeDetailsCopy.openingTimes[i],
        [type]: text,
        day: days[i],
      };
    } else {
      storeDetailsCopy.openingTimes = new Array(7).fill([]);
      storeDetailsCopy.openingTimes[i] = {
        ...storeDetailsCopy.openingTimes[i],
        [type]: text,
        day: days[i],
      };
    }

    setStoreDetails(storeDetailsCopy);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Select Location
        </InputLabel> */}
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <Modal open={viewImg} onClose={() => setViewImg(false)}>
          <Box
            sx={{
              height: "100%",
              padding: "5% 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "column",
            }}>
            <img src={storeDetails?.img} alt="store" style={{ width: "50%" }} />
            <Button
              variant="contained"
              onClick={() => {
                setViewImg(false);
              }}>
              Close
            </Button>
          </Box>
        </Modal>
        {location ? (
          <div className="content-container">
            <div className="left">
              <h4 style={{ margin: "1% 0" }}>
                Store location is set by the location
              </h4>
              <MapView
                store={location}
                // handleClick={handleClick}
                markerCoords={[location.longitude, location.latitude]}
              />
            </div>
            <div className="right">
              <h1 style={{ textAlign: "center", margin: "2% 0 " }}>
                {storeDetails?.name}
              </h1>
              <div className="data-container">
                <div className="data-container-l">
                  <h3>Store display name:</h3>
                </div>
                <div className="data-container-r">
                  <TextField
                    id="outlined-basic"
                    label="Store display name"
                    variant="outlined"
                    onChange={(e) => {
                      let text = e.target.value;
                      setStoreDetails((oldS) => {
                        let newState = { ...oldS };
                        newState.name = text;
                        return newState;
                      });
                    }}
                    value={storeDetails?.name || ""}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <Divider />
              <div className="data-container">
                <div className="data-container-l">
                  <h3>Store Contact Email:</h3>
                </div>
                <div className="data-container-r">
                  <TextField
                    id="outlined-basic"
                    label="Store contact email"
                    variant="outlined"
                    onChange={(e) => {
                      let text = e.target.value;
                      setStoreDetails((oldS) => {
                        let newState = { ...oldS };
                        newState.email = text;
                        return newState;
                      });
                    }}
                    value={storeDetails?.email || ""}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <Divider />

              <div className="data-container" style={{ height: "15%" }}>
                <div className="data-container-l">
                  <h3>Store Image:</h3>
                </div>
                <div className="data-container-r">
                  <TextField
                    id="outlined-basic"
                    label="Store Image"
                    variant="outlined"
                    onChange={(e) => {
                      let text = e.target.value;
                      setStoreDetails((oldS) => {
                        let newState = { ...oldS };
                        newState.img = text;
                        return newState;
                      });
                    }}
                    value={storeDetails?.img || ""}
                    style={{ width: "100%" }}
                  />
                  {storeDetails?.img && (
                    <Button
                      style={{ marginTop: "2vh" }}
                      variant="outlined"
                      onClick={() => {
                        setViewImg(true);
                      }}>
                      View Image
                    </Button>
                  )}
                </div>
              </div>
              <Divider />
              <div className="data-container" style={{ height: "15%" }}>
                <div className="data-container-l">
                  <h3>Store Directions URL:</h3>
                </div>
                <div className="data-container-r">
                  <TextField
                    id="outlined-basic"
                    label="Store Directions URL"
                    variant="outlined"
                    onChange={(e) => {
                      let text = e.target.value;
                      setStoreDetails((oldS) => {
                        let newState = { ...oldS };
                        newState.directions = text;
                        return newState;
                      });
                    }}
                    value={storeDetails?.directions}
                    style={{ width: "100%" }}
                  />
                  {storeDetails?.directions && (
                    <Button
                      style={{ marginTop: "2vh" }}
                      variant="outlined"
                      onClick={() => {
                        setViewImg(true);
                      }}>
                      View Image
                    </Button>
                  )}
                </div>
              </div>
              <Divider />

              <div className="data-container" style={{ height: "90%" }}>
                <div className="data-container-l">
                  <h3>Store Opening Times:</h3>
                  <p>HHam or HHpm or closed</p>
                </div>
                <div className="data-container-r">
                  {days.map((day, i) => {
                    let open = "";
                    let close = "";
                    if (storeDetails?.openingTimes) {
                      open = storeDetails?.openingTimes[i].open;
                      close = storeDetails?.openingTimes[i].close;
                    }

                    console.log("open:", open);
                    console.log("close:", close);
                    return (
                      <div className="times-container">
                        <div className="times-item">
                          <p>Open:</p>
                          <TextField
                            id="outlined-basic"
                            label={day}
                            onChange={(e) => updateHours(e, "open", i)}
                            variant="outlined"
                            value={open}
                            style={{ width: "40%" }}
                          />
                        </div>
                        <div className="times-item">
                          <p>Close:</p>
                          <TextField
                            id="outlined-basic"
                            label={day}
                            variant="outlined"
                            value={close}
                            onChange={(e) => updateHours(e, "close", i)}
                            style={{ width: "40%" }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Divider />
              <div className="confirm-changes-container">
                <Button
                  variant="contained"
                  onClick={() => {
                    updateStoreDetails(
                      storeDetails,
                      setIsLoading,
                      enqueueSnackbar,
                      navigate
                    );
                  }}>
                  Save Changes
                </Button>
              </div>
              <Divider />
            </div>
          </div>
        ) : (
          <h2>No Location Data</h2>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;

    height: 90vh;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* border: 1px solid red; */
    height: 100vh;
  }
  .right {
    display: flex;
    flex: 2;
    /* border: 1px solid red; */
    /* height: 100%; */
    flex-direction: column;
  }

  .data-container {
    /* height: 15vh; */
    margin: 2vh 0;
    /* border: 1px solid red; */
    padding: 0 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .confirm-changes-container {
    /* height: 15vh; */
    margin: 4vh 0;
    /* border: 1px solid red; */
    padding: 0 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .data-container-l {
    width: 35%;
    height: 100%;
    /* border: 1px solid red; */
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10%;
  }
  .data-container-r {
    width: 65%;
    height: 100%;
    /* border: 1px solid red; */
    padding: 0 2.5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .modal {
    height: 20vh;
    border: 5px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .times-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2vh;
  }

  .day-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .times-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`;

export default StoreDetails;
