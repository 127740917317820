import { Toolbar } from "@mui/material";
import React from "react";

import styled from "styled-components";
import {
  SupportHeader,
  SupportImg,
  SupportImgDouble,
  SupportSubHeader,
  SupportText,
  SupportWarnings,
} from "./components/SupportComponents";
import SupportBreadCrumb from "./SupportBreadCrumb";
import SupportNavigation from "./SupportNavigation";
import TillDiscountsFramed from "../../resources/img/till_discounts_framed.png";
import TillDiscountScreenFramed from "../../resources/img/till_discount_screen_framed.png";
import TillGlobalDiscountTypeFramed from "../../resources/img/till_global_discount_type_framed.png";
import TillSubtotalScreenFramed from "../../resources/img/till_subtotal_screen_framed.png";

const DiscountSupport = () => {
  return (
    <Wrapper>
      <SupportNavigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Select Store
        </InputLabel> */}
            <SupportBreadCrumb />
          </Toolbar>
        </div>
        <div className="content-container">
          <SupportHeader header="Discount Types" />

          <SupportSubHeader header="What are Global discounts vs Item disocunts" />
          <SupportText>
            <p>
              There are two ways of adding discounts to items:{" "}
              <u>Global Discounts</u> and <u>Item Discounts</u>
            </p>

            <br />
            <p>
              &#8226; Global Discounts let you add discounts to all items in the
              current cart that satisfy a condition, such as "All Drink Items".
              This lets you add discounts for return users that usually get a
              10% drink discount.
              <br />
              <br />
              &#8226; Item Discounts only apply the discount to the item you
              have selected. Global discounts may be applied on top of item
              discounts, and the global discount will use the discounted item
              price to apply the discount
            </p>
          </SupportText>
          <SupportSubHeader header="How to add Item discounts" />
          <SupportText>
            <p>
              To add Item discounts, add an item to the cart and swipe left on
              the item. This will show 3 buttons as seen below. Click on "Add
              Disc" and then select the discount percentage you would like to
              add
            </p>
          </SupportText>
          <SupportImgDouble
            src1={TillDiscountsFramed}
            alt1="Till Discounts Framed"
            src2={TillDiscountScreenFramed}
            alt2="Till Discount Screen"
          />
          <SupportSubHeader header="How to add Global discounts" />
          <SupportText>
            <p>
              To add Global discounts, add your order items to the cart and
              press on "Pay" in the bottom right corner. Just before taking a
              customers payment, press on "Discounts". This will take you to the
              "Select Discount Percentage" screen. Select the discount you want
              to apply and then a modal will appear asking what categories to
              apply the discount to. This discount will then be applied to all
              items your cart that match the category selected.
              <br />
              <br />
              <SupportWarnings
                text={`Warning: Once you apply a global discount the order will be
                locked! Always add a global discount at the end of an order
                (before paying)`}
              />
            </p>
          </SupportText>
          <SupportImgDouble
            src1={TillSubtotalScreenFramed}
            alt1="Till Subtotal Screen"
            alt2="Till Global Discount Type "
            src2={TillGlobalDiscountTypeFramed}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  /* flex-direction: column; */

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    /* overflow: scroll; */
  }
`;

export default DiscountSupport;
