import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  MenuItem,
  Select,
  Toolbar as MuiToolbar,
  Button,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  Link,
  RadioGroup,
  IconButton,
  Tabs,
  Tab,
  InputLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Chip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ButtonGroup,
  Avatar,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import { useAdmin } from "../../context/admin_context";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import { stringAvatar } from "../../helpers/helpers";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RuleIcon from "@mui/icons-material/Rule";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SettingsIcon from "@mui/icons-material/Settings";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import ArchiveIcon from "@mui/icons-material/Archive";
import EmployeeProfileComp from "../../components/EmployeeProfileComp";
import EmployeeSupportComp from "../../components/EmployeeSupportComp";

const EmployeeProfile = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedListIndex, setSelectedListIndex] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const { userId } = useParams();

  let navigate = useNavigate();
  let location = useLocation();

  const user = location?.state;

  console.log("location.state:", location.state);

  const { locations, selectedStore, setSelectedStore } = useAdmin();

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <div className="nav-container">
          <MuiToolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            <h2
              style={{
                marginLeft: "25%",
                color: "#444",
                fontFamily: "'Heebo', sans-serif",
              }}>
              Profile
            </h2>
          </MuiToolbar>
        </div>
        <div className="content-container">
          <div className="left-container">
            <div className="left-top">
              <Avatar
                style={{ height: "10vw", width: "10vw", fontSize: "3vw" }}
                {...stringAvatar(user?.name)}
              />
            </div>
            <div className="left-bottom">
              <List style={{ width: "95%", margin: "0 2.5%" }}>
                <ListItemButton
                  onClick={() => setSelectedListIndex(0)}
                  className="list-item"
                  selected={selectedListIndex === 0}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => setSelectedListIndex(1)}
                  className="list-item"
                  selected={selectedListIndex === 1}>
                  <ListItemIcon>
                    <RuleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Roles & Positions" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedListIndex === 2}>
                  <ListItemIcon>
                    <EventAvailableIcon />
                  </ListItemIcon>
                  <ListItemText primary="Availability" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedListIndex === 3}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedListIndex === 4}>
                  <ListItemIcon>
                    <EventNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Schedule" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedListIndex === 5}>
                  <ListItemIcon>
                    <SubtitlesIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logs" />
                </ListItemButton>
                <ListItemButton
                  className="list-item"
                  selected={selectedListIndex === 6}>
                  <ListItemIcon>
                    <ArchiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="Archive Employee" />
                </ListItemButton>
              </List>
            </div>
          </div>

          {selectedListIndex === 0 && <EmployeeProfileComp />}
          {selectedListIndex === 1 && <EmployeeSupportComp />}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60px;
    width: 90%;
    margin: 0 5%;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    border-bottom: 0.5px solid #ccc;
    box-shadow: 0px 5px 15px 5px rgba(55, 55, 55, 0.05);
  }

  .container {
    width: 100%;
  }
  .content-container {
    background-color: #f7f7f7;
    width: 100%;
    height: 90%;
    display: grid;
    grid-template-columns: 1fr 3fr;

    /* border: 1px solid red; */
  }

  .left-container {
    /* border: 1px solid green; */
    display: grid;
    grid-template-rows: 1fr 2fr;
    border-right: 0.5px solid #ccc;
    margin-right: 10%;
  }

  .left-top {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-bottom {
    /* border: 1px solid red; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .right-container {
    /* border: 1px solid red; */
    display: grid;
    grid-template-rows: 1fr 2fr;
    grid-row-gap: 2vw;
  }

  .right-top {
    /* border: 1px solid green; */
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 5% 0 0 10%;
  }

  .right-bottom {
    /* border: 1px solid blue; */
  }

  .list-item: {
    border: 1px solid #ccc;
    background-color: black;
  }
`;

export default EmployeeProfile;
