import React, { useEffect, useState, useCallback } from "react";
import { fetchOptions, fetchProducts } from "../helpers/helpers";
import styled from "styled-components";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "./Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import update from "immutability-helper";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DnDItemOptions } from "./DnDItemOptions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useAdmin } from "../context/admin_context";

const DisplayInStores = ({
  setSelectedOption,
  selectedOption,
  optionIndex,
  storeGroups,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <h4>Store Groups</h4>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <h3>All Stores</h3>
          <Checkbox
            checked={selectedOption?.optionItems[
              optionIndex
            ]?.displayInStores?.includes("all_stores")}
            // indeterminate={
            //   selectedOption?.displayInStores
            //     ?.length > 0
            // }
            onChange={(event) => {
              setSelectedOption((oldS) => {
                let newState = { ...oldS };
                if (event.target.checked) {
                  newState.optionItems[optionIndex].displayInStores = [
                    "all_stores",
                  ];
                } else {
                  newState.optionItems[optionIndex].displayInStores = [];
                }

                return newState;
              });
            }}
          />
        </div>
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {storeGroups &&
            storeGroups.map((store, i) => {
              console.log(
                "egrerefr:",
                selectedOption?.optionItems[
                  optionIndex
                ]?.displayInStores?.includes("all_stores")
                  ? true
                  : false
              );
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <h5>{store?.storeGroupName}</h5>
                  <Checkbox
                    key={i}
                    label={store?.storeGroupName}
                    disabled={selectedOption?.optionItems[
                      optionIndex
                    ]?.displayInStores?.includes("all_stores")}
                    checked={
                      selectedOption?.optionItems[
                        optionIndex
                      ]?.displayInStores?.includes("all_stores")
                        ? true
                        : false ||
                          selectedOption?.optionItems[
                            optionIndex
                          ]?.displayInStores?.includes(store?.storeGroupId)
                        ? true
                        : false
                    }
                    onChange={(event) => {
                      setSelectedOption((oldS) => {
                        let newState = { ...oldS };

                        if (
                          newState?.optionItems[
                            optionIndex
                          ]?.displayInStores?.includes("all_stores")
                        ) {
                          newState.optionItems[optionIndex].displayInStores =
                            [];
                          return newState;
                        }

                        if (event.target.checked) {
                          if (
                            newState?.optionItems[optionIndex]?.displayInStores
                          ) {
                            newState?.optionItems[
                              optionIndex
                            ]?.displayInStores.push(store?.storeGroupId);
                          } else {
                            newState.optionItems[optionIndex].displayInStores =
                              [store?.storeGroupId];
                          }
                        } else {
                          let filteredStores = newState?.optionItems[
                            optionIndex
                          ]?.displayInStores?.filter(
                            (s) => s !== store?.storeGroupId
                          );
                          newState.optionItems[optionIndex].displayInStores =
                            filteredStores;
                        }
                        return newState;
                      });
                    }}
                  />
                </div>
              );
            })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DisplayInStores;
