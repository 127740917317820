import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ListItemButton,
  ListItemText,
  List,
  TableCell,
  Table,
  Collapse,
  Toolbar,
  Select,
  TableHead,
  TableRow,
  Divider,
  IconButton,
  Checkbox,
  MenuItem,
  Typography,
  TableBody,
  TableContainer,
  Paper,
  TextField,
  OutlinedInput,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Navigation from "../../pages/navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  deleteOption,
  fetchEposGroups,
  fetchOptions,
  fetchProducts,
  addOption,
  updateOption,
  createStoreGroup,
  arrayContainsObject,
  getStoreGroups,
  deleteStoreGroup,
  updateStoreGroup,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import ImageIcon from "@mui/icons-material/Image";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import DisplayInStores from "../../components/DisplayInStores";
import { useAdmin } from "../../context/admin_context";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

const initialState = {
  storeGroupName: "",
  stores: [],
  tags: [""],
};

const StoreGroups = () => {
  const [storeGroups, setStoreGroups] = useState(null);
  const [addStoreGroupScreen, setAddStoreGroupScreen] = useState({
    show: false,
    type: "add",
  });
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const [formValue, setFormValue] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  let { storeId } = useParams();

  const { locations } = useAdmin();

  console.log("locations:", locations);

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    getStoreGroups(setStoreGroups, setIsLoading, enqueueSnackbar);
  }, []);

  const deleteStoreGroupAlert = (optionId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this store group. This will remove this option from all products that use it."
      ) == true
    ) {
      deleteStoreGroup(optionId, setIsLoading, setStoreGroups, enqueueSnackbar);
    }
  };

  const validateStoreGroupInput = () => {
    if (!formValue?.storeGroupName || formValue?.storeGroupName?.length === 0) {
      enqueueSnackbar(`Invalid store group name`, {
        variant: "warning",
      });
      return false;
    }

    if (!formValue?.stores || formValue?.stores?.length === 0) {
      enqueueSnackbar(`Invalid store groups`, {
        variant: "warning",
      });
      return false;
    }

    return true;
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.storeGroupName}
          </TableCell>
          <TableCell align="left">{row.storeGroupId}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => deleteStoreGroupAlert(row.storeGroupId)}>
              <DeleteIcon style={{ color: "#f54242" }} />
            </IconButton>
            <IconButton aria-label="expand row" size="small">
              <EditIcon
                onClick={() => {
                  // setEditingVariation(variation);
                  // setVariationValue(variation);
                  // setOpen({ show: true, type: "Update" });
                  setFormValue(row);
                  setAddStoreGroupScreen({ show: true, type: "update" });
                }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "45%", marginRight: "5%" }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Stores</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.stores.map((optItems) => (
                        <TableRow key={optItems}>
                          <TableCell component="th" scope="row">
                            {optItems.locationName}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div style={{ width: "45%", marginRight: "5%" }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Store Group Tags</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.tags.map((tags) => (
                        <TableRow key={tags}>
                          <TableCell component="th" scope="row">
                            {tags}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        {addStoreGroupScreen.show ? (
          <div className="content-container">
            <div className="form-container">
              <div className="header-container-add">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#777" }}
                  onClick={() => {
                    setAddStoreGroupScreen({ show: false, type: "" });
                    setFormValue(initialState);
                  }}>
                  Go Back
                </Button>
                <h2>New Store Group:</h2>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (validateStoreGroupInput()) {
                      if (addStoreGroupScreen.type === "update") {
                        updateStoreGroup(
                          formValue,
                          setIsLoading,
                          setStoreGroups,
                          enqueueSnackbar
                        );
                      } else {
                        createStoreGroup(
                          formValue,
                          setIsLoading,
                          setStoreGroups,
                          enqueueSnackbar
                        );
                      }

                      setFormValue(initialState);
                      setAddStoreGroupScreen({
                        show: false,
                        type: "",
                      });
                    }
                  }}>
                  Submit
                </Button>
              </div>
              <Divider />

              <div className="data-container">
                <div className="data-row">
                  <div className="data-col">
                    <h3>Store Group Name:</h3>
                  </div>
                  <div className="data-col">
                    <TextField
                      value={formValue.storeGroupName}
                      onChange={(e) => {
                        setFormValue((oldS) => {
                          let newState = { ...oldS };
                          newState.storeGroupName = e.target.value;
                          return newState;
                        });
                      }}
                      style={{ width: "90%" }}
                      label="Store Group Name"
                      variant="outlined"
                      type="search"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <Divider />

                <div className="data-row">
                  <div className="data-col">
                    <h3>Store Group Stores:</h3>
                  </div>
                  <div className="data-col">
                    <Select
                      placeholder="Select Stores"
                      style={{ width: "90%" }}
                      labelId="demo-multiple-name-label"
                      label="Stores"
                      id="demo-multiple-name"
                      multiple
                      renderValue={(selected) => (
                        <div
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value?.locationId}
                              label={value?.locationName}
                            />
                          ))}
                        </div>
                      )}
                      value={formValue?.stores}
                      onChange={(e) => {
                        const selectedItem =
                          e.target.value[e.target.value.length - 1];
                        setFormValue((oldS) => {
                          let newState = { ...oldS };

                          console.log("selectedItem:", selectedItem);
                          console.log("newState.stores:", newState.stores);

                          const isInArr = arrayContainsObject(
                            selectedItem,
                            newState.stores
                          );

                          console.log("isInArr:", isInArr);
                          if (isInArr) {
                            let updatedStores = newState.stores.filter(
                              (s) => s?.locationId !== selectedItem?.locationId
                            );
                            newState.stores = updatedStores;
                          } else {
                            newState.stores.push(selectedItem);
                          }
                          return newState;
                        });
                      }}
                      input={<OutlinedInput label="Name" />}>
                      {locations &&
                        locations.map((store, i) => {
                          const locationIdsInUse = [];
                          storeGroups &&
                            storeGroups.map((storeGroup) => {
                              if (
                                storeGroup?.storeGroupId !==
                                formValue?.storeGroupId
                              ) {
                                storeGroup?.stores?.map((storeGroupStore) => {
                                  locationIdsInUse.push(
                                    storeGroupStore?.locationId
                                  );
                                });
                              }
                            });
                          console.log("locationIdsInUse: ", locationIdsInUse);

                          if (locationIdsInUse.length === locations?.length) {
                            if (i === 0) {
                              //have to do this as you can't break out of a map
                              //not most ideal way but works for now
                              return (
                                <button
                                  disabled
                                  style={{
                                    padding: "10px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    fontSize: "15px",
                                  }}>
                                  All stores are in use
                                </button>
                              );
                            }
                          }

                          if (!locationIdsInUse.includes(store?.locationId)) {
                            return (
                              <MenuItem
                                key={i}
                                value={{
                                  locationId: store?.locationId,
                                  locationName: store?.locationName,
                                }}>
                                <Checkbox
                                  checked={formValue?.stores?.some(
                                    (st) => st?.locationId === store?.locationId
                                  )}
                                />
                                <ListItemText primary={store?.locationName} />
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  </div>
                </div>
                <Divider />

                <div className="item-container">
                  <h3>Option Tags:</h3>
                  {formValue.tags.map((tag, i) => {
                    return (
                      <div className="data-row">
                        <div
                          className="data-col"
                          style={{ justifyContent: "space-around" }}>
                          <button
                            onClick={() => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                let itemOptions = [...newState.tags];
                                itemOptions.splice(i, 1);
                                newState.tags = itemOptions;
                                return newState;
                              });
                            }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}>
                            <RemoveCircleIcon style={{ color: "#f54242" }} />
                          </button>

                          <h4>Tag {i + 1}:</h4>
                        </div>
                        <div className="data-col">
                          <TextField
                            value={formValue.tags[i]}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.tags[i] = e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            id="outlined-basic"
                            label="Tag"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setFormValue((oldS) => {
                        let newState = { ...oldS };
                        newState.tags.push("");
                        console.log("newState:", newState);
                        return newState;
                      });
                    }}>
                    Add Option Item
                  </Button>
                </div>
                <Divider />
              </div>
            </div>
          </div>
        ) : (
          <div className="content-container">
            <div className="header-container">
              <h2>Store Groups:</h2>
              <IconButton
                aria-label="expand row"
                size="small"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  getStoreGroups(setStoreGroups, setIsLoading, enqueueSnackbar);
                }}>
                <span>Refresh</span>

                <RefreshIcon />
              </IconButton>
              <IconButton
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                aria-label="expand row"
                size="small">
                <span>Sync devices</span>

                <CloudSyncIcon />
              </IconButton>
              <Button
                variant="contained"
                onClick={() =>
                  setAddStoreGroupScreen({ show: true, type: "add" })
                }>
                Add new store group
              </Button>
            </div>

            {storeGroups && storeGroups?.length > 0 && (
              <div className="epos-groups-container">
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Store Group Name</TableCell>
                        <TableCell align="left">Store Group Id</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {storeGroups.map((row) => (
                        <Row key={row.storeGroupId} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    width: 100%;

    height: 90vh;
    /* display: grid; */
    /* grid-template-rows: 1fr 9fr; */
    /* border: 1px solid red; */
  }
  .header-container {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3%;
  }

  .epos-groups-container {
    width: 100%;
    height: 90%;
    text-align: center;
    overflow: scroll;
    /* border: 1px solid black; */
    padding: 0 2% 2% 2%;
    /* display: flex; */
    /* background-color: #f9f9f9; */
    /* justify-content: space-around;
    align-items: center;
    justify-content: center; */
  }

  .products-container-top {
    height: 10%;
    width: 100%;
    padding: 0 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 6px 10px -1px rgba(0, 0, 0, 0.05);
  }
  .products-container-bottom {
    height: 90%;
    /* border: 1px solid black; */
    width: 100%;
    overflow: scroll;
    padding: 1%;
  }

  .form-container {
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    width: 90%;
    height: 95%;
    margin: 2.5% 5%;
    overflow-y: scroll;
  }

  .header-container-add {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20%;
  }

  .data-container {
    width: 100%;
    height: 90%;
    text-align: center;
    /* border: 1px solid black; */
    padding: 0 2%;
    /* display: flex; */
    /* background-color: #f9f9f9; */
    /* justify-content: space-around;
    align-items: center;
    justify-content: center; */
  }
  .item-container {
    /* border: 1px solid black; */
    margin: 2.5% 0;
  }
  .data-row {
    width: 100%;
    display: flex;
    margin: 2% 0;
  }
  .data-col {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default StoreGroups;
