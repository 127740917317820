import React from "react";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
} from "@mui/material";

const SearchComp = ({
  onClick,
  tags,
  setSelectedTags,
  filteredItems,
  items,
  handleToggle,
  selectedItems,
  findItemKey,
  displayNameKey,
  showAddBtn = false,
}) => {
  const OptionsList = ({ opt, i }) => {
    const labelId = `checkbox-list-label-${opt}`;

    console.log("opt:", opt);

    return (
      <ListItem
        key={i}
        divider={i !== items.length - 1}
        //   secondaryAction={
        //     <IconButton edge="end" aria-label="comments">
        //       <CommentIcon />
        //     </IconButton>
        //   }
        disablePadding>
        <ListItemButton role={undefined} onClick={handleToggle(opt)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selectedItems.find(
                (optionName) => optionName[findItemKey] === opt[findItemKey]
              )}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={opt[displayNameKey]} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Wrapper>
      <Autocomplete
        style={{ width: "70%", margin: "1% 0 3% 0", height: "10%" }}
        multiple
        onChange={(event, newValue, reason) => {
          setSelectedTags(newValue);
          console.log("newValue:", newValue);
        }}
        id="tags-standard"
        options={tags}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Search by Name or Tags"
            placeholder="Enter name or tags"
          />
        )}
      />
      {showAddBtn && selectedItems.length > 0 && (
        <Button variant="contained" onClick={onClick}>
          Add options to product
        </Button>
      )}
      <div
        style={{
          //   border: "1px solid black",
          width: "100%",
          height: "75%",
          overflow: "scroll",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          width: "80%",
          margin: "2.5% 10%",
          //   paddingTop: "25%",
        }}>
        <List
          style={{
            // border: "1px solid red",
            width: "100%",
            height: "100%",
          }}>
          {filteredItems ? (
            <>
              {filteredItems.map((opt, i) => (
                <OptionsList opt={opt} i={i} />
              ))}
            </>
          ) : (
            <>
              {items.map((opt, i) => (
                <OptionsList opt={opt} i={i} />
              ))}
            </>
          )}
        </List>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* border: 1px solid red; */
  overflow: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  flex-direction: column;
`;

export default SearchComp;
