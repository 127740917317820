import React, { useEffect, useState, useCallback } from "react";
import { fetchOptions, fetchProducts } from "../helpers/helpers";
import styled from "styled-components";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "./Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import update from "immutability-helper";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DnDItemOptions } from "./DnDItemOptions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useAdmin } from "../context/admin_context";

const GlobalDisplayInStores = ({
  setSelectedOption,
  selectedOption,
  storeGroups,
}) => {
  console.log("storeGroups", storeGroups);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <h4>Global Store Groups</h4>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <h3>All Stores</h3>
          <Checkbox
            checked={selectedOption?.optionItems?.every((opt) => {
              return opt?.displayInStores?.includes("all_stores");
            })}
            // indeterminate={
            //   selectedOption?.displayInStores
            //     ?.length > 0
            // }
            onChange={(event) => {
              setSelectedOption((oldS) => {
                let newState = { ...oldS };
                if (event.target.checked) {
                  newState.optionItems = newState.optionItems.map((o) => {
                    console.log("o:", o);
                    o.displayInStores = ["all_stores"];
                    return o;
                  });
                } else {
                  newState.optionItems = newState.optionItems.map((o) => {
                    o.displayInStores = [];
                    return o;
                  });
                }

                return newState;
              });
            }}
          />
        </div>
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {storeGroups &&
            storeGroups.map((store, i) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <h5>{store?.storeGroupName}</h5>
                  <Checkbox
                    key={i}
                    label={store?.storeGroupName}
                    disabled={selectedOption?.optionItems?.every((opt) => {
                      return opt?.displayInStores?.includes("all_stores");
                    })}
                    checked={
                      selectedOption?.optionItems?.every((opt) => {
                        return opt?.displayInStores?.includes("all_stores");
                      }) ||
                      selectedOption?.optionItems?.every((opt) => {
                        return opt?.displayInStores?.includes(
                          store?.storeGroupId
                        );
                      })
                    }
                    onChange={(event) => {
                      setSelectedOption((oldS) => {
                        let newState = { ...oldS };

                        if (event.target.checked) {
                          newState.optionItems = newState.optionItems.map(
                            (o) => {
                              console.log("o:", o);
                              if (o?.displayInStores) {
                                o.displayInStores.push(store?.storeGroupId);
                              } else {
                                o.displayInStores = [store?.storeGroupId];
                              }

                              return o;
                            }
                          );
                        } else {
                          newState.optionItems = newState.optionItems.map(
                            (o) => {
                              let newO = o.displayInStores?.filter(
                                (s) => s !== store?.storeGroupId
                              );
                              return newO;
                            }
                          );

                          //   newState.optionItems[optionIndex].displayInStores =
                          //     filteredStores;
                        }
                        return newState;
                      });
                    }}
                  />
                </div>
              );
            })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default GlobalDisplayInStores;
