import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  MenuItem,
  Select,
  Toolbar as MuiToolbar,
  Button,
  Box,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  Link,
  DialogActions,
  IconButton,
  Tooltip,
  DialogContent,
  Autocomplete,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Chip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import { useAdmin } from "../../context/admin_context";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

import InfoIcon from "@mui/icons-material/Info";
import {
  getStaff,
  getEposUsers,
  resendPasswordResetEmail,
  createEposUser,
  deleteEposUser,
  getAStaffUser,
} from "../../helpers/helpers";
import StorefrontIcon from "@mui/icons-material/Storefront";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RefreshIcon from "@mui/icons-material/Refresh";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useAuth } from "../../context/auth_context";

const EposUsers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    user: null,
  });

  let navigate = useNavigate();

  const handleClickOpen = (user, updateType = false) => {
    setModalVisible({ visible: true, user: user, updateType: updateType });
  };

  const handleClose = () => {
    setModalVisible({ visible: false, user: null });
  };

  const { currentUser } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const goToStaffAccount = async (uid, setModalLoading) => {
    const staffUser = await getAStaffUser(
      uid,
      setModalLoading,
      enqueueSnackbar
    );
    if (staffUser) {
      navigate(`/staff/${uid}`, {
        state: staffUser,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getEposUsers(setUsers, setIsLoading, enqueueSnackbar);
  }, []);

  const AddUserModal = () => {
    const [linkStaff, setLinkStaff] = useState({
      modalVisible: false,
      staff: null,
      selectedStaff: null,
    });
    const [modalLoading, setModalLoading] = useState(false);
    const [updatedUser, setUpdatedUser] = useState(null);

    const setShowStaffLinkModalHelper = async () => {
      const resp = await getStaff(setModalLoading, enqueueSnackbar);
      if (resp) {
        console.log("setting modal visible");
        setLinkStaff({ modalVisible: true, staff: resp });
      }
    };

    const updateEposUserHelper = async () => {
      if (updatedUser.encrypted_pin !== modalVisible.user.encrypted_pin) {
        let userBeingOverriden = null;
        let isDuplicate = users.some((usr) => {
          if (usr.encrypted_pin === updatedUser.encrypted_pin) {
            userBeingOverriden = usr;
            return true;
          }
        });
        if (isDuplicate) {
          if (
            window.confirm(
              `You are overriding ${userBeingOverriden.name}'s user with ${updatedUser.name}'. Are you sure you want to do this?`,
              ""
            )
          ) {
            await createEposUser(
              updatedUser,
              setUsers,
              setModalLoading,
              enqueueSnackbar,
              modalVisible?.user?.encrypted_pin
            );
            handleClose();
            return;
          } else {
            return;
          }
        } else {
          await createEposUser(
            updatedUser,
            setUsers,
            setModalLoading,
            enqueueSnackbar,
            modalVisible?.user?.encrypted_pin
          );
          handleClose();
        }
      }

      await createEposUser(
        updatedUser,
        setUsers,
        setModalLoading,
        enqueueSnackbar
      );
      handleClose();
    };

    const createEposUserHelper = async () => {
      let userBeingOverriden = null;
      let isDuplicate = users.some((usr) => {
        if (usr.encrypted_pin === updatedUser.encrypted_pin) {
          userBeingOverriden = usr;
          return true;
        }
      });
      if (isDuplicate) {
        if (
          window.confirm(
            `You are overriding ${userBeingOverriden.name}'s user with ${updatedUser.name}'. Are you sure you want to do this?`,
            ""
          )
        ) {
          await createEposUser(
            updatedUser,
            setUsers,
            setModalLoading,
            enqueueSnackbar
          );
          handleClose();
          return;
        } else {
          return;
        }
      } else {
        await createEposUser(
          updatedUser,
          setUsers,
          setModalLoading,
          enqueueSnackbar
        );
        handleClose();
      }
    };

    const deleteEposUserHelper = async () => {
      if (
        window.confirm(
          `Are you sure you want to delete ${updatedUser?.name}'s epos user. You will still be able to view ${updatedUser?.name}'s previous sales.`
        )
      ) {
        await deleteEposUser(
          updatedUser.encrypted_pin,
          setUsers,
          setModalLoading,
          enqueueSnackbar
        );
        handleClose();
      }
    };

    useEffect(() => {
      if (modalVisible.user) {
        setUpdatedUser({ ...modalVisible.user });
      }
    }, [modalVisible]);

    return (
      <Dialog fullWidth open={modalVisible.visible} onClose={handleClose}>
        {modalLoading && <Loader />}
        {updatedUser && (
          <>
            <DialogTitle>{updatedUser.name}</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>Enter the EPOS users name</DialogContentText> */}
              <TextField
                autoFocus
                margin="dense"
                label="Enter the EPOS users name"
                type="text"
                value={updatedUser?.name}
                onChange={(e) => {
                  const text = e.target.value;
                  setUpdatedUser((oldS) => {
                    let newState = { ...oldS };
                    newState.name = text;
                    return newState;
                  });
                }}
                fullWidth
                variant="standard"
              />

              {/* <DialogContentText>Enter the EPOS users pin</DialogContentText> */}
              <TextField
                autoFocus
                margin="dense"
                label="Enter the EPOS users pin"
                type="number"
                onChange={(e) => {
                  const text = e.target.value;
                  setUpdatedUser((oldS) => {
                    let newState = { ...oldS };
                    newState.encrypted_pin = text;
                    return newState;
                  });
                }}
                value={updatedUser?.encrypted_pin}
                fullWidth
                variant="standard"
              />
              {updatedUser.uid ? (
                <>
                  <Button
                    style={{ margin: "5% 5% 5% 0" }}
                    color="info"
                    variant="outlined"
                    onClick={() =>
                      goToStaffAccount(modalVisible?.user?.uid, setModalLoading)
                    }>
                    View Staff Account
                  </Button>
                  <Button
                    style={{ margin: "5% 0" }}
                    color="warning"
                    // variant="outlined"
                    onClick={() => {
                      setUpdatedUser((oldS) => {
                        let newState = { ...oldS };
                        newState.uid = null;
                        return newState;
                      });
                    }}>
                    Remove Staff Account Link
                  </Button>
                </>
              ) : (
                <>
                  {linkStaff.modalVisible && linkStaff.staff ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        height: "25vh",
                        paddingTop: "5vh",
                      }}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          console.log("newValue:", newValue);
                          setLinkStaff((oldS) => {
                            let newState = { ...oldS };
                            newState.selectedStaff = newValue;
                            return newState;
                          });
                          setUpdatedUser((oldS) => {
                            let newState = { ...oldS };
                            newState.uid = newValue.uid;
                            return newState;
                          });
                        }}
                        value={linkStaff.selectedStaff}
                        disablePortal
                        id="combo-box-demo"
                        // freeSolo
                        getOptionLabel={(option) => option.email}
                        options={linkStaff.staff}
                        // renderOption={(props, option) => (
                        //   <li {...props}>{option.name}</li>
                        // )}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Staff" />
                        )}
                      />
                    </div>
                  ) : (
                    <Button
                      style={{ margin: "5% 0" }}
                      color="warning"
                      variant="outlined"
                      onClick={setShowStaffLinkModalHelper}>
                      Link to staff account
                    </Button>
                  )}
                </>
              )}
              {/* <DialogContentText>Enter the EPOS users BLE ID</DialogContentText> */}
              <TextField
                autoFocus
                margin="dense"
                label="Enter the EPOS users BLE ID"
                type="text"
                onChange={(e) => {
                  const text = e.target.value;
                  setUpdatedUser((oldS) => {
                    let newState = { ...oldS };
                    newState.ble_id = text;
                    return newState;
                  });
                }}
                value={updatedUser?.ble_id}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Button color="error" onClick={deleteEposUserHelper}>
                Delete User
              </Button>
              <div>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (modalVisible.updateType) {
                      updateEposUserHelper();
                    } else {
                      createEposUserHelper();
                    }
                  }}
                  type="submit">
                  Confirm
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <AddUserModal />

      <div className="container">
        <div className="nav-container">
          <MuiToolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            <h2
              style={{
                marginLeft: "25%",
                color: "#444",
                fontFamily: "'Heebo', sans-serif",
              }}>
              EposUsers
            </h2>
            <IconButton
              onClick={() => {
                getEposUsers(setUsers, setIsLoading, enqueueSnackbar);
              }}
              style={{ position: "absolute", right: "15%" }}
              aria-label="expand row">
              <RefreshIcon style={{ fontSize: 30 }} />
            </IconButton>
          </MuiToolbar>
        </div>
        <div className="content-container">
          <div className="button-container">
            <Button
              variant="contained"
              onClick={() => {
                handleClickOpen({
                  name: "",
                  encyrpted_pin: "",
                  bled_id: "",
                  uid: "",
                });
              }}>
              Add Till User
            </Button>
          </div>
          <Box sx={{ width: "95%", margin: "0 2.5%" }}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: "73vh" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Pin</TableCell>
                      <TableCell>BLE Tag ID</TableCell>
                      <TableCell>Connected User</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          console.log("row:", row);
                          return (
                            <TableRow
                              key={i}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              style={
                                currentUser?.email === row?.email
                                  ? {
                                      backgroundColor: "#f7f7f7",
                                    }
                                  : {}
                              }
                              key={row.encrypted_pin}>
                              <TableCell style={{ overflow: "hidden" }}>
                                {row?.name}
                              </TableCell>
                              <TableCell style={{ overflow: "hidden" }}>
                                {row?.encrypted_pin}
                              </TableCell>
                              <TableCell>{row?.ble_id}</TableCell>
                              <TableCell>
                                {row?.uid ? (
                                  <Chip
                                    icon={<CheckCircleOutlineIcon />}
                                    label="Integrated User"
                                    color="success"
                                    variant="outlined"
                                  />
                                ) : (
                                  <Chip
                                    icon={<InfoIcon />}
                                    label="Standalone User"
                                    color="warning"
                                    variant="outlined"
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleClickOpen(row, true)}>
                                  <MoreHorizIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60px;
    width: 90%;
    margin: 0 5%;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    border-bottom: 0.5px solid #ccc;
    box-shadow: 0px 5px 15px 5px rgba(55, 55, 55, 0.05);
  }

  .container {
    width: 100%;
  }
  .content-container {
    background-color: #f7f7f7;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* border: 1px solid red; */
  }

  .modal-row {
    border: 1px solid black;
    margin: 10% 0;
  }

  .top-container {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-container {
    /* border: 1px solid green; */
  }

  .users-container {
    height: 100%;
  }

  .users-top: {
    height: 10%;
    border: 1px solid green;
  }

  .users-bottom: {
    height: 90%;
    border: 1px solid green;
  }

  .select-container {
    width: 20%;
    height: 100%;
  }

  .select {
    width: 100%;
    height: 60%;
  }
  .button-container {
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default EposUsers;
