import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  TextField,
  Button,
  Modal,
  Box,
  Divider,
  Toolbar,
  Slide,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import MapView from "../../components/MapView";
import SimpleNavBar from "../../components/LocationBreadCrumb";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  addLocation,
  registerNewStore,
  updateStoreData,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";

const initialState = {
  locationName: "",
  locationId: "",
  locationTypes: [],
  locationPin: "",
  latitude: 51,
  longitude: 1,
};

const AddLocation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({ ...initialState });
  const { locations } = useAdmin();

  let navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleMapClick = (_, e) => {
    setFormValue((oldS) => {
      let newState = { ...oldS };
      newState.latitude = e.lngLat.lat;
      newState.longitude = e.lngLat.lng;
      return newState;
    });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormValue(
      (oldS) => {
        let newState = { ...oldS };
        newState.locationTypes =
          typeof value === "string" ? value.split(",") : value;
        return newState;
      }
      // On autofill we get a stringified value.
    );
  };

  const createLocationHelper = async () => {
    let formValueCopy = { ...formValue };
    console.log("formValueCopy:", formValueCopy);

    if (formValueCopy?.locationName?.length === 0) {
      enqueueSnackbar("Invalid location name", {
        variant: "error",
      });
      return;
    }
    if (formValueCopy?.locationPin?.length === 0) {
      enqueueSnackbar("Invalid location pin", {
        variant: "error",
      });
      return;
    }
    if (formValueCopy?.locationTypes?.length === 0) {
      enqueueSnackbar("You must select at least 1 location type", {
        variant: "error",
      });
      return;
    }

    setIsLoading(true);
    const addLocationResp = await addLocation(formValueCopy, enqueueSnackbar);
    if (addLocationResp) {
      navigate("/locations");
    } else {
      setIsLoading(false);
      return;
    }
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Select Store
        </InputLabel> */}
            <LocationBreadCrumb />
          </Toolbar>
        </div>

        <div className="content-container">
          <div className="left">
            <h4 style={{ margin: "1% 0" }}>
              Click on map to set store location
            </h4>
            <MapView
              store={formValue}
              handleClick={handleMapClick}
              markerCoords={[formValue.longitude, formValue.latitude]}
            />
          </div>
          <div className="right">
            <h1 style={{ textAlign: "center", margin: "2% 0 " }}>
              {formValue?.locationName}
            </h1>
            <div className="data-container">
              <div className="data-container-l">
                <h3>Location ID:</h3>
              </div>
              <div className="data-container-r">
                <TextField
                  label="Location ID"
                  required
                  variant="outlined"
                  value={formValue.locationId}
                  onChange={(e) => {
                    let text = e.target.value;
                    setFormValue((oldS) => {
                      let newState = { ...oldS };
                      newState.locationId = text;
                      return newState;
                    });
                  }}
                  style={{ width: "100%" }}
                  // disabled
                />
              </div>
            </div>
            <Divider />
            <div className="data-container">
              <div className="data-container-l">
                <h3>Location Name:</h3>
              </div>
              <div className="data-container-r">
                <TextField
                  required
                  label="Location Name"
                  variant="outlined"
                  onChange={(e) => {
                    let text = e.target.value;
                    setFormValue((oldS) => {
                      let newState = { ...oldS };
                      newState.locationName = text;
                      return newState;
                    });
                  }}
                  value={formValue?.locationName}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <Divider />

            <div className="data-container">
              <div className="data-container-l">
                <h3>Location Pin:</h3>
              </div>
              <div className="data-container-r">
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    required
                    label="Location Pin"
                    variant="outlined"
                    onChange={(e) => {
                      let text = e.target.value;
                      setFormValue((oldS) => {
                        let newState = { ...oldS };
                        newState.locationPin = text;
                        return newState;
                      });
                    }}
                    value={formValue?.locationPin}
                    style={{ width: "100%" }}
                  />
                  <FormHelperText>
                    This is the pin you will use to log in to a device
                    registered to this location
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <Divider />

            <div className="data-container">
              <div className="data-container-l">
                <h3>Location Types:</h3>
              </div>
              <div className="data-container-r">
                <FormControl required sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Location Types
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={formValue?.locationTypes}
                    label="Location Types"
                    onChange={handleChange}>
                    <MenuItem value={"store"}>Store</MenuItem>
                    <MenuItem value={"factory"}>Factory</MenuItem>
                    <MenuItem value={"warehouse"}>Warehouse</MenuItem>
                  </Select>
                  <FormHelperText>
                    These are the location types that this location accommodates
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <Divider />

            <div className="confirm-changes-container">
              <Button variant="contained" onClick={createLocationHelper}>
                Create Location
              </Button>
            </div>
            <Divider />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;

    height: 90vh;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* border: 1px solid red; */
    height: 90vh;
  }
  .right {
    display: flex;
    flex: 2;
    /* border: 1px solid red; */
    /* height: 100%; */
    flex-direction: column;
  }

  .data-container {
    /* height: 15vh; */
    margin: 2vh 0;
    /* border: 1px solid red; */
    padding: 0 2.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .confirm-changes-container {
    /* height: 15vh; */
    margin: 4vh 0;
    /* border: 1px solid red; */
    padding: 0 2.5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .data-container-l {
    width: 35%;
    height: 100%;
    /* border: 1px solid red; */
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10%;
  }
  .data-container-r {
    width: 65%;
    height: 100%;
    /* border: 1px solid red; */
    padding: 0 2.5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .modal {
    height: 20vh;
    border: 5px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .times-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2vh;
  }

  .day-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .times-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`;

export default AddLocation;
