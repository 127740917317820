import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Button,
  Toolbar,
  Select,
  Divider,
  MenuItem,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  addProduct,
  fetchProducts,
  getMenus,
  getTaxGroups,
  uploadMenu,
  validateNewProductFields,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import EnhancedTable from "../../components/Table";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { ButtonGroup } from "rsuite";
import { useSnackbar } from "notistack";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { FileUploader } from "react-drag-drop-files";
import FormData from "form-data";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const AddMenu = ({ setAddMenuScreen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [menuName, setMenuName] = useState("");
  const [file, setFile] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (file) => {
    console.log("file:", file);
    setFile(file);
  };
  const onTypeError = (error) => {
    console.log("error:", error);
  };

  let fileTypes = ["png", "jpg", "jpeg", "pdf"];

  const handleSubmission = async () => {
    if (menuName.length === 0) {
      enqueueSnackbar("Invalid menu name", { variant: "error" });
      return;
    }

    const formData = new FormData();
    const authToken = window.localStorage.getItem("auth_token");
    formData.set("admin_token", authToken);
    formData.set("menu_name", menuName);
    formData.append("file", file, file.name);

    console.log("file:", formData.get("admin_token"));

    setIsLoading(true);
    const resp = await uploadMenu(formData, enqueueSnackbar);
    if (resp) {
      setAddMenuScreen(false);
    }
    setIsLoading(true);
  };

  return (
    <div className="add-menu-container">
      {isLoading && <Loader />}
      <TextField
        className="text-input"
        label="Menu Name"
        variant="outlined"
        onChange={(e) => {
          const text = e.target.value;
          setMenuName(text);
        }}
        value={menuName}
      />
      {file && (
        <Button
          variant="contained"
          component="span"
          onClick={() => setFile(null)}>
          Remove File
        </Button>
      )}

      <FileUploader
        handleChange={handleChange}
        onTypeError={onTypeError}
        name="file"
        //   label="afdsd"
        hoverTitle="drop here &nbsp;"
        classes="file-uploader"
        fileOrFiles={file}
        types={fileTypes}
      />
      {file && (
        <Button variant="contained" component="span" onClick={handleSubmission}>
          Submit
        </Button>
      )}
    </div>
  );
};

const Menus = () => {
  const [menus, setMenus] = useState(null);
  const [addMenuScreen, setAddMenuScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const storage = getStorage();

  // Create a reference under which you want to list
  const listRef = ref(storage, "menus");

  function getImgUrl(itemRef) {
    return new Promise((resolve, reject) => {
      getDownloadURL(ref(storage, itemRef.fullPath))
        .then((url) => {
          let menuObj = {
            name: itemRef.name,
            path: itemRef.fullPath,
            url: null,
          };
          menuObj.url = url;
          resolve(menuObj);
        })
        .catch((err) => {
          console.error("err:", err);
          reject();
        });
    });
  }

  function someFunction(resp) {
    let promises = [];
    resp.items.forEach((itemRef) => {
      console.log("itemRef:", itemRef);

      promises.push(getImgUrl(itemRef));
    });
    return Promise.all(promises);
  }

  const getMenusHelper = async () => {
    try {
      setIsLoading(true);
      const resp = await listAll(listRef);
      if (resp) {
        someFunction(resp)
          .then((results) => {
            console.log("results:", results);
            setMenus(results);
          })
          .catch((err) => {
            console.log(err);
          });

        // setMenus(menusArr);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("error:", error);
      enqueueSnackbar("Something went wrong when trying to get menus", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const deleteFile = async (fullFilePath) => {
    if (
      window.confirm(
        "Are you sure you want to delete this menu. All TV's using this menu will be given a black screen"
      )
    ) {
      const fileToDeleteRef = ref(storage, fullFilePath);
      setIsLoading(true);
      // Delete the file
      deleteObject(fileToDeleteRef)
        .then(() => {
          enqueueSnackbar("Successfully delete file", { variant: "success" });
          getMenusHelper();
          // File deleted successfully
        })
        .catch((error) => {
          setIsLoading(false);
          enqueueSnackbar("Something went wrong when trying to delete file", {
            variant: "error",
          });
          console.log("error:", error);
        });
    }
  };

  useEffect(() => {
    if (!addMenuScreen) {
      getMenusHelper();
    }
  }, [addMenuScreen]);

  console.log("menus:", menus);
  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>

        <div className="header-container">
          <h2>
            <u>Menus</u>
          </h2>
          <Button
            onClick={() => {
              setAddMenuScreen(!addMenuScreen);
            }}
            variant="contained"
            style={{ position: "absolute", right: "5%" }}>
            {addMenuScreen ? "Go Back" : "Add Menu"}
          </Button>
        </div>
        {addMenuScreen ? (
          <AddMenu setAddMenuScreen={setAddMenuScreen} />
        ) : (
          <div className="menu-container">
            {menus && menus?.length > 0 ? (
              menus.map((menu, i) => {
                console.log("menu:", menu);

                return (
                  <Card
                    key={i}
                    sx={{
                      width: "350px",
                      height: "30vh",
                      margin: "2%",
                    }}>
                    <CardMedia
                      component="img"
                      style={{ width: "auto", height: "50%", margin: "0 auto" }}
                      image={menu.url}
                      alt={menu.name}
                    />
                    <CardContent
                      style={{
                        // border: "1px solid black",

                        width: "100%",
                        overflow: "hidden",
                      }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {menu.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {menu.filePath}
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        onClick={() => {
                          deleteFile(menu.path);
                        }}
                        size="small"
                        color="error"
                        variant="text">
                        Delete
                      </Button>
                      <Button size="small" variant="contained">
                        View
                      </Button>
                    </CardActions>
                  </Card>
                );
              })
            ) : (
              <h2>No Menus</h2>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .header-container {
    height: 10vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-container {
    height: 80vh;
    width: 100%;
    padding: 2.5%;
    display: grid;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  .add-menu-container {
    height: 80vh;
    width: 100%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  .file-uploader {
    height: 50vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #364f78;
  }
`;

export default Menus;
