import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
// import { Switch } from "react-router-dom";
import ConfirmAccount from "./pages/ConfirmAccount";
import SalesScreen from "./pages/sales/SalesScreen";
import Error from "./pages/Error";
import HomePage from "./pages/HomePage";
import ResetPassword from "./pages/ResetPassword";
import PrivateRoute from "./PrivateRoute";
import Locations from "./pages/stores/Locations";
import ProductsHome from "./pages/products/ProductsHome";
import Products from "./pages/products/Products";
import EposGroups from "./pages/products/EposGroups";
import SingleEposGroup from "./pages/products/SingleEposGroup";
import SingleProduct from "./pages/products/SingleProduct";
import OptionGroups from "./pages/products/AddOption";
import Schedule from "./pages/shiftPlanner/Scheduler";
import Staff from "./pages/users/Staff";
import EmployeeProfile from "./pages/shiftPlanner/EmployeeProfile";
import StoreGroups from "./pages/stores/StoreGroups";
import Warehouses from "./pages/warehouses/Warehouses";
import SingleWarehouse from "./pages/warehouses/SingleWarehouse";
import AllPlu from "./pages/products/AllPlu";
import Settings from "./pages/settings/Settings";
import AddUser from "./pages/users/AddUser";
import AddLocation from "./pages/stores/AddLocation";
import DiscountSupport from "./pages/support/DiscountSupport";
import CouponSupport from "./pages/support/CouponSupport";
import SingleLocationMenu from "./pages/stores/SingleLocationMenu";
import ViewStoreReports from "./pages/stores/ViewStoreReports";
import RegisteredDevices from "./pages/devices/RegisteredDevices";
import UnregisteredDevices from "./pages/devices/UnregisteredDevices";
import AddDevice from "./pages/stores/AddDevice";
import Menus from "./pages/products/Menus";
import SingleLocation from "./pages/stores/StoreDetails";
import Customers from "./pages/customers/Customers";
import EposUsers from "./pages/users/EposUsers";

const baseName = process.env.REACT_APP_BASENAME;

const ScreenRoutes = () => {
  return (
    <BrowserRouter>
      {window.location.host.split(".")[0] === "support" ? (
        <Routes>
          <Route exact path="/" element={<DiscountSupport />} />
          <Route exact path="/discounts" element={<DiscountSupport />} />
          <Route exact path="/payments" element={<DiscountSupport />} />
          <Route exact path="/coupons" element={<CouponSupport />} />
          <Route path="*" element={<Error homeLink={`${baseName}/`} />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/createaccount" element={<ConfirmAccount />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route exact path="/login" element={<Login />} />

          <Route
            exact
            path="/locations"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Locations />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/storegroups"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <StoreGroups />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/addproducts"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <ProductsHome />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/products/:productId"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <SingleProduct />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/products"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Products />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/eposgroups"
            element={
              <PrivateRoute>
                <EposGroups />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/options"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <OptionGroups />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/allplu"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <AllPlu />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/eposgroups/:eposGroupId"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <SingleEposGroup />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/locations/addlocation"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <AddLocation />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/locations/:locationId"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <SingleLocationMenu />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/locations/:locationId/viewstorereports"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <ViewStoreReports />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/locations/:locationId/storedetails"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <SingleLocation />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/locations/:locationId/adddevice"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <AddDevice />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/sales"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <SalesScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/menus"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Menus />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/registereddevices"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <RegisteredDevices />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/unregistereddevices"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <UnregisteredDevices />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/settings"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Settings />
              </PrivateRoute>
            }
          />

          {/* WAREHOUSES */}
          <Route
            exact
            path="/warehouses"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Warehouses />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/warehouses/:locationId"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <SingleWarehouse />
              </PrivateRoute>
            }
          />
          {/* END OF WAREHOUSES  */}

          <Route
            exact
            path="/staff"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Staff />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/eposusers"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <EposUsers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/customers"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Customers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/staff/adduser"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/staff/:userId"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <EmployeeProfile />
              </PrivateRoute>
            }
          />

          {/* SHIFT PLANNER  */}
          <Route
            exact
            path="/schedule"
            element={
              <PrivateRoute requiredRoles={["admin", "manager"]}>
                <Schedule />
              </PrivateRoute>
            }
          />

          {/* END OF SHIFT PLANNER  */}
          <Route exact path="/" element={<HomePage />} />
          <Route path="*" element={<Error homeLink={`${baseName}/`} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default ScreenRoutes;
