import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Navigation from "../navigation/Navigation";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import {
  Button,
  Toolbar,
  Select,
  Divider,
  MenuItem,
  TextField,
  FormControl,
} from "@mui/material";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import AddIcon from "@mui/icons-material/Add";
import {
  createWarehouse,
  getGlobalSkuData,
  getLocations,
  getWarehouses,
} from "../../helpers/helpers";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { useSnackbar } from "notistack";
import WarehouseTransferList from "./WarehouseTransferList";
import WarehouseComp from "./WarehouseComp";

const initialState = {
  warehouseName: "",
  allSkus: [],
  locationId: "",
  tags: [""],
};

const Warehouses = () => {
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [locations, setLocations] = useState(null);
  const [warehouses, setWarehouses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addWarehouseScreen, setAddWarehouseScreen] = useState(false);
  const [formValue, setFormValue] = useState(initialState);

  const [globalSkus, setGlobalSkus] = useState([]);
  const [globalSkusData, setGlobalSkusData] = useState({});
  const [skuStockData, setSkuStockData] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getLocations(setLocations, setIsLoading, enqueueSnackbar);
    getWarehouses(setWarehouses, setIsLoading, enqueueSnackbar);
    getGlobalSkuData(
      setGlobalSkus,
      setGlobalSkusData,
      setIsLoading,
      enqueueSnackbar
    );
    // eslint-disable-next-line
  }, []);

  console.log("selectedSkus:", selectedSkus);

  return (
    <Wrapper style={{ backgroundColor: colors.backgroundColor }}>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        {addWarehouseScreen ? (
          <div className="content-container">
            <div className="form-container">
              <div className="header-container-add">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#777",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    setAddWarehouseScreen(false);
                  }}>
                  Go Back
                </Button>
                <h2>New Warehouse:</h2>
                <Button
                  variant="contained"
                  onClick={async () => {
                    let formValueCopy = { ...formValue };
                    formValueCopy.allSkus = selectedSkus;

                    let filteredSkuStockData = {};

                    console.log("Object.keys(skuStockData)", skuStockData);

                    for (let sku of Object.keys(skuStockData)) {
                      console.log("sku:", sku);
                      if (selectedSkus.includes(sku)) {
                        console.log("skusssss:", skuStockData[sku]);

                        filteredSkuStockData[sku] = {
                          ...skuStockData[sku],
                          sku: sku,
                        };
                      }
                    }

                    formValueCopy.skuStockData = filteredSkuStockData;

                    console.log("filteredSkuStockData:", filteredSkuStockData);

                    await createWarehouse(
                      formValueCopy,
                      setWarehouses,
                      setIsLoading,
                      enqueueSnackbar
                    );
                    setFormValue(initialState);
                    setAddWarehouseScreen(false);
                  }}>
                  Submit
                </Button>
              </div>
              <Divider />

              <div className="data-container">
                <div className="data-row">
                  <div className="data-col">
                    <h3>Warehouse Name:</h3>
                  </div>
                  <div className="data-col">
                    <TextField
                      value={formValue.warehouseName}
                      onChange={(e) => {
                        setFormValue((oldS) => {
                          let newState = { ...oldS };
                          newState.warehouseName = e.target.value;
                          return newState;
                        });
                      }}
                      style={{ width: "90%" }}
                      label="Name"
                      variant="outlined"
                      type="search"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <Divider />

                <div className="data-row">
                  <div className="data-col">
                    <h3>Select Warehouse Location:</h3>
                  </div>

                  <div className="data-col">
                    <FormControl style={{ width: "90%" }}>
                      {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formValue.locationId}
                        label="Location"
                        onChange={(event) => {
                          console.log("event:", event);
                          setFormValue((oldS) => {
                            let newState = { ...oldS };
                            newState.locationId = event.target.value;
                            return newState;
                          });
                        }}>
                        {locations?.length > 0 &&
                          locations.map((location, i) => {
                            return (
                              <MenuItem value={location?.locationId}>
                                {location?.locationName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <Divider />
                <div className="item-container">
                  <h3>Warehouse Tags:</h3>
                  {formValue.tags.map((tag, i) => {
                    return (
                      <div className="data-row">
                        <div
                          className="data-col"
                          style={{ justifyContent: "space-around" }}>
                          <button
                            onClick={() => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                let itemOptions = [...newState.tags];
                                itemOptions.splice(i, 1);
                                newState.tags = itemOptions;
                                return newState;
                              });
                            }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}>
                            <RemoveCircleIcon style={{ color: "#f54242" }} />
                          </button>

                          <h4>Tag {i + 1}:</h4>
                        </div>
                        <div className="data-col">
                          <TextField
                            value={formValue.tags[i]}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.tags[i] = e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            id="outlined-basic"
                            label="Tag"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setFormValue((oldS) => {
                        let newState = { ...oldS };
                        newState.tags.push("");
                        console.log("newState:", newState);
                        return newState;
                      });
                    }}>
                    Add Tag
                  </Button>
                </div>
                <Divider />
                <WarehouseTransferList
                  setSelectedSkus={setSelectedSkus}
                  selectedSkus={selectedSkus}
                  globalSkus={globalSkus}
                  setGlobalSkus={setGlobalSkus}
                  globalSkusData={globalSkusData}
                  skuStockData={skuStockData}
                  setSkuStockData={setSkuStockData}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="add-button"
              onClick={() => {
                setAddWarehouseScreen(true);
              }}>
              <AddIcon style={{ color: "white" }} />
            </div>
            <div className="grid-container">
              {warehouses?.length > 0 ? (
                warehouses.map((warehouse, i) => {
                  console.log("warehouse:", warehouse);
                  return <WarehouseComp warehouse={warehouse} />;
                })
              ) : (
                <h2>No Warehouses</h2>
              )}
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // height: 10vh;
  display: flex;
  position: relative;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;

    /* height: 100vh; */
    /* boredr: 1px solid red; */
  }

  .grid-container {
    margin: 4% 2%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2% 1.1%;
    /* border: 1px solid red; */
  }

  .grid-item {
    height: 45vh;
    width: 25vw;
    /* border: 1px solid red; */
    border-radius: 10px;
    background-color: white;
  }

  .add-button {
    z-index: 999;
    position: fixed;
    top: 85%;
    right: 5%;
    background-color: rgb(56, 116, 203);
    border-radius: 100px;
    width: 7vh;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-button:hover {
    cursor: pointer;
  }
  .content-container {
    display: flex;
    width: 100%;

    /* height: 90vh; */
    /* height: 80%; */
    overflow: scroll;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid red; */
  }

  .form-container {
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    width: 90%;
    height: 95%;
    margin: 2.5% 5%;
    overflow-y: scroll;
  }

  .header-container-add {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20%;
  }

  .data-container {
    width: 100%;
    height: 90%;
    text-align: center;
    /* border: 1px solid black; */
    padding: 0 2%;
    /* display: flex; */
    /* background-color: #f9f9f9; */
    /* justify-content: space-around;
    align-items: center;
    justify-content: center; */
  }

  .data-row {
    width: 100%;
    display: flex;
    margin: 2% 0;
  }
  .data-col {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-container {
    /* border: 1px solid black; */
    margin: 2.5% 0;
  }
`;

export default Warehouses;
