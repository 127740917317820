import { Button } from "@mui/material";
import React from "react";
import { PDFViewer } from "react-view-pdf";

const PDFView = ({ pdfUrl, setPdfUrl }) => {
  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          setPdfUrl(null);
        }}>
        Go Back
      </Button>
      <PDFViewer url={pdfUrl} />
    </div>
  );
};

export default PDFView;
