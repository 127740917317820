import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  TextField,
  Button,
  Alert,
  CircularProgress,
  Collapse,
  IconButton,
} from "@mui/material";
import { useAuth } from "../../context/auth_context";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { loginUserByToken } from "../../helpers/helpers";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const { setUser, currentUser } = useAuth();

  const REACT_APP_API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

  const handleLoginSubmit = async () => {
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${REACT_APP_API_ADDRESS}/login_user_by_email_password`,
        formValues
      );

      console.log("login_user_by_email_password resp: ", resp.data);

      if (resp?.data?.token) {
        const authToken = resp?.data?.token;

        // save token to local storage
        window.localStorage.setItem("auth_token", authToken);

        setIsLoading(false);

        // save user and token to context
        await setUser(
          resp?.data?.acceptedInvitation,
          resp?.data?.name,
          resp?.data?.email,
          resp?.data?.emailVerified,
          resp?.data?.createdAt,
          resp?.data?.updatedAt,
          resp?.data?.deviceTokens,
          resp?.data?.uid,
          resp?.data?.token,
          resp?.data?.firebaseToken,
          resp?.data?.claims
        );

        navigate("/sales", { replace: true });

        //show success alert
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setErrorVisible(true);
    }
  };

  return (
    <Wrapper>
      {isLoading && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            right: "50%",
            translateX: "-50%",
            translateY: "-50%",
            zIndex: 99,
          }}
        />
      )}
      {errorVisible && (
        <Collapse
          in={errorVisible}
          style={{ position: "absolute", top: "5%", right: "5%" }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorVisible(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}>
            Something Went Wrong, Error Code: 8004981
          </Alert>
        </Collapse>
      )}
      <div className="form-container">
        <div className="top">
          <h1>Login to Admin Portal</h1>
        </div>
        <div className="middle">
          <TextField
            required
            className="textInput"
            margin="normal"
            id="standard-basic"
            onChange={(e) => {
              setFormValues((oldS) => ({ ...oldS, email: e.target.value }));
            }}
            label="Email"
            type="email"
            autoComplete="email"
          />
          <TextField
            required
            className="textInput"
            margin="normal"
            label="Password"
            onChange={(e) => {
              setFormValues((oldS) => ({ ...oldS, password: e.target.value }));
            }}
            type="password"
            autoComplete="current-password"
          />
        </div>
        <div className="bottom">
          {/* <h4>{formValues.email}</h4> */}
          {/* <h4>{formValues.password}</h4> */}
          <Button variant="contained" onClick={handleLoginSubmit}>
            Login
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  h1 {
    color: #444;
  }

  .textInput {
    width: 35vw;

    @media (max-width: 600px) {
      width: 70vw;
    }
  }

  .form-container {
    height: 80vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    border-radius: 30px;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
    border: 2px solid #ccc;

    @media (max-width: 600px) {
      width: 90vw;
      height: 90vh;
    }
  }

  .top {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #ccc;
  }

  .middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bottom {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #ccc;
  }
  errorAlert: {
      position: absolute;
      top: 0
      right: 0
  }
`;

export default Login;
