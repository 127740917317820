import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  TextField,
  Box,
  Button,
  Tabs,
  Tab,
  ButtonGroup,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CardContent,
  Card,
  Avatar,
  Checkbox,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import Loader from "./Loader";
import { useSnackbar } from "notistack";

import { useAdmin } from "../context/admin_context";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import EventIcon from "@mui/icons-material/Event";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import PatternIcon from "@mui/icons-material/Pattern";
import PasswordIcon from "@mui/icons-material/Password";
import InfoIcon from "@mui/icons-material/Info";
import { stringAvatar, updateUserPermissions } from "../helpers/helpers";
import { useAuth } from "../context/auth_context";
var _ = require("lodash");

const EmployeeSupportComp = () => {
  let location = useLocation();
  const user = location?.state;

  const [hasChanged, setHasChanged] = useState(false);
  const { currentUser, userStores } = useAuth();

  const [roles, setRoles] = useState([...currentUser?.claims?.userRoles]);
  const [manageStores, setManageStores] = useState([...userStores]);

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      !_.isEqual(currentUser?.claims?.userRoles, roles) ||
      !_.isEqual(currentUser?.claims?.userStores, manageStores)
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [manageStores, roles]);

  const { locations } = useAdmin();

  console.log("location.state:", location.state);

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  const updateUserPermissionsHelper = async () => {
    setIsLoading(true);
    const resp = await updateUserPermissions(user?.uid, roles, manageStores);
    if (resp) {
      enqueueSnackbar(`Successfully updated user permissions`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(
        `Something went wrong when trying to update user permissions`,
        {
          variant: "error",
        }
      );
    }
    setIsLoading(false);
  };

  return (
    <Wrapper>
      {hasChanged && (
        <div
          className="section"
          style={{
            height: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
          }}>
          <Button variant="contained" onClick={updateUserPermissionsHelper}>
            Save
          </Button>
        </div>
      )}

      <div className="section">
        <div className="item-container">
          <h3>User Permissions:</h3>

          <div className="data-row">
            <div className="data-col">
              <h3 className="row-title"> Permissions:</h3>
            </div>
            <div className="data-col">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={roles}
                onChange={(e) => {
                  const role = e.target.value;
                  if (role === "admin" || role === "manager") {
                    setManageStores(["all_stores"]);
                  } else {
                    if (manageStores[0] === "all_stores") {
                      setManageStores([]);
                    }
                  }

                  setRoles([role]);
                }}>
                <FormControlLabel
                  value="staff"
                  control={<Radio />}
                  label="Staff"
                />
                <FormControlLabel
                  value="manager"
                  control={<Radio />}
                  label="Manager"
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
              </RadioGroup>
            </div>
          </div>
          <div className="data-row">
            <div className="data-col">
              <h3 className="row-title"> Manage stores:</h3>
            </div>
            <div className="data-col">
              <Select
                value={manageStores || []}
                multiple
                renderValue={(selected) => {
                  return selected.join(", ");
                }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  setManageStores(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                label="Manage manageStores"
                style={{
                  width: "50%",
                  marginRight: "5%",
                  height: "100%",
                }}
                // onChange={handleChange}
              >
                {manageStores?.indexOf("all_stores") === -1 &&
                  locations &&
                  locations.map((location, i) => {
                    return (
                      <MenuItem key={i} value={location.locationId}>
                        <Checkbox
                          checked={
                            manageStores.indexOf(location.locationId) > -1
                          }
                        />
                        <ListItemText primary={location.locationName} />
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: scroll;

  .section {
    height: 45%;
    max-height: 45%;
    width: 100%;
    /* border: 1px solid black; */
  }

  .text-input {
    width: 60%;
  }

  .card {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.3s;
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .card:hover {
    transform: scale(1.1);
  }
`;

export default EmployeeSupportComp;
