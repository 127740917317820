import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoIcon from "@mui/icons-material/Info";

export const SupportHeader = ({ header }) => {
  return (
    <div
      style={{
        height: "7vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "10%",
      }}>
      <h2 style={{ fontFamily: "Merriweather Sans, sans-serif" }}>{header}</h2>
    </div>
  );
};

export const SupportSubHeader = ({ header }) => {
  return (
    <div
      style={{
        height: "7vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "10%",
        // backgroundColor: "red",
      }}>
      <h3
        style={{
          fontFamily: "Merriweather Sans, sans-serif",
        }}>
        {header}
      </h3>
    </div>
  );
};

export const SupportText = ({ children }) => {
  return (
    <div
      style={{
        padding: " 0 10%",
        fontFamily: "Merriweather Sans",
        lineHeight: "25px",
      }}>
      {children}
    </div>
  );
};

export const SupportWarnings = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: "rgb(255,204,0)",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1%",
        margin: "2% 0",
      }}>
      <div
        style={{
          width: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <WarningAmberIcon />
      </div>
      <div
        style={{
          width: "95%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
        {text}
      </div>
    </div>
  );
};

export const SupportInfo = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: "rgb(173, 216, 230)",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1%",
        margin: "2% 0",
      }}>
      <div
        style={{
          width: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <InfoIcon />
      </div>
      <div
        style={{
          width: "95%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
        {text}
      </div>
    </div>
  );
};

export const SupportImg = ({ src, alt }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "2.5% 10%",
      }}>
      <img src={src} alt={alt} style={{ height: "auto", width: "70%" }} />
    </div>
  );
};

export const SupportImgDouble = ({ src1, alt1, src2, alt2 }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "5%",
        padding: "2.5% 10%",
      }}>
      <img src={src1} alt={alt1} style={{ height: "auto", width: "100%" }} />

      <img src={src2} alt={alt2} style={{ height: "auto", width: "100%" }} />
    </div>
  );
};
