import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import update from "immutability-helper";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
  TextField,
  DialogActions,
  Paper,
  FormControl,
  FormLabel,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  addCategoryToEposGroup,
  addProductsToEposGroup,
  createEposGroup,
  createEposGroupFromCopy,
  fetchEposGroups,
  fetchProducts,
  showScreensUsingAScreen,
  removeItemFromEposGroupScreen,
  updateDisplayOrder,
  addShortcutBtn,
  deleteShortcutBtn,
  getIconForShortcutBtn,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import ImageIcon from "@mui/icons-material/Image";
import SingleEposGroup from "./SingleEposGroup";
import SingleCategory from "./SingleCategory";
import { useSnackbar } from "notistack";
import CategoryIcon from "@mui/icons-material/Category";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IpadTemplate from "../../resources/img/ipad-template.png";
import { grid } from "@mui/system";
import DnDTill, { DnDItem } from "../../components/DnDItem";
import SearchComp from "../../components/SearchComp";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import EmojiFoodBeverageOutlinedIcon from "@mui/icons-material/EmojiFoodBeverageOutlined";
import LocalBarOutlinedIcon from "@mui/icons-material/LocalBarOutlined";
import BakeryDiningOutlinedIcon from "@mui/icons-material/BakeryDiningOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import TakeoutDiningOutlinedIcon from "@mui/icons-material/TakeoutDiningOutlined";

const EposGroups = () => {
  const [open, setOpen] = useState(false);
  const [newScreenOpen, setNewScreenOpen] = useState(false);
  const [newItemValue, setNewItemValue] = useState({
    itemName: "",
    itemType: "product",
  });
  const [eposGroups, setEposGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [currScreen, setCurrScreen] = useState(null);
  const [currDisplayItems, setCurrDisplayItems] = useState(null);
  const [oldCurrScreen, setOldCurrScreen] = useState(null);
  const [products, setProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [tags, setTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [screenInUseDialog, setScreenInUseDialog] = useState({
    show: false,
    data: null,
  });
  const [addShortcutBtnDialog, setAddShortcutBtnDialog] = useState({
    open: false,
    iconName: "",
    productId: "",
  });

  let { storeId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    fetchEposGroups(setEposGroups);
  }, []);

  console.log("eposGroups:", eposGroups);

  const onSelectGroup = (e) => {
    const eposGroupName = e.target.value;
    const [selectedGroupItem] = eposGroups.filter(
      (gr) => gr.eposGroupName === eposGroupName
    );

    if (selectedGroupItem) {
      console.log("selected selectedGroupItem:", selectedGroupItem);

      setSelectedGroup(selectedGroupItem);
      setCurrScreen({
        items: selectedGroupItem?.items?.home_screen?.displayOrder,
        itemId: "home_screen",
        itemLabel: "Home Screen",
        // itemLabel: itemLabel,
      });
    }
    // console.log("target", e.target);
    // console.log("eposGroup", eposGroup);
    // navigate(`${eposGroup}`, { state: eposGroups[i] });
  };

  const addNewGroupFn = async (eposGroupName) => {
    setIsLoading(true);
    const eposGroupResp = await createEposGroup(eposGroupName, enqueueSnackbar);
    console.log("eposGroupResp:", eposGroupResp);
    setIsLoading(false);
    setSelectedGroup(eposGroupResp);
  };

  const removeAnEposGroupScreenHelper = async () => {
    console.log("selectedGroup:", selectedGroup);
    if (currScreen?.itemId === "home_screen") {
      enqueueSnackbar(`You can not delete the top level screen`, {
        variant: "warning",
      });
    } else {
      if (currScreen?.items?.length > 0) {
        enqueueSnackbar(`You can not delete a screen that has items in it`, {
          variant: "warning",
        });
      } else {
        setIsLoading(true);
        const hasDeletedResp = await showScreensUsingAScreen(
          selectedGroup?.eposGroupId,
          currScreen?.itemId,
          enqueueSnackbar
        );

        if (hasDeletedResp?.length > 0) {
          setScreenInUseDialog({
            show: true,
            data: hasDeletedResp,
          });
          enqueueSnackbar(
            `This screen is currently in use by ${hasDeletedResp?.length} screen(s)`,
            {
              variant: "warning",
            }
          );
        }

        setIsLoading(false);
      }
    }
  };

  const addNewGroupFromCopyFn = (newTillGroupName) => {
    const isNameDuplicate = eposGroups.some(
      (eposG) => eposG.eposGroupName === newTillGroupName
    );

    if (isNameDuplicate) {
      enqueueSnackbar(`This Epos group name already exists!`, {
        variant: "error",
      });
    } else {
      createEposGroupFromCopy(
        selectedGroup?.eposGroupId,
        newTillGroupName,
        setEposGroups,
        setIsLoading,
        enqueueSnackbar
      );
    }
  };

  useEffect(() => {
    if (selectedGroup && currScreen?.itemId) {
      if (selectedGroup?.items[currScreen?.itemId]?.displayOrder) {
        setCurrDisplayItems({
          displayOrder: selectedGroup.items[currScreen?.itemId].displayOrder,
          productIds: selectedGroup.productIds,
        });
      }
    }
  }, [selectedGroup]);

  const addItemToEposGroupFn = async () => {
    console.log("selectedProducts:", selectedProducts);

    if (newItemValue.itemType === "category") {
      console.log(
        "Object.keys(selectedGroup.items)",
        Object.keys(selectedGroup.items)
      );
      for (let screen_id of Object.keys(selectedGroup.items)) {
        console.log("screen_id:", screen_id);
        console.log(
          "new_screen_Id:",
          newItemValue.itemName.toLowerCase().replace(" ", "-")
        );
        if (
          screen_id === newItemValue.itemName.toLowerCase().replaceAll(" ", "-")
        ) {
          enqueueSnackbar(
            `You can not have two categories with the same name`,
            {
              variant: "warning",
            }
          );
          return;
        }
      }

      addCategoryToEposGroup(
        selectedGroup?.eposGroupId,
        newItemValue.itemName,
        currScreen?.itemId,
        setSelectedGroup,
        setIsLoading,
        enqueueSnackbar,
        currScreen,
        setCurrScreen
      );
      setNewItemValue({
        itemName: "",
        itemType: "product",
      });
      setNewScreenOpen(false);
    } else if (newItemValue.itemType === "product") {
      if (selectedProducts?.length === 0) {
        enqueueSnackbar(`You have not selected any products`, {
          variant: "warning",
        });
      } else {
        /*
        first we need to check if one of the products we are
        trying to add is already in the screen
        */

        console.log("currScreen:", currScreen);
        console.log("selectedGroup:", selectedGroup);
        let productIdsInScreen = [];
        let productAlreadyInScreen = null;
        currScreen.items.map((currScreenItem) => {
          if (currScreenItem.itemType === "product") {
            productIdsInScreen.push(currScreenItem.itemId);
          }
        });
        console.log("productIdsInScreen", productIdsInScreen);

        selectedProducts.map((prod) => {
          if (productIdsInScreen?.includes(prod?.productId)) {
            productAlreadyInScreen = prod;
          }
        });

        if (productAlreadyInScreen) {
          enqueueSnackbar(
            `Product: (${productAlreadyInScreen?.productName}) is already in this screen and can not be added again`,
            {
              variant: "warning",
            }
          );
        } else {
          addProductsToEposGroup(
            selectedGroup?.eposGroupId,
            currScreen?.itemId,
            selectedProducts,
            setSelectedGroup,
            setCurrScreen,
            setIsLoading,
            enqueueSnackbar
          );
          setNewItemValue({
            itemName: "",
            itemType: "product",
          });
          setNewScreenOpen(false);
        }
      }
    }
  };

  const removeItemFromEposGroupFn = (itemId) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${itemId} from this screen. This action can not be undone`
      )
    ) {
      removeItemFromEposGroupScreen(
        selectedGroup?.eposGroupId,
        itemId,
        currScreen?.itemId,
        setSelectedGroup,
        setCurrScreen,
        setIsLoading,
        enqueueSnackbar
      );
    }
  };
  const addShortcutBtnFn = () => {
    if (addShortcutBtnDialog?.iconName?.length === 0) {
      enqueueSnackbar(`Please select an icon`, {
        variant: "error",
      });
      return;
    }
    if (addShortcutBtnDialog?.productId?.length === 0) {
      enqueueSnackbar(`Please select a product`, {
        variant: "error",
      });
      return;
    }
    addShortcutBtn(
      selectedGroup?.eposGroupId,
      addShortcutBtnDialog.iconName,
      addShortcutBtnDialog.productId,
      setSelectedGroup,
      setIsLoading,
      enqueueSnackbar
    );
  };

  const deleteShortcutBtnFn = (productId) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${productId} shortcut button from this screen. This action can not be undone`
      )
    ) {
      deleteShortcutBtn(
        selectedGroup?.eposGroupId,
        productId,
        setSelectedGroup,
        setIsLoading,
        enqueueSnackbar
      );
    }
  };

  const updateDisplayOrderFn = () => {
    console.log("currScreen.ITEMiD", currScreen?.itemId);
    console.log("currDisplayItems.ITEMiD", currDisplayItems);

    let displayOrder = [...currDisplayItems?.displayOrder];

    for (let i = 0; i < displayOrder?.length; i++) {
      let currDisplayItem = displayOrder[i];
      console.log("currDisplayItem", currDisplayItem);
      currDisplayItem.item_id = currDisplayItem?.itemId;
      currDisplayItem.item_type = currDisplayItem?.itemType;
      currDisplayItem.display_name = currDisplayItem?.displayName;
      delete currDisplayItem?.itemId;
      delete currDisplayItem?.itemType;
      delete currDisplayItem?.displayName;
    }

    console.log("displayOrder: ", displayOrder);

    updateDisplayOrder(
      selectedGroup?.eposGroupId,
      currScreen?.itemId,
      displayOrder,
      setSelectedGroup,
      setIsLoading,
      enqueueSnackbar
    );
  };

  useEffect(() => {
    fetchProducts(setProducts, enqueueSnackbar, setIsLoading);
  }, []);

  useEffect(() => {
    if (products) {
      let tags = [];
      products.map((opt) => {
        if (opt.tags) {
          opt.tags.map((tag) => {
            if (!tags.includes(tag)) {
              tags.push(tag);
            }
          });
        }
      });
      setTags(tags);
    }
  }, [products]);

  useEffect(() => {
    if (selectedTags.length > 0) {
      const filteredProd = [];
      products.filter((opt) => {
        let isAllTagsInOption = true;
        selectedTags.map((tag) => {
          if (!opt.tags.includes(tag)) {
            isAllTagsInOption = false;
          }
        });

        if (isAllTagsInOption) {
          if (!filteredProd.includes(opt)) {
            filteredProd.push(opt);
          }
        }
      });
      setFilteredProducts(filteredProd);
    } else {
      setFilteredProducts(null);
    }
  }, [selectedTags]);

  const handleToggle = (opt) => () => {
    console.log("opt:", opt);

    let isInArr = { isInArr: false, index: null };

    selectedProducts.map((product, i) => {
      if (product.productId === opt.productId) {
        isInArr = { isInArr: true, index: i };
      }
    });
    const newChecked = [...selectedProducts];

    if (isInArr.isInArr) {
      newChecked.splice(isInArr.index, 1);
    } else {
      newChecked.push(opt);
    }

    console.log("newChecked:", newChecked);
    setSelectedProducts(newChecked);
  };

  return (
    <Wrapper>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent>
          <TextField
            value={newGroupName}
            onChange={(e) => {
              setNewGroupName(e.target.value);
            }}
            id="till-group"
            label="Enter Till Group Name"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              addNewGroupFn(newGroupName);
            }}>
            Add Group
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ height: "100vh", overflow: "scroll" }}
        fullWidth
        open={newScreenOpen}
        onClose={() => setNewScreenOpen(false)}>
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          {newItemValue.itemType === "category" ? (
            <TextField
              style={{ marginBottom: "20px" }}
              value={newItemValue.itemName}
              onChange={(e) => {
                setNewItemValue((oldS) => {
                  let newState = { ...oldS };
                  newState.itemName = e.target.value;
                  return newState;
                });
              }}
              id="till-group"
              label="Enter Item Name"
              fullWidth
              variant="standard"
            />
          ) : (
            <>
              {products ? (
                <SearchComp
                  onClick={() => setNewScreenOpen(true)}
                  filteredItems={filteredProducts}
                  items={products}
                  setSelectedTags={setSelectedTags}
                  tags={tags}
                  handleToggle={handleToggle}
                  selectedItems={selectedProducts}
                  findItemKey="productId"
                  displayNameKey="productName"
                />
              ) : (
                <p>Can not add products at the moment</p>
              )}
            </>
          )}

          <FormControl>
            <FormLabel>Item Type</FormLabel>
            <RadioGroup
              name="item-type-select"
              value={newItemValue.itemType}
              onChange={(e) => {
                setNewItemValue((oldS) => {
                  let newState = { ...oldS };
                  newState.itemType = e.target.value;
                  return newState;
                });
              }}>
              <FormControlLabel
                value="product"
                control={<Radio />}
                label="Product"
              />
              <FormControlLabel
                value="category"
                control={<Radio />}
                label="Category"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewScreenOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              console.log("selectedProduct:", selectedProducts);
              addItemToEposGroupFn();
            }}>
            Add Group
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        style={{ width: "50%", margin: "0 auto" }}
        fullWidth
        maxWidth
        open={screenInUseDialog.show}
        onClose={() => {
          setScreenInUseDialog({ show: false, data: null, type: "" });
        }}>
        <DialogTitle>Screen In Use</DialogTitle>
        <div
          style={{
            // border: "1px solid black",
            borderTop: "1px solid gray",
            height: "60vh",
            width: "100%",
            padding: "10% 10% 0 10%",
            borderBottom: "1px dashed gray",
            backgroundColor: "#f7f7f7",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "center",
            overflow: "scroll",
            position: "relative",
          }}>
          <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
            <u>Other screens using this screen</u>
          </h4>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Screen ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {screenInUseDialog?.data &&
                  screenInUseDialog.data.length > 0 &&
                  screenInUseDialog.data.map((data, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell component="th" scope="row">
                          {data}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <span
            style={{
              position: "absolute",
              left: "5%",
              bottom: "5%",
              fontSize: "13px",
              padding: "0 10%",
              textAlign: "center",
            }}>
            To delete this screen, remove all instances of this screen from the
            screens listed above
          </span>
        </div>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setScreenInUseDialog({ show: false, data: null, type: "" });
            }}
            style={{ width: "30%" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={addShortcutBtnDialog.open}
        onClose={() =>
          setAddShortcutBtnDialog((oldS) => ({ ...oldS, open: false }))
        }>
        <DialogTitle>Add Shortcut Button</DialogTitle>
        <DialogContent sx={{ display: "grid", gridTemplateRows: "1fr 1fr" }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">
              Select Icon
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={addShortcutBtnDialog.iconName}
              label="Select Icon"
              onChange={(e) => {
                setAddShortcutBtnDialog((oldS) => ({
                  ...oldS,
                  iconName: e.target.value,
                }));
              }}>
              <MenuItem value={"coffee-outline"}>
                {getIconForShortcutBtn("coffee-outline", "#000")}
              </MenuItem>
              <MenuItem value={"tea-outline"}>
                {getIconForShortcutBtn("tea-outline", "#000")}
              </MenuItem>
              <MenuItem value={"glass-cocktail"}>
                {getIconForShortcutBtn("glass-cocktail", "#000")}
              </MenuItem>
              <MenuItem value={"food-croissant"}>
                {getIconForShortcutBtn("food-croissant", "#000")}
              </MenuItem>
              <MenuItem value={"cupcake"}>
                {getIconForShortcutBtn("cupcake", "#000")}
              </MenuItem>
              <MenuItem value={"food-takeout-box-outline"}>
                {getIconForShortcutBtn("food-takeout-box-outline", "#000")}
              </MenuItem>
            </Select>
            <FormHelperText>
              This is the icon that will be displayed on the shortcut button
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">
              Select Product
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={addShortcutBtnDialog.productId}
              label="Select Product"
              onChange={(e) => {
                setAddShortcutBtnDialog((oldS) => ({
                  ...oldS,
                  productId: e.target.value,
                }));
              }}>
              {selectedGroup?.productIds &&
                Object.keys(selectedGroup?.productIds).map((prodId, i) => {
                  let [correspondingProduct] = products.filter(
                    (prod) => prod?.productId === prodId
                  );

                  console.log("correspondingProduct:", correspondingProduct);

                  if (correspondingProduct) {
                    if (!selectedGroup?.shortcutBtns) {
                      return (
                        <MenuItem value={prodId}>
                          <h5>{correspondingProduct?.productName}</h5>
                        </MenuItem>
                      );
                    } else if (
                      selectedGroup?.shortcutBtns &&
                      !selectedGroup?.shortcutBtns.some((btn) => {
                        console.log("btn:", btn);
                        return btn?.productId === prodId;
                      })
                    ) {
                      return (
                        <MenuItem value={prodId}>
                          <h5>{correspondingProduct?.productName}</h5>
                        </MenuItem>
                      );
                    }
                  }
                })}
            </Select>
            <FormHelperText>
              This is the product you will be directed to when you press the
              shortcut button
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddShortcutBtnDialog((oldS) => ({ ...oldS, open: false }));
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setAddShortcutBtnDialog((oldS) => ({ ...oldS, open: false }));
              addShortcutBtnFn();
            }}>
            Add Button
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            <h4 style={{ marginLeft: "15%", color: "#444" }}>
              Select Epos Group:
            </h4>

            <Select
              style={{ marginLeft: "5%" }}
              className="select"
              labelId="epos-group-select"
              id="epos-group-select"
              value={selectedGroup?.eposGroupName || ""}
              label="Epos Group"
              onChange={onSelectGroup}>
              {eposGroups &&
                eposGroups?.map((eposGroup, i) => {
                  return (
                    <MenuItem key={i} value={eposGroup?.eposGroupName}>
                      {eposGroup?.eposGroupName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Toolbar>
        </div>
        <div className="content-container">
          {!selectedGroup ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <h2>Select An Epos Group</h2>
              <Button
                onClick={() => setOpen(true)}
                style={{ width: "70%", marginTop: "15%" }}
                variant="contained">
                Add Group
              </Button>
            </div>
          ) : (
            <>
              <SingleEposGroup
                setOldCurrScreen={setOldCurrScreen}
                setCurrScreen={setCurrScreen}
                setCurrDisplayItems={setCurrDisplayItems}
                currScreen={currScreen}
                selectedGroup={selectedGroup}
                updateDisplayOrderFn={updateDisplayOrderFn}
                removeItemFromEposGroupFn={removeItemFromEposGroupFn}
                setNewScreenOpen={setNewScreenOpen}
                currDisplayItems={currDisplayItems}
                addNewGroupFromCopyFn={addNewGroupFromCopyFn}
                removeAnEposGroupScreenHelper={removeAnEposGroupScreenHelper}
                setAddShortcutBtnDialog={setAddShortcutBtnDialog}
                products={products}
                deleteShortcutBtnFn={deleteShortcutBtnFn}
              />
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    background-color: #f7f7f7;
    display: flex;
    width: 100%;
    height: 90vh;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* border: 1px solid red; */
  }
  .epos-group-container {
    width: 100%;
    height: 90vh;
    display: grid;
    grid-template-columns: 1fr 3fr;
    /* display: flex; */
    /* flex-direction: row; */
    /* border: 1px solid black; */
  }

  .tree-container {
    border-right: 0.5px dashed black;
    width: 100%;
    height: 100%;
    overflow: scroll;
    align-self: flex-start;
    padding: 5% 0 0 2%;
  }
  .level-items-container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 9fr;
  }
  .level-items-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 5%;
  }
  .level-items-bottom {
    padding: 8% 11% 5% 9%;
    row-gap: 1%;
  }
  .level-items-bottom-bottom {
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: 2%;
  }
  .level-items {
    width: 30%;
    height: 18vh;
    /* border-radius: 10px; */
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .level-items:hover {
    cursor: pointer;
  }
`;

export default EposGroups;
