import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Select,
  Divider,
  MenuItem,
  TextField,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  DialogActions,
  TableBody,
  InputAdornment,
  InputLabel,
  Toolbar as MuiToolbar,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemText,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import { useAdmin } from "../../context/admin_context";
import { createUser } from "../../helpers/helpers";
import { useNavigate } from "react-router";

const initialState = {
  acceptedInvitation: false,
  name: "",
  email: "",
  emailVerified: false,
  phoneNumber: "",
};

const AddUser = () => {
  const [formValue, setFormValue] = useState(initialState);
  const [roles, setRoles] = useState([]);
  const [manageStores, setManageStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();

  const { locations } = useAdmin();

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  const validateForm = () => {
    if (!formValue?.name || formValue?.name.length === 0) {
      enqueueSnackbar(`Invalid name`, {
        variant: "warning",
      });
      return;
    }
    if (!formValue?.email || formValue?.email.length === 0) {
      enqueueSnackbar(`Invalid email`, {
        variant: "warning",
      });
      return;
    }
    if (!manageStores || manageStores.length === 0) {
      enqueueSnackbar(`Invalid permissions`, {
        variant: "warning",
      });
      return;
    }
    console.log("formValue:", formValue);
    createUserHelper();
  };

  const createUserHelper = async () => {
    setIsLoading(true);
    const resp = await createUser(formValue, manageStores, roles);
    if (resp) {
      if (resp.userAlreadyExists) {
        enqueueSnackbar(`User already exists`, {
          variant: "error",
        });
      }
      if (resp.createdUser) {
        enqueueSnackbar(`Successfully created user`, {
          variant: "success",
        });
        navigate(`/staff`);
      }
    } else {
      enqueueSnackbar(`Something went wrong when trying to create new user`, {
        variant: "error",
      });
    }

    setIsLoading(false);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <div className="nav-container">
          <MuiToolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            <h2
              style={{
                marginLeft: "25%",
                color: "#444",
                fontFamily: "'Heebo', sans-serif",
              }}>
              Add User
            </h2>
          </MuiToolbar>
        </div>
        <div className="content-container">
          <div className="form-container">
            <div className="header-container-add">
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#777",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  navigate(`/staff`);
                }}>
                Go Back
              </Button>
              <h2>New User:</h2>
              <Button
                type="reset"
                variant="contained"
                onClick={() => {
                  setFormValue(initialState);
                }}>
                Reset
              </Button>
              <Button variant="contained" type="submit" onClick={validateForm}>
                Submit
              </Button>
            </div>
            <Divider />

            <div className="data-container">
              <div className="item-container">
                <h3>User Information:</h3>

                <div className="data-row">
                  <div className="data-col">
                    <h3 className="row-title">User Name:</h3>
                  </div>
                  <div className="data-col">
                    <TextField
                      required
                      value={formValue.name}
                      onChange={(e) => {
                        setFormValue((oldS) => {
                          let newState = { ...oldS };
                          newState.name = e.target.value;
                          return newState;
                        });
                      }}
                      style={{ width: "90%" }}
                      label="Name"
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="data-row">
                  <div className="data-col">
                    <h3 className="row-title"> Email:</h3>
                  </div>
                  <div className="data-col">
                    <TextField
                      value={formValue.email}
                      onChange={(e) => {
                        setFormValue((oldS) => {
                          let newState = { ...oldS };
                          newState.email = e.target.value;
                          return newState;
                        });
                      }}
                      style={{ width: "90%" }}
                      required
                      label="Email"
                      variant="outlined"
                      type="email"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="data-row">
                  <div className="data-col">
                    <h3 className="row-title">Phone number:</h3>
                  </div>
                  <div className="data-col">
                    <TextField
                      value={formValue.phoneNumber}
                      onChange={(e) => {
                        setFormValue((oldS) => {
                          let newState = { ...oldS };
                          newState.phoneNumber = e.target.value;
                          return newState;
                        });
                      }}
                      style={{ width: "90%" }}
                      label="Phone Number"
                      variant="outlined"
                      type="tel"
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="item-container">
                <h3>User Permissions:</h3>

                <div className="data-row">
                  <div className="data-col">
                    <h3 className="row-title"> Permissions:</h3>
                  </div>
                  <div className="data-col">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={roles}
                      onChange={(e) => {
                        const role = e.target.value;
                        if (role === "admin" || role === "manager") {
                          setManageStores(["all_stores"]);
                        } else {
                          if (manageStores[0] === "all_stores") {
                            setManageStores([]);
                          }
                        }

                        setRoles([role]);
                      }}>
                      <FormControlLabel
                        value="staff"
                        control={<Radio />}
                        label="Staff"
                      />
                      <FormControlLabel
                        value="manager"
                        control={<Radio />}
                        label="Manager"
                      />
                      <FormControlLabel
                        value="admin"
                        control={<Radio />}
                        label="Admin"
                      />
                    </RadioGroup>
                  </div>
                </div>
                <div className="data-row">
                  <div className="data-col">
                    <h3 className="row-title"> Manage stores:</h3>
                  </div>
                  <div className="data-col">
                    <Select
                      value={manageStores || []}
                      multiple
                      renderValue={(selected) => {
                        return selected.join(", ");
                      }}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setManageStores(
                          typeof value === "string" ? value.split(",") : value
                        );
                      }}
                      label="Manage stores"
                      style={{
                        width: "50%",
                        marginRight: "5%",
                        height: "100%",
                      }}
                      // onChange={handleChange}
                    >
                      {manageStores.indexOf("all_stores") === -1 &&
                        locations &&
                        locations.map((location, i) => {
                          return (
                            <MenuItem key={i} value={location.locationId}>
                              <Checkbox
                                checked={
                                  manageStores.indexOf(location.locationId) > -1
                                }
                              />
                              <ListItemText primary={location.locationName} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60px;
    width: 90%;
    margin: 0 5%;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    border-bottom: 0.5px solid #ccc;
    box-shadow: 0px 5px 15px 5px rgba(55, 55, 55, 0.05);
  }

  .container {
    width: 100%;
  }

  .content-container {
    display: flex;
    width: 100%;

    /* height: 90vh; */
    /* height: 80%; */
    overflow: scroll;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid red; */
  }

  .products-container {
    width: 100%;

    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    /* justify-content: space-around; */
    flex-direction: column;
    flex-wrap: row;
    padding: 0 2% 2% 2%;
  }

  .products-container-top {
    height: 10%;
    width: 100%;
    padding: 0 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 6px 10px -1px rgba(0, 0, 0, 0.05);
  }
  .products-container-bottom {
    height: 90%;
    /* border: 1px solid black; */
    width: 100%;
    overflow: scroll;
    padding: 1%;
  }

  .add-btn-container {
    display: flex;
    /* border: 1px solid black; */
    height: 10%;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 10%;
  }

  .form-container {
    background-color: white;
    width: 90%;
    height: 95%;
    margin: 2.5% 5%;
    overflow-y: scroll;
  }

  .header-container-add {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20%;
  }

  .data-container {
    width: 100%;
    height: 90%;
    text-align: center;
    /* border: 1px solid black; */
    padding: 0 2%;
    /* display: flex; */
    /* background-color: #f9f9f9; */
    /* justify-content: space-around;
    align-items: center;
    justify-content: center; */
  }
  .item-container {
    /* border: 1px solid black; */
    margin: 2.5% 0;
  }
  .data-row {
    width: 100%;
    display: flex;
    margin: 2% 0;
  }
  .data-col {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row-title {
    width: 100%;
    text-align: left;
    padding-left: 20%;
  }
  .permissions-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .permission-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default AddUser;
