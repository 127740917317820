import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import update from "immutability-helper";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  DialogActions,
  Paper,
  IconButton,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  fetchEposGroups,
  fetchProducts,
  getIconForShortcutBtn,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled as muiStyled } from "@mui/material/styles";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
// web.cjs is required for IE11 support
import { animated, useSpring } from "@react-spring/web";
import DnDTill, { DnDItem } from "../../components/DnDItem";
import IpadTemplate from "../../resources/img/ipad-template.png";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";

const SingleEposGroup = ({
  selectedGroup,
  setCurrScreen,
  currScreen,
  setCurrDisplayItems,
  updateDisplayOrderFn,
  removeItemFromEposGroupFn,
  setNewScreenOpen,
  currDisplayItems,
  addNewGroupFromCopyFn,
  removeAnEposGroupScreenHelper,
  setAddShortcutBtnDialog,
  products,
  deleteShortcutBtnFn,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const colors = ["#406590", "#8D5164"];

  let navigate = useNavigate();

  const goToProduct = (productId) => {
    navigate(`/products/${productId}`);
    // setCategoryId(productId);
    // navigate(`/products/${productId}`);
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCurrDisplayItems((oldS) => {
      let newState = { ...oldS };
      let selectedItem = newState?.displayOrder[dragIndex];
      newState?.displayOrder.splice(dragIndex, 1);
      newState?.displayOrder.splice(hoverIndex, 0, selectedItem);
      return newState;
    });
  }, []);

  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }

  function CloseSquare(props) {
    return (
      <SvgIcon
        className="close"
        fontSize="inherit"
        style={{ width: 14, height: 14 }}
        {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }
  const setLevelFn = (screen, itemId, itemLabel) => {
    // we need index of the level the item is on as we know the level
    console.log(
      "setting currDisplayItem in SingleEposGroup to:",
      selectedGroup.items[itemId].displayOrder
    );
    setCurrScreen({ items: screen, itemId: itemId, itemLabel: itemLabel });
    setCurrDisplayItems({
      displayOrder: selectedGroup.items[itemId].displayOrder,
      productIds: selectedGroup.productIds,
    });
  };

  const RenderTree = ({ itemId }) => {
    console.log("itemId:", itemId);
    console.log("selectedGroup:", selectedGroup);
    console.log("selectedGroup?.items[itemId]:", selectedGroup?.items[itemId]);
    return (
      <>
        {selectedGroup?.items[itemId]?.displayOrder?.length > 0 &&
          selectedGroup.items[itemId].displayOrder.map((displayItem, i) => {
            console.log("displayItem inside:", displayItem);
            return (
              <div key={i}>
                {displayItem.itemType === "category" ? (
                  <TreeItem
                    style={{ borderLeft: "1px dashed gray" }}
                    onClick={() =>
                      setLevelFn(
                        selectedGroup.items[displayItem.itemId].displayOrder,
                        displayItem.itemId,
                        displayItem.displayName
                      )
                    }
                    key={displayItem.itemId}
                    nodeId={displayItem.itemId}
                    label={displayItem.displayName}>
                    <RenderTree itemId={displayItem.itemId} />
                  </TreeItem>
                ) : (
                  <div className="product_id_container">
                    <button
                      className="product_id_btn"
                      onClick={() => goToProduct(displayItem.itemId)}>
                      {displayItem.displayName}
                    </button>
                  </div>
                )}
              </div>
            );
          })}
      </>
    );
  };

  const renderCard = (screenItem, index) => {
    const productId = screenItem?.productId;

    return (
      <DnDItem
        navigate={navigate}
        updateDisplayOrderFn={updateDisplayOrderFn}
        removeItemFromEposGroupFn={removeItemFromEposGroupFn}
        screenItem={screenItem}
        selectedGroup={selectedGroup}
        setCurrScreen={setCurrScreen}
        setCurrDisplayItems={setCurrDisplayItems}
        key={`till-dnd-${index}`}
        index={index}
        id={`till-dnd-${index}`}
        moveCard={moveCard}
      />
    );
  };

  const CopyEposGroupDialog = () => {
    const [newEposGroupName, setNewEposGroupName] = useState("");

    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Enter New Epos Group Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            // margin="dense"
            label="New Epos Group"
            type="name"
            fullWidth
            variant="standard"
            value={newEposGroupName}
            onChange={(e) => {
              setNewEposGroupName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => addNewGroupFromCopyFn(newEposGroupName)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Wrapper>
      <CopyEposGroupDialog />
      <div className="tree-container">
        <Button
          variant="outlined"
          style={{ margin: "2% auto 8% auto" }}
          onClick={() => setOpen(true)}>
          Duplicate Epos Group
        </Button>
        {selectedGroup && (
          <TreeView
            multiSelect
            aria-label="multi-select"
            defaultExpanded={["nodes-levels"]}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
            sx={{
              height: "100%",
              flexGrow: 1,
              maxWidth: 400,
              overflowY: "auto",
            }}>
            <TreeItem
              onClick={() =>
                setLevelFn(
                  selectedGroup.items.home_screen.displayOrder,
                  "home_screen",
                  "Home Screen"
                )
              }
              key="Home Screen"
              nodeId="1"
              label="Home Screen">
              <>
                {selectedGroup.items.home_screen.displayOrder.map(
                  (displayItem, i) => {
                    return (
                      <div key={i}>
                        {displayItem.itemType === "category" ? (
                          <TreeItem
                            style={{ borderLeft: "1px dashed gray" }}
                            onClick={() =>
                              setLevelFn(
                                selectedGroup.items[displayItem.itemId]
                                  .displayOrder,
                                displayItem.itemId,
                                displayItem.displayName
                              )
                            }
                            key={displayItem.itemId}
                            nodeId={displayItem.itemId}
                            label={displayItem.displayName}>
                            {<RenderTree itemId={displayItem.itemId} />}
                          </TreeItem>
                        ) : (
                          <div className="product_id_container">
                            <button
                              className="product_id_btn"
                              onClick={() => goToProduct(displayItem.itemId)}>
                              {displayItem.displayName}
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </>
            </TreeItem>
          </TreeView>
        )}
      </div>
      <>
        {currScreen ? (
          <div className="level-items-container">
            <div className="level-items-top">
              <Button
                onClick={removeAnEposGroupScreenHelper}
                variant="contained"
                style={{
                  backgroundColor: "#f54842",
                  width: "30%",
                }}>
                Delete Screen Helper
              </Button>
              <Button
                onClick={() => {
                  setNewScreenOpen(true);
                }}
                style={{ width: "30%" }}
                variant="contained">
                Add Item to Screen
              </Button>
            </div>
            <div
              className="till-container"
              // style={{
              // backgroundImage: `url(${IpadTemplate})`,
              // backgroundRepeat: "no-repeat, repeat",
              // backgroundPosition: "center",
              //   display: "flex",
              //   flexDirection: "column",
              //   alignItems: "center",
              //   justifyContent: "center",
              //   width: "90%",
              //   border: "1px solid black",
              //   margin: "5% ",
              // }}
            >
              <div className="till-row">
                <h2
                  style={{
                    textTransform: "capitalize",
                    color: "rgb(230, 230, 230)",
                  }}>
                  {currScreen?.itemLabel}
                </h2>
              </div>
              <div className="till-shortcut-btn-row">
                {Array.from({ length: 6 }, () => null).map((_, i) => {
                  let shortcutBtn = null;

                  if (selectedGroup && selectedGroup?.shortcutBtns) {
                    shortcutBtn = selectedGroup?.shortcutBtns[i];
                  }

                  if (shortcutBtn) {
                    let [correspondingProduct] = products.filter(
                      (prod) => prod?.productId === shortcutBtn?.productId
                    );
                    return (
                      <Paper
                        style={{
                          width: "80%",
                          height: "90%",
                          margin: "5% 10%",
                          cursor: "move",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          backgroundColor: colors[i % colors.length],
                        }}
                        elevation={3}
                        onContextMenu={(e) => {
                          deleteShortcutBtnFn(correspondingProduct?.productId);
                          e.preventDefault();
                        }}
                        onClick={() => {
                          navigate(
                            `/products/${correspondingProduct?.productId}`
                          );
                        }}>
                        {/* <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          aria-label="delete"
                          style={{ color: "#f54842" }}>
                          <RemoveCircleIcon style={{ fontSize: "15px" }} />
                        </IconButton> */}
                        {getIconForShortcutBtn(shortcutBtn?.iconName)}
                        <h6
                          style={{
                            textAlign: "center",
                            color: "rgb(230, 230, 230)",
                          }}>
                          {correspondingProduct?.productName}
                        </h6>
                      </Paper>
                    );
                  } else {
                    return (
                      <div
                        key={i}
                        className="empty-till-item-sb"
                        onClick={() => {
                          setAddShortcutBtnDialog((oldS) => ({
                            ...oldS,
                            open: true,
                          }));
                        }}>
                        <h2>+</h2>
                      </div>
                    );
                  }
                })}
              </div>

              <div className="bottom-till-row">
                {currDisplayItems?.displayOrder?.length > 0 ? (
                  Array.from({ length: 9 }, () => null).map((_, i) => {
                    let screenItem = currDisplayItems?.displayOrder[i];
                    if (screenItem) {
                      return renderCard(screenItem, i);
                    } else {
                      return (
                        <div key={i} className="empty-till-item">
                          <h2>+</h2>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div
                    style={{
                      gridRow: "1/-1",
                      gridColumn: "1/-1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <h2>No Items</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>select level to view</p>
        )}
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  height: 90vh;
  display: grid;
  grid-template-columns: 1fr 3fr;

  .product_id_container {
    display: flex;
    flex-direction: row;
    border-left: 1px dashed gray;
  }

  .product_id_btn {
    background-color: transparent;
    border: none;
    font-size: 15px;
    margin: 5px 0;
    border-bottom: 1px dashed black;
    margin-left: 10px;
  }
  .product_id_btn: hover {
    cursor: pointer;
  }

  .till-container {
    /* background-image: url(${IpadTemplate}); */
    /* background-repeat: no-repeat, repeat; */
    /* background-position: center; */
    background-color: rgb(22, 26, 30);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    border-left: 18px solid #a9a9a9;
    border-right: 18px solid #a9a9a9;
    border-top: 8px solid #a9a9a9;
    border-bottom: 8px solid #a9a9a9;
    border-radius: 10px;
    margin: 5%;
  }

  .till-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 0.1;
    background-color: rgb(31, 36, 43);
    border-bottom: 1px solid black;
  }
  .till-shortcut-btn-row {
    flex: 0.15;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    overflow: hidden;
    padding-top: 1%;
  }
  .bottom-till-row {
    flex: 0.75;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    padding-bottom: 1%;
  }

  .tree-container {
    border-right: 0.5px dashed black;
    width: 100%;
    height: 100%;
    overflow: scroll;
    align-self: flex-start;
    padding: 5% 0 0 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .empty-till-item {
    border: 0.5px dashed gray;
    border-radius: 10px;
    width: 90%;
    height: 90%;
    margin: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .empty-till-item-sb {
    border: 0.5px dashed gray;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    margin: 5% 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export default SingleEposGroup;
