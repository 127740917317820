import { useSnackbar } from "notistack";
import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import {
  SET_USER,
  LOGOUT_USER,
  REFRESH_CONTEXT,
  LOGIN_STORE,
  LOGOUT_STORE,
  UPDATE_USER,
  LOCK_TILL,
} from "../actions";
import {
  fetchRegisteredStores,
  getLocations,
  getUserRoles,
} from "../helpers/helpers";
import adminReducer from "../reducers/admin_reducer";
import { useAuth } from "./auth_context";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [adminObj, dispatch] = useReducer(adminReducer, {});
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();

  useEffect(() => {
    // console.log("currentUser: ",currentUser);
    // console.log("userRoles: ", userRoles);
    if (
      currentUser?.claims?.userRoles?.includes("admin") ||
      currentUser?.claims?.userRoles?.includes("manager")
    ) {
      getLocations(setLocations, setIsLoading, enqueueSnackbar);
    }
  }, []);

  return (
    <AdminContext.Provider
      value={{
        adminObj,
        isLoading,
        locations,
        setSelectedStore,
        selectedStore,
        setIsLoading,
      }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => {
  return useContext(AdminContext);
};
