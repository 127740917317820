import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import { Button, Toolbar } from "@mui/material";
import Navigation from "../navigation/Navigation";
import MapView from "../../components/MapView";
import SimpleNavBar from "../../components/LocationBreadCrumb";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  registerNewStore,
  updateStoreData,
  uploadProductsFile,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { StoreData } from "../../classes/classes";
import { FileUploader } from "react-drag-drop-files";
import { colors } from "../../constants/constants";
import FormData from "form-data";

const ProductsHome = () => {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    console.log("file:", file);
    setFile(file);
  };
  const onTypeError = (error) => {
    console.log("error:", error);
  };
  const [isLoading, setIsLoading] = useState(false);
  let { storeId } = useParams();

  let navigate = useNavigate();
  let location = useLocation();

  let fileTypes = [
    "csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "xlsx",
  ];

  const handleSubmission = async () => {
    // const formData = new FormData();

    // formData.append("File", file);
    // const formValue = formData.get("File");
    // console.log("formValue:", formValue);
    // uploadProductsFile(formData);

    // function readFileDataAsBase64() {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //       resolve(event.target.result);
    //     };

    //     reader.onerror = (err) => {
    //       reject(err);
    //     };

    //     reader.readAsBinaryString(file);
    //   });
    // }

    const formData = new FormData();
    const authToken = window.localStorage.getItem("auth_token");
    console.log("authToken:", typeof authToken);
    formData.set("admin_token", authToken);
    formData.append("file", file, file.name);

    console.log("file:", formData.get("admin_token"));

    uploadProductsFile(formData);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Select Store
        </InputLabel> */}
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <div className="content-container">
          <h2>Current Master file: {file?.name || "None Uploaded"}</h2>
          {file && (
            <Button
              variant="contained"
              component="span"
              onClick={() => setFile(null)}>
              Remove File
            </Button>
          )}

          <FileUploader
            handleChange={handleChange}
            onTypeError={onTypeError}
            name="file"
            //   label="afdsd"
            hoverTitle="drop here"
            classes="file-uploader"
            fileOrFiles={file}
            types={fileTypes}
          />
          {file && (
            <Button
              variant="contained"
              component="span"
              onClick={handleSubmission}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 90vh;
    align-items: center;
    justify-content: space-around;
  }

  .file-uploader {
    height: 50vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #364f78;
  }
`;

export default ProductsHome;
