import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItemButton,
} from "@mui/material";
import styled from "styled-components";
import React, { useState } from "react";
import { useAuth } from "../../context/auth_context";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { colors } from "../../constants/constants";
import Logo from "../../resources/img/logo.png";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutIcon from "@mui/icons-material/Logout";
import TvIcon from "@mui/icons-material/Tv";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import MessageIcon from "@mui/icons-material/Message";
import PolicyIcon from "@mui/icons-material/Policy";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GrainIcon from "@mui/icons-material/Grain";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { getUserRoles, sendNotificationToTill } from "../../helpers/helpers";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useSnackbar } from "notistack";
import CategoryIcon from "@mui/icons-material/Category";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import GroupIcon from "@mui/icons-material/Group";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import DevicesIcon from "@mui/icons-material/Devices";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import BadgeIcon from "@mui/icons-material/Badge";

const Navigation = () => {
  const [open, setOpen] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { currentUser, logoutUser } = useAuth();
  console.log("currUser", currentUser);

  const { enqueueSnackbar } = useSnackbar();

  const logout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      // txt = "You pressed OK!";
      logoutUser();
    } else {
      // txt = "You pressed Cancel!";
    }
  };

  let navigate = useNavigate();

  return (
    <Wrapper style={{ width: drawerOpen ? "20vw" : "60px" }}>
      <div className="drawer-container">
        <Drawer
          sx={{
            width: drawerOpen ? "20vw" : "60px",
            transition: "width 0.5s",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerOpen ? "20vw" : "60px",
              boxSizing: "border-box",
              transition: "width 0.5s",
            },
          }}
          variant="permanent"
          open={drawerOpen}
          anchor="left">
          {drawerOpen ? (
            <>
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "10vh",
                  transition: "width 0.5s",
                }}>
                {/* <img className="logo" src={Logo} alt="logo" /> */}

                <h4
                  style={{
                    textAlign: "center",
                    color: colors.color5,
                  }}>
                  Nudo Designs Portal
                </h4>
              </Toolbar>
              <Divider />
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}>
                <h4
                  style={{
                    textAlign: "center",
                    color: colors.color5,
                  }}>
                  Welcome Back {currentUser?.name}
                </h4>
                <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                  <ArrowCircleLeftIcon />
                </IconButton>
              </Toolbar>
            </>
          ) : (
            <>
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60px",
                }}>
                <img className="logo" src={Logo} alt="logo" />
              </Toolbar>
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                  <ArrowCircleRightIcon />
                </IconButton>
              </Toolbar>
            </>
          )}
          <Divider />
          <List
            style={{
              backgroundColor: "rgb(237, 240, 243)",
            }}>
            <ListItem button key="Sales" onClick={() => navigate("/sales")}>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText
                primary="Sales"
                sx={{ opacity: drawerOpen ? 1 : 0 }}
              />
            </ListItem>
          </List>
          <Divider />

          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() =>
                    setOpen((oldS) => ({ locations: !oldS.locations }))
                  }>
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Locations"
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                  {open.locations ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>
              {open.locations && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["Registered Locations", "Store Groups"].map(
                    (text, index) => (
                      <ListItem
                        button
                        key={text}
                        onClick={() => {
                          if (text === "Registered Locations") {
                            navigate("/locations");
                          } else if (text === "Store Groups") {
                            navigate("/storegroups");
                          }
                        }}
                        style={{
                          backgroundColor: "rgb(247, 250, 253)",
                        }}>
                        <ListItemIcon>
                          {index === 0 && <WhereToVoteIcon />}
                          {index === 1 && <GroupWorkIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}
              <Divider />
            </>
          )}
          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() =>
                    setOpen((oldS) => ({ devices: !oldS.devices }))
                  }>
                  <ListItemIcon>
                    <DevicesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Devices"
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                  {open.devices ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>
              {open.devices && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["Registered Devices", "Unregistered Devices"].map(
                    (text, index) => (
                      <ListItem
                        button
                        key={text}
                        onClick={() => {
                          if (text === "Registered Devices") {
                            navigate("/registereddevices");
                          } else if (text === "Unregistered Devices") {
                            navigate("/unregistereddevices");
                          }
                        }}
                        style={{
                          backgroundColor: "rgb(247, 250, 253)",
                        }}>
                        <ListItemIcon>
                          {index === 0 && <MobileFriendlyIcon />}
                          {index === 1 && <DeviceUnknownIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}
              <Divider />
            </>
          )}
          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() =>
                    setOpen((oldS) => ({ warehouses: !oldS.warehouses }))
                  }>
                  <ListItemIcon>
                    <WarehouseIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Warehouses"
                    sx={{ warehouses: drawerOpen ? 1 : 0 }}
                  />
                  {open.stores ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>

              {open.warehouses && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["Warehouses", "Order Requests", "Inventory"].map(
                    (text, index) => (
                      <ListItem
                        button
                        key={text}
                        onClick={() => {
                          if (text === "Warehouses") {
                            navigate("/warehouses");
                          }
                        }}
                        style={{
                          backgroundColor: "rgb(247, 250, 253)",
                        }}>
                        <ListItemIcon>
                          {index === 0 && <WarehouseIcon />}
                          {index === 1 && <ListAltIcon />}
                          {index === 2 && <InventoryIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}

              <Divider />
            </>
          )}
          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() => setOpen((oldS) => ({ sales: !oldS.sales }))}>
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Products"
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                  {open.sales ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>

              {open.sales && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["Epos Groups", "Products", "Options", "PLU's", "Menus"].map(
                    (text, index) => (
                      <ListItem
                        button
                        key={text}
                        onClick={() => {
                          if (text === "Products") {
                            navigate("/products");
                          } else if (text === "Epos Groups") {
                            navigate("/eposgroups");
                          } else if (text === "Options") {
                            navigate("/options");
                          } else if (text === "PLU's") {
                            navigate("/allplu");
                          } else if (text === "Menus") {
                            navigate("/menus");
                          }
                        }}
                        style={{
                          backgroundColor: "rgb(247, 250, 253)",
                        }}>
                        <ListItemIcon>
                          {index === 0 && <ViewCompactIcon />}
                          {index === 1 && <WorkspacesIcon />}
                          {index === 2 && <GrainIcon />}
                          {index === 3 && <CategoryIcon />}
                          {index === 4 && <MenuBookIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}

              <Divider />
            </>
          )}
          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() => setOpen((oldS) => ({ shifts: !oldS.shifts }))}>
                  <ListItemIcon>
                    <CalendarMonthIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Shift Planner"
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                  {open.shifts ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>

              {open.shifts && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["Schedule", "Requests", "Attendance"].map((text, index) => (
                    <ListItem
                      button
                      key={text}
                      onClick={() => {
                        if (text === "Schedule") {
                          navigate("/schedule");
                        } else if (text === "Requests") {
                          navigate("/employee_requests");
                        } else if (text === "Attendance") {
                          navigate("/employee_attendance");
                        }
                      }}
                      style={{
                        backgroundColor: "rgb(247, 250, 253)",
                      }}>
                      <ListItemIcon>
                        {index === 0 && <EventNoteIcon />}
                        {index === 1 && <PersonOutlineIcon />}
                        {index === 2 && <AddTaskIcon />}
                        {index === 3 && <AccessTimeIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{ opacity: drawerOpen ? 1 : 0 }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}

              <Divider />
            </>
          )}
          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() =>
                    setOpen((oldS) => ({ customers: !oldS.customers }))
                  }>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Customers"
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                  {open.customers ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>

              {open.customers && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["My Customers", "EPOS Users", "Manage Permissions"].map(
                    (text, index) => (
                      <ListItem
                        button
                        key={text}
                        onClick={() => {
                          if (text === "My Customers") {
                            navigate("/customers");
                          }
                        }}
                        style={{
                          backgroundColor: "rgb(247, 250, 253)",
                        }}>
                        <ListItemIcon>
                          {index === 0 && <GroupIcon />}
                          {index === 1 && <AssignmentIndIcon />}
                          {index === 2 && <AdminPanelSettingsIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}

              <Divider />
            </>
          )}
          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() => setOpen((oldS) => ({ staff: !oldS.staff }))}>
                  <ListItemIcon>
                    <BadgeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Staff"
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                  {open.staff ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>

              {open.staff && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["Staff", "EPOS Users", "Manage Permissions"].map(
                    (text, index) => (
                      <ListItem
                        button
                        key={text}
                        onClick={() => {
                          if (text === "Staff") {
                            navigate("/staff");
                          } else if (text === "EPOS Users") {
                            navigate("/eposusers");
                          }
                        }}
                        style={{
                          backgroundColor: "rgb(247, 250, 253)",
                        }}>
                        <ListItemIcon>
                          {index === 0 && <GroupIcon />}
                          {index === 1 && <AssignmentIndIcon />}
                          {index === 2 && <AdminPanelSettingsIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              )}

              <Divider />
            </>
          )}
          {(currentUser?.claims?.userRoles?.includes("admin") ||
            currentUser?.claims?.userRoles?.includes("manager")) && (
            <>
              <List
                style={{
                  backgroundColor: "rgb(237, 240, 243)",
                }}>
                <ListItemButton
                  onClick={() =>
                    setOpen((oldS) => ({ wastage: !oldS.wastage }))
                  }>
                  <ListItemIcon>
                    <DeleteSweepIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Wastage"
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                  {open.wastage ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </List>

              {open.wastage && (
                <List
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  {["Registered Stores", "Devices"].map((text, index) => (
                    <ListItem
                      style={{
                        backgroundColor: "rgb(247, 250, 253)",
                      }}
                      button
                      key={text}
                      style={{
                        backgroundColor: "rgb(247, 250, 253)",
                      }}>
                      <ListItemIcon>
                        {index === 0 ? (
                          <PlaylistAddCheckCircleIcon />
                        ) : (
                          <TvIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{ opacity: drawerOpen ? 1 : 0 }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}

              <Divider />
            </>
          )}

          <Divider />
          <List
            style={{
              backgroundColor: "rgb(237, 240, 243)",
            }}>
            <ListItem button key="Messages" onClick={() => navigate("/sales")}>
              <ListItemIcon>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText
                primary="Messages"
                sx={{ opacity: drawerOpen ? 1 : 0 }}
              />
            </ListItem>
          </List>
          <Divider />
          <List
            style={{
              backgroundColor: "rgb(237, 240, 243)",
            }}>
            <ListItem
              button
              key="Settings"
              onClick={() => navigate("/settings")}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                sx={{ opacity: drawerOpen ? 1 : 0 }}
              />
            </ListItem>
          </List>
          <Divider />
          <List
            style={{
              backgroundColor: "rgb(237, 240, 243)",
            }}>
            <ListItem button key="logout" onClick={logout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="logout" />
            </ListItem>
          </List>
          <Divider />
        </Drawer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  transition: width 0.5s;

  .drawer-container {
    border-width: 3px solid black;
    overflow: scroll;
    height: 100vh;
    transition: width 0.5s;
  }

  .logo {
    width: 40px;
    height: 40px;
  }
`;

export default Navigation;
