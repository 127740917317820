const adminReducer = (state, action) => {
  // console.log(
  //   'authReducer state=',
  //   state,
  //   'action=',
  //   action,
  //   'action.payload=',
  //   action.payload,
  // );

  switch (action.type) {
    default:
      throw new Error(`No matching action type ${action.type}`);
  }
};

export default adminReducer;
