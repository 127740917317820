import React, { useEffect, useState } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function ConfirmAccount() {
  const [isLoading, setIsLoading] = useState(false);
  let query = useQuery();
  let navigate = useNavigate();

  const mode = query.get("mode");
  const oobCode = query.get("oobCode");
  const continueUrl = query.get("continueUrl");
  const resetpasslink = query.get("resetpasslink");

  console.log("mode:", mode);
  console.log("oobCode:", oobCode);
  console.log("continueUrl:", continueUrl);
  console.log("resetpasslink:", resetpasslink);

  const handleFirebaseVerifyEmail = async () => {
    // initialize the firebase app
    let firebaseConfig = {
      apiKey: "AIzaSyAyEyNuDCLdSHIfX8I_6-SPsD2Pcguwgu4",
    };
    const appName = "app" + String(new Date().getTime());
    const app = firebase.initializeApp(firebaseConfig, appName);
    const auth = app.auth();

    //end of intiialize firebase app

    console.log("inside handleFirebaseVerifyEmail function");

    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth
      .applyActionCode(oobCode)
      .then((resp) => {
        // Email address has been verified.
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        console.log("verify email succeeded");
        console.log(resp);
        setIsLoading(false);

        app.delete();
        let decodedResetPassLink = Base64.decode(resetpasslink);
        window.location.replace(decodedResetPassLink);
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        app.delete();
        alert("Something went wrong when trying to verify your email");
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (mode === "verifyEmail") {
      console.log("calling function handleFirebaseVerifyEmail with:");
      handleFirebaseVerifyEmail();
    } else if (mode === "resetPassword") {
      console.log("verify password with:", oobCode, continueUrl);
      setIsLoading(false);
      navigate("/resetpassword", {
        pathname: `/resetpassword`,
        state: {
          oobCode: oobCode,
          continueUrl: continueUrl,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <h1>Confirm your account from inv</h1>
      {isLoading && <h1>Loading</h1>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ConfirmAccount;
