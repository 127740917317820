import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItemButton,
} from "@mui/material";
import styled from "styled-components";
import React, { useState } from "react";
import { useAuth } from "../../context/auth_context";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Logo from "../../resources/img/logo.png";
import { colors } from "../../constants/constants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutIcon from "@mui/icons-material/Logout";
import TvIcon from "@mui/icons-material/Tv";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentsIcon from "@mui/icons-material/Payments";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useSnackbar } from "notistack";
import DiscountIcon from "@mui/icons-material/Discount";
import CropFreeIcon from "@mui/icons-material/CropFree";

const SupportNavigation = () => {
  const [open, setOpen] = useState({ tills: true });
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { currentUser, logoutUser } = useAuth();
  console.log("currUser", currentUser);

  const { enqueueSnackbar } = useSnackbar();

  const logout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      // txt = "You pressed OK!";
      logoutUser();
    } else {
      // txt = "You pressed Cancel!";
    }
  };

  let navigate = useNavigate();

  return (
    <Wrapper style={{ width: drawerOpen ? "20vw" : "60px" }}>
      <div className="drawer-container">
        <Drawer
          sx={{
            width: drawerOpen ? "20vw" : "60px",
            transition: "width 0.5s",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerOpen ? "20vw" : "60px",
              boxSizing: "border-box",
              transition: "width 0.5s",
            },
          }}
          variant="permanent"
          open={drawerOpen}
          anchor="left">
          {drawerOpen ? (
            <>
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "10vh",
                  transition: "width 0.5s",
                }}>
                {/* <img className="logo" src={Logo} alt="logo" /> */}

                <h4
                  style={{
                    textAlign: "center",
                    color: colors.color5,
                  }}>
                  Nudo Designs Support Portal
                </h4>
              </Toolbar>
              <Divider />
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}>
                <h4
                  style={{
                    textAlign: "center",
                    color: colors.color5,
                  }}>
                  Welcome Back {currentUser?.name}
                </h4>
                <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                  <ArrowCircleLeftIcon />
                </IconButton>
              </Toolbar>
            </>
          ) : (
            <>
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60px",
                }}>
                <img className="logo" src={Logo} alt="logo" />
              </Toolbar>
              <Toolbar
                style={{
                  backgroundColor: colors.color1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                  <ArrowCircleRightIcon />
                </IconButton>
              </Toolbar>
            </>
          )}
          <List
            style={{
              backgroundColor: "rgb(237, 240, 243)",
            }}>
            <ListItemButton
              onClick={() => setOpen((oldS) => ({ tills: !oldS.tills }))}>
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText
                primary="Tills"
                sx={{ opacity: drawerOpen ? 1 : 0 }}
              />
              {open.tills ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </List>
          {open.tills && (
            <List
              style={{
                backgroundColor: "rgb(247, 250, 253)",
              }}>
              {[
                "Payments",
                "Hardware",
                "Orders",
                "Discounts",
                "Coupons",
                "Feature List",
              ].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => {
                    if (text === "Payments") {
                      navigate("/payments");
                    } else if (text === "Discounts") {
                      navigate("/discounts");
                    } else if (text === "Coupons") {
                      navigate("/coupons");
                    }
                  }}
                  style={{
                    backgroundColor: "rgb(247, 250, 253)",
                  }}>
                  <ListItemIcon>
                    {index === 0 && <PaymentsIcon />}
                    {index === 1 && <TvIcon />}
                    {index === 2 && <LocalMallIcon />}
                    {index === 3 && <DiscountIcon />}
                    {index === 4 && <CropFreeIcon />}
                    {index === 5 && <FeaturedPlayListIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <Divider />
          <List
            style={{
              backgroundColor: "rgb(237, 240, 243)",
            }}>
            <ListItem
              button
              key="Dashboard"
              onClick={() => {
                window.location.assign(
                  `http://${window.location.host.split(".")[1]}/dashboard`
                );
              }}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: drawerOpen ? 1 : 0 }}
              />
            </ListItem>
          </List>
          <Divider />
        </Drawer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  transition: width 0.5s;

  .drawer-container {
    border-width: 3px solid black;
    overflow: scroll;
    height: 100vh;
    transition: width 0.5s;
  }

  .logo {
    width: 40px;
    height: 40px;
  }
`;

export default SupportNavigation;
