import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "mapbox-gl/dist/mapbox-gl.css";
import { TextField, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAWarehouse } from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { colors } from "../../constants/constants";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import WarehouseSkuTable from "./WarehouseSkuTable";

const SingleWarehouse = () => {
  const [warehouse, setWarehouse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  let { locationId } = useParams();

  useEffect(() => {
    getAWarehouse(locationId, setWarehouse, setIsLoading, enqueueSnackbar);
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper style={{ backgroundColor: colors.backgroundColor }}>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        {warehouse && (
          <div className="warehouse-container">
            <div className="warehouse-container-top">
              <TextField
                id="outlined-basic"
                label="Warehouse Name"
                variant="outlined"
                value={warehouse?.warehouseName}
                style={{ width: "40%" }}
              />
              <TextField
                id="outlined-basic"
                label="Location ID"
                variant="outlined"
                value={warehouse?.locationId}
                style={{ width: "40%" }}
              />
            </div>
            <WarehouseSkuTable rows={Object.values(warehouse?.skuStockData)} />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // height: 10vh;
  display: flex;
  position: relative;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;

    /* height: 100vh; */
    /* boredr: 1px solid red; */
  }

  .warehouse-container {
    margin: 0 2.5%;
    display: grid;
    grid-template-rows: 1fr 2fr;
  }

  .warehouse-container-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export default SingleWarehouse;
