import React from "react";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Logo from "../resources/img/logo.png";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoibWFyY29zaGFyZHkiLCJhIjoiY2tqazc5aHlrMGJocjJzczI1bXRsOGdmdSJ9.dccrdrzWSes0GriTu0fKWw",
});

const MapView = ({ store, handleClick, markerCoords }) => {
  return (
    <Map
      onClick={handleClick}
      center={[store.longitude, store.latitude]}
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: "95%",
        width: "90%",
        margin: "5 0%",
        borderRadius: 10,
      }}>
      <Marker coordinates={markerCoords} anchor="bottom">
        <img src={Logo} style={{ width: 30 }} />
      </Marker>
    </Map>
  );
};

export default MapView;
