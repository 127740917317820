import React, { useEffect, useState } from "react";
import {
  fetchOptions,
  getProductOptions,
  updateProductOption,
} from "../helpers/helpers";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import ViewProductOptions from "./ViewProductOptions";
import SearchComp from "./SearchComp";

const ViewOptions = ({
  confirmProductOptions,
  productId,
  setProduct,
  removeOptionFromProductFn,
  updateProductOptionsDisplayOrderFn,
  removeOptionGroupFromProductFn,
  product,
}) => {
  const [optionGroups, setOptionGroups] = useState(null);
  const [filteredOptionGroups, setFilteredOptionGroups] = useState(null);
  const [tags, setTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [screen, setScreen] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [productOptions, setProductOptions] = useState(null);

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setScreen(1);
  }, [product]);

  const handleToggle = (opt) => () => {
    console.log("opt:", opt);

    let isInArr = { isInArr: false, index: null };

    selectedOptions.map((optionName, i) => {
      if (optionName.optionId === opt.optionId) {
        isInArr = { isInArr: true, index: i };
      }
    });
    const newChecked = [...selectedOptions];

    if (isInArr.isInArr) {
      newChecked.splice(isInArr.index, 1);
    } else {
      newChecked.push(opt);
    }

    setSelectedOptions(newChecked);
  };

  console.log("product:", product);

  useEffect(() => {
    if (screen === 0) {
      fetchOptions(setOptionGroups, setIsLoading, enqueueSnackbar);
    } else if (screen === 1) {
      // getProductOptions(
      //   productId,
      //   setProductOptions,
      //   setIsLoading,
      //   enqueueSnackbar
      // );
    }
  }, [screen]);

  useEffect(() => {
    if (optionGroups) {
      let tags = [];
      optionGroups.map((opt) => {
        if (opt.tags) {
          opt.tags.map((tag) => {
            if (!tags.includes(tag)) {
              tags.push(tag);
            }
          });
        }
      });
      setTags(tags);
    }
  }, [optionGroups]);

  useEffect(() => {
    if (selectedTags.length > 0) {
      const filteredOptGroups = [];
      optionGroups.filter((opt) => {
        let isAllTagsInOption = true;
        selectedTags.map((tag) => {
          if (!opt.tags.includes(tag)) {
            isAllTagsInOption = false;
          }
        });

        if (isAllTagsInOption) {
          if (!filteredOptGroups.includes(opt)) {
            filteredOptGroups.push(opt);
          }
        }
      });
      setFilteredOptionGroups(filteredOptGroups);
    } else {
      setFilteredOptionGroups(null);
    }
  }, [selectedTags]);

  console.log("selectedOptions:", selectedOptions);

  return (
    <Wrapper>
      {modalVisible && (
        <Dialog onClose={() => setModalVisible(false)} open={modalVisible}>
          <DialogTitle>
            The following options will be added to this product:
          </DialogTitle>
          <List sx={{ pt: 0, height: "40vh", overflow: "scroll" }}>
            {selectedOptions.map((item, i) => (
              <ListItem button onClick={handleToggle(item)} key={item}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedOptions.find(
                      (optionName) => optionName.optionId === item.optionId
                    )}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": i }}
                  />
                </ListItemIcon>
                <ListItemText primary={item.optionName} />
              </ListItem>
            ))}
          </List>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              height: "10vh",
            }}>
            <Button
              style={{ width: "25%", backgroundColor: "#777" }}
              variant="contained"
              onClick={() => setModalVisible(false)}>
              Close
            </Button>
            {selectedOptions.length > 0 && (
              <Button
                style={{ width: "25%" }}
                variant="contained"
                onClick={() => confirmProductOptions(selectedOptions)}>
                Confirm
              </Button>
            )}
          </div>
        </Dialog>
      )}
      {isLoading && <Loader />}

      {optionGroups && tags ? (
        <div
          style={{
            display: "flex",
            // border: "1px solid black",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            paddingTop: "1%",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <ButtonGroup size="medium">
            <Button onClick={() => setScreen(1)}>
              View Options On This Product
            </Button>
            <Button onClick={() => setScreen(0)}>
              Add Options To this product
            </Button>
          </ButtonGroup>

          {screen === 0 && (
            <SearchComp
              onClick={() => setModalVisible(true)}
              filteredItems={filteredOptionGroups}
              items={optionGroups}
              setSelectedTags={setSelectedTags}
              tags={tags}
              handleToggle={(opt) => handleToggle(opt)}
              selectedItems={selectedOptions}
              findItemKey="optionId"
              displayNameKey="optionName"
              showAddBtn={true}
            />
          )}

          {screen === 1 && (
            <div className="add-options-container">
              {product?.productOptions &&
                product?.productOptions?.length > 0 && (
                  <ViewProductOptions
                    removeOptionGroupFromProductFn={
                      removeOptionGroupFromProductFn
                    }
                    removeOptionFromProductFn={removeOptionFromProductFn}
                    productOptions={product?.productOptions}
                    productId={productId}
                    setProduct={setProduct}
                    updateProductOptionsDisplayOrderFn={
                      updateProductOptionsDisplayOrderFn
                    }
                  />
                )}
            </div>
          )}
        </div>
      ) : (
        <h2>No Product Options</h2>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* border: 3px solid red; */

  .options-container: {
    /* border: 1px solid black; */

    /* height: 100%; */
    /* width: 100%;
    border: 5px solid black;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; */
  }

  .option-items-container {
    width: 10%;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .add-options-container {
    /* border: 1px solid red; */
    overflow: scroll;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    flex-direction: column;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border: 1px solid black;
  }
`;

export default ViewOptions;
