import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import update from "immutability-helper";
import { IconButton, Paper } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useDrag, useDrop } from "react-dnd";

const ItemTypes = {
  CARD: "card",
};

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};
export const DnDItem = ({
  navigate,
  id,
  index,
  moveCard,
  screenItem,
  selectedGroup,
  setCurrScreen,
  setCurrDisplayItems,
  removeItemFromEposGroupFn,
  updateDisplayOrderFn,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: () => {
      console.log("DROPPEDDD");
      updateDisplayOrderFn();
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const colors = ["#406590", "#8D5164"];

  return (
    <Paper
      ref={ref}
      style={{
        width: "90%",
        height: "90%",
        margin: "5%",
        cursor: "move",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        backgroundColor: colors[index % colors.length],
        opacity,
      }}
      data-handler-id={handlerId}
      elevation={3}
      onContextMenu={(e) => {
        removeItemFromEposGroupFn(screenItem.itemId);

        e.preventDefault();
      }}
      onClick={() => {
        if (screenItem?.itemType === "category") {
          console.log("setting currScreen in DnDItem to", selectedGroup);
          setCurrScreen({
            items: selectedGroup?.items?.[screenItem?.itemId]?.displayOrder,
            itemId: screenItem?.itemId,
            itemLabel: screenItem?.displayName,
          });

          setCurrDisplayItems({
            displayOrder:
              selectedGroup?.items?.[screenItem?.itemId]?.displayOrder,
            productIds: selectedGroup?.product_ids,
          });
        } else if (screenItem?.itemType === "product") {
          navigate(`/products/${screenItem?.itemId}`);
        }
      }}>
      <h4 style={{ textAlign: "center", color: "rgb(230, 230, 230)" }}>
        {screenItem.displayName}
      </h4>
      {/* <h4 style={{ textAlign: "center", color: "rgb(230, 230, 230)" }}>
        {screenItem.itemType}
      </h4> */}
      {/* {screenItem.itemType === "product" ? (
        <CropSquareIcon style={{ fontSize: 40, color: "rgb(230, 230, 230)" }} />
      ) : (
        <CategoryIcon style={{ fontSize: 40, color: "rgb(230, 230, 230)" }} />
      )} */}
    </Paper>
  );
};
