import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Button,
  ListItemButton,
  ListItemText,
  List,
  TableCell,
  Table,
  Collapse,
  Toolbar,
  Select,
  TableHead,
  TableRow,
  TextField,
  AccordionDetails,
  Box,
  Typography,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  Pagination,
  InputLabel,
  InputAdornment,
  Menu,
  MenuItem,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  addPluToGlobalSku,
  addProduct,
  fetchProducts,
  generateReadableSkuString,
  getGlobalSkuData,
  getTaxGroups,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSnackbar } from "notistack";

const initialState = {
  productName: "",
  attributes: [{ name: "", value: "" }],
  basePrice: 0,
  taxIds: [""],
  tags: [""],
  variations: [],
  productOptions: [],
  productId: null,
};

const ViewStock = () => {
  const [filterBy, setFilterBy] = useState({ id: "plu", title: "PLU" });
  const [globalSkuData, setGlobalSkuData] = useState(null);
  const [filteredGlobalSkuData, setFilteredGlobalSkuData] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  let { storeId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();
  const getGlobalSkuDataHelper = () => {
    getGlobalSkuData(setGlobalSkuData, setIsLoading, enqueueSnackbar);
  };

  useEffect(() => {
    getGlobalSkuDataHelper();
  }, []);

  useEffect(() => {
    if (filterValue?.length > 0) {
      const filteredData = globalSkuData.filter((skuD) =>
        skuD[filterBy.id]?.toLowerCase()?.includes(filterValue.toLowerCase())
      );
      setFilteredGlobalSkuData(filteredData);
    } else {
      setFilteredGlobalSkuData(null);
    }
  }, [filterValue]);

  function Row({ skuData }) {
    const [isRowLoading, setIsRowLoading] = useState(false);
    const [skuDataCopy, setSkuDataCopy] = useState({ ...skuData });
    const [hasChanged, setHasChanged] = useState(false);
    console.log("skuDataCopy: ", skuDataCopy);
    const skuString = generateReadableSkuString(skuDataCopy);

    useEffect(() => {
      if (skuDataCopy?.plu !== skuData?.plu) {
        setHasChanged(true);
      } else {
        setHasChanged(false);
      }
    }, [skuDataCopy]);

    return (
      <TableRow
        key={skuDataCopy.sku}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        {isRowLoading && <Loader />}
        <TableCell style={{ width: "30%" }} component="th" scope="row">
          {skuString}
        </TableCell>
        <TableCell style={{ width: "30%" }} component="th" scope="row">
          {skuData?.sku}
        </TableCell>
        <TableCell style={{ width: "30%" }}>
          <TextField
            id="standard-basic"
            label="PLU"
            fullWidth
            variant="outlined"
            value={skuDataCopy?.plu || ""}
            onChange={(e) => {
              const text = e.target.value;
              setSkuDataCopy((oldS) => {
                let newState = { ...oldS };
                newState.plu = text;
                return newState;
              });
            }}
          />
        </TableCell>
        <TableCell style={{ width: "20%" }}>
          {hasChanged && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}>
              <IconButton
                onClick={() => {
                  if (globalSkuData.some((d) => d?.plu === skuDataCopy?.plu)) {
                    enqueueSnackbar(`PLU's must be unique`, {
                      variant: "error",
                    });
                  } else {
                    addPluToGlobalSku(
                      skuDataCopy.sku,
                      skuDataCopy.plu,
                      setIsRowLoading,
                      setHasChanged,
                      enqueueSnackbar
                    );
                  }
                }}
                style={{ display: "flex", flexDirection: "column" }}
                aria-label="expand row">
                <span style={{ fontSize: 13, color: "#333" }}>Save</span>
                <SaveIcon style={{ fontSize: 22 }} color="primary" />
              </IconButton>
              <IconButton
                style={{ display: "flex", flexDirection: "column" }}
                onClick={() => {
                  setSkuDataCopy({ ...skuData });
                }}
                aria-label="expand row">
                <span style={{ fontSize: 13, color: "#333" }}>Cancel</span>
                <CancelIcon style={{ fontSize: 22 }} color="error" />
              </IconButton>
            </div>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
              All Plus
            </InputLabel> */}
            <IconButton
              onClick={getGlobalSkuDataHelper}
              style={{ position: "absolute", right: "15%" }}
              aria-label="expand row">
              <RefreshIcon style={{ fontSize: 30 }} />
            </IconButton>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <div className="header-container">
          <TextField
            style={{ width: "70%" }}
            label="Filter"
            id="outlined-start-adornment"
            sx={{ m: 1, width: "25ch" }}
            value={filterValue}
            onChange={(e) => {
              const text = e.target.value;
              setFilterValue(text);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {filterBy?.title}:
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            className="filter-icon-container"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}>
            <span style={{ fontSize: "13px", color: "#888" }}>Filter By</span>
            <FilterListIcon fontSize={"30px"} />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "filter-select-button",
            }}>
            <MenuItem
              onClick={() => {
                setFilterBy({ id: "plu", title: "PLU" });
                handleClose();
              }}>
              PLU
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilterBy({ id: "productName", title: "Product Name" });
                handleClose();
              }}>
              Product Name
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilterBy({ id: "barcode", title: "UPC" });
                handleClose();
              }}>
              UPC
            </MenuItem>
          </Menu>
        </div>
        <div className="content-container">
          {globalSkuData && globalSkuData?.length > 0 ? (
            <div className="table-container">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>SKU</TableCell>
                      <TableCell>PLU</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredGlobalSkuData
                      ? filteredGlobalSkuData.map((skuData) => (
                          <Row key={skuData?.sku} skuData={skuData} />
                        ))
                      : globalSkuData.map((skuData) => (
                          <Row key={skuData?.sku} skuData={skuData} />
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <h4 style={{ textAlign: "center" }}>
              No SKU items. Start adding products
            </h4>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    width: 100%;
    height: 80vh;
  }
  .header-container {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 3%;
  }

  .table-container {
    width: 100%;
    height: 90%;
    text-align: center;
    overflow: scroll;
    padding: 0 2% 2% 2%;
  }

  .row-container {
    height: 5vh;
    display: flex;
    flex-direction: row;
    /* border: 1px solid black; */
  }

  .row-col-l {
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding-left: 5%;
    border: 1px solid black;
  }
  .row-col-r {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
  }
  .filter-icon-container {
    display: flex;
    flex-direction: column;
    margin-right: 10%;
  }
`;

export default ViewStock;
