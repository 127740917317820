import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Paper,
  TableContainer,
  IconButton,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import styled from "styled-components";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Navigation from "../navigation/Navigation";
import { getUnegisteredDevices } from "../../helpers/helpers";
import { useSnackbar } from "notistack";
import Loader from "../../components/Loader";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate, useParams } from "react-router";
import axios from "axios";

const REACT_APP_API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

const AddDevice = () => {
  let { locationId } = useParams();
  const [deviceNameModal, setDeviceNameModal] = useState({
    show: false,
    device: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [unregisteredDevices, setUnregisteredDevices] = useState(null);

  let navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const getUnregisteredDevicesHelper = async () => {
    setIsLoading(true);
    const resp = await getUnegisteredDevices(enqueueSnackbar);
    if (resp) {
      setUnregisteredDevices(resp);

      setIsLoading(false);
    }
  };

  const registerDeviceToLocation = async (deviceName) => {
    if (!deviceName) {
      enqueueSnackbar("No device name", {
        variant: "error",
      });
      return;
    }

    if (!deviceNameModal.device) {
      enqueueSnackbar("No device selected", {
        variant: "error",
      });
      return;
    }

    let deviceCopy = { ...deviceNameModal.device };
    deviceCopy.device_name = deviceName;

    try {
      setIsLoading(true);
      const authToken = window.localStorage.getItem("auth_token");

      const resp = await axios.post(
        `${REACT_APP_API_ADDRESS}/register_devices_to_location`,
        //prettier-ignore
        { "admin_token": authToken, "location_id":  locationId, "devices": [deviceCopy]}
      );
      console.log("resp from registerDeviceToLocation:", resp.data);

      setIsLoading(false);
      enqueueSnackbar("Succesfully registered new device", {
        variant: "success",
      });
      navigate(`/locations/:${locationId}`);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
      console.log(error);
      enqueueSnackbar(
        "Something went wrong when trying to register new store",
        {
          variant: "error",
        }
      );
    }
  };

  const handleClose = () => {
    setDeviceNameModal({ show: false, device: null });
  };

  useEffect(() => {
    getUnregisteredDevicesHelper();
  }, []);

  const DeviceNameModal = () => {
    const [deviceName, setDeviceName] = useState("");
    return (
      <Dialog open={deviceNameModal.show} onClose={handleClose}>
        <DialogTitle>Device Name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To register this device, enter a name you would like to give this
            device. This will be used later to identify the device
          </DialogContentText>
          <TextField
            value={deviceName}
            onChange={(e) => {
              const text = e.target.value;
              setDeviceName(text);
            }}
            label="Device Name"
            variant="standard"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleClose();
              registerDeviceToLocation(deviceName);
            }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <DeviceNameModal />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <div className="devices-container">
          <h2 style={{ marginBottom: "2%" }}>
            <u>Add Device to {locationId}</u>
          </h2>
          {unregisteredDevices ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unregisteredDevices.map((device, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell>{device.device_id}</TableCell>
                        <TableCell>{device.manufacturer || "N/A"}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              setDeviceNameModal({
                                show: true,
                                device: device,
                              });
                            }}
                            color="primary">
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h3>No unregistered devices</h3>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;

    height: 90vh;
  }

  .devices-container {
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
export default AddDevice;
