import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Card,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import MapView from "../../components/MapView";
import SimpleNavBar from "../../components/LocationBreadCrumb";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  getStoreDetails,
  registerNewStore,
  updateStoreData,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { StoreData } from "../../classes/classes";
import ZReadingIcon from "../../resources/img/z-reading.png";
import XReadingIcon from "../../resources/img/x-reading.png";
import EODReport from "../../resources/img/eod-report.png";
import Waste from "../../resources/img/waste.png";
import StoreInventory from "../../resources/img/store-inventory.png";
import CashUpIcon from "../../resources/img/cash-up.png";
import MorningReport from "../../resources/img/morning-report.png";
import AddDeviceIcon from "../../resources/img/add-device.png";
import LocationInformation from "../../resources/img/location-information.png";
import Storefront from "../../resources/img/storefront.png";
import { SupportWarnings } from "../support/components/SupportComponents";
import { useSnackbar } from "notistack";

const SingleLocationMenu = () => {
  let { locationId } = useParams();
  const { locations } = useAdmin();
  const [buttons, setButtons] = useState(null);
  const [showStoreDetailsWarning, setShowStoreDetailsWarning] = useState(false);

  let navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const initButtons = async () => {
    let btns = [
      {
        text: "Location Details",
        img: LocationInformation,
        onClick: () => {
          navigate(`/locations/${locationId}/adddevice`);
        },
        secondaryText:
          "View and manage this locations data. You can edit the location types, location name and more.",
      },
      {
        text: "Add Device",
        img: AddDeviceIcon,
        onClick: () => {
          navigate(`/locations/${locationId}/adddevice`);
        },
        secondaryText: `Add an unregistered device to be registered to ${locationId}`,
      },
      {
        text: "Z Reading",
        img: ZReadingIcon,
        onClick: () => {
          navigate(`/locations/${locationId}/viewstorereports`, {
            state: {
              urlToFetchReports: "get_till_reading_reports",
              urlToFetchSingleReport: "get_a_till_reading",
              reportHeader: "Z Readings",
              urlParams: {
                reading_type: "z_reading",
                store_id: locationId,
              },
            },
          });
        },
        secondaryText:
          "View Z Reading reports for this store. You will be able to see every Z Reading from this store",
      },
      {
        text: "X Reading",
        img: XReadingIcon,
        onClick: () => {
          navigate(`/locations/${locationId}/viewstorereports`, {
            state: {
              urlToFetchReports: "get_till_reading_reports",
              urlToFetchSingleReport: "get_a_till_reading",
              reportHeader: "X Readings",
              urlParams: {
                reading_type: "x_reading",
                store_id: locationId,
              },
            },
          });
        },
        secondaryText:
          "View X Reading reports for this store. You will be able to see every X Reading from this store",
      },
      {
        text: "EOD Reports",
        img: EODReport,
        onClick: () => {
          navigate(`/locations/${locationId}/viewstorereports`, {
            state: {
              urlToFetchReports: "get_eod_reports",
              urlToFetchSingleReport: "get_an_eod_report",
              reportHeader: "EOD Reports",
              urlParams: {
                store_id: locationId,
              },
            },
          });
        },
        secondaryText:
          "View EOD reports for this store. You will be able to see every EOD report from this store",
      },
      {
        text: "Wastage Reports",
        img: Waste,
        onClick: () => {
          navigate(`/locations/${locationId}/viewstorereports`, {
            state: {
              urlToFetchReports: "get_eod_reports",
              urlToFetchSingleReport: "get_an_eod_report",
              reportHeader: "EOD Reports",
              urlParams: {
                store_id: locationId,
              },
            },
          });
        },
        secondaryText:
          "View Waste reports for this store. You will be able to see every waste report from this store",
      },
      {
        text: "Stock Reports",
        img: StoreInventory,
        onClick: () => {
          navigate(`/locations/${locationId}/viewstorereports`, {
            state: {
              urlToFetchReports: "get_eod_reports",
              urlToFetchSingleReport: "get_an_eod_report",
              reportHeader: "EOD Reports",
              urlParams: {
                store_id: locationId,
              },
            },
          });
        },
        secondaryText:
          "View Store Inventory reports for this store. You will be able to see every store inventory report from this store",
      },
      {
        text: "Cash Ups",
        img: CashUpIcon,
        onClick: () => {
          navigate(`/locations/${locationId}/viewstorereports`, {
            state: {
              urlToFetchReports: "get_cash_up_reports",
              urlToFetchSingleReport: "get_a_cash_up_report",
              reportHeader: "Cash Up Reports",
              urlParams: {
                store_id: locationId,
              },
            },
          });
        },
        secondaryText:
          "View Cash Up reports for this store. You will be able to see every cash up report from this store",
      },
      {
        text: "Morning Cash ups",
        img: MorningReport,
        onClick: () => {
          navigate(`/locations/${locationId}/viewstorereports`, {
            state: {
              urlToFetchReports: "get_cash_up_reports",
              urlToFetchSingleReport: "get_a_cash_up_report",
              reportHeader: "Cash Up Reports",
              urlParams: {
                store_id: locationId,
              },
            },
          });
        },
        secondaryText:
          "View Morning Cash Up reports for this store. You will be able to see every morning cash up report from this store",
      },
    ];

    const location = locations.find((loc) => locationId === loc.locationId);

    console.log("location:", location);

    if (location && location.locationTypes.includes("store")) {
      btns.push({
        text: "Store Information",
        img: Storefront,
        onClick: () => {
          navigate(`/locations/${locationId}/storedetails`);
        },
        secondaryText:
          "View and manage this locations store details / reviews / opening times. Store details are what your customer sees on your website / app.",
      });

      const storeDetails = await getStoreDetails(locationId, enqueueSnackbar);
      console.log("storeDetails resp:", storeDetails);
      if (!storeDetails) {
        setShowStoreDetailsWarning(true);
      }
    }

    setButtons(btns);
  };

  useEffect(() => {
    initButtons();
  }, []);

  return (
    <Wrapper>
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Select Store
        </InputLabel> */}
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <div className="content-container">
          {showStoreDetailsWarning && (
            <SupportWarnings
              text={`Warning: This location has a store registered to it, but there are no store details. Customers viewing this store will see no information`}
            />
          )}
          <div className="menu-container">
            {buttons &&
              buttons.map((btn, i) => {
                return (
                  <Card
                    key={i}
                    sx={{
                      width: "350px",
                      height: "30vh",
                      margin: "2% 0",
                    }}>
                    <CardMedia
                      component="img"
                      style={{ width: "25%", margin: "0 auto" }}
                      image={btn.img}
                      alt={btn.text}
                    />
                    <CardContent
                      style={{
                        // border: "1px solid black",
                        width: "100%",
                        overflow: "hidden",
                      }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {btn.text}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {btn.secondaryText}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={btn.onClick}>
                        View
                      </Button>
                    </CardActions>
                  </Card>
                );
              })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;
    min-height: 90vh;
    flex-direction: column;
    padding: 0 5%;
  }

  .menu-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export default SingleLocationMenu;
