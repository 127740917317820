import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import {
  SET_USER,
  LOGOUT_USER,
  REFRESH_CONTEXT,
  LOGIN_STORE,
  LOGOUT_STORE,
  UPDATE_USER,
  LOCK_TILL,
} from "../actions";
import {
  getUserRoles,
  getUserStores,
  initialiseUser,
} from "../helpers/helpers";
import { signOut, getAuth } from "firebase/auth";
import reducer from "../reducers/auth_reducer";

const AuthContext = createContext();

export const AuthProvider = ({ loggedInUser, children }) => {
  const [currentUser, dispatch] = useReducer(reducer, loggedInUser);
  // const [userRoles, setUserRoles] = useState(null);
  // const [userStores, setUserStores] = useState(null);

  const setUser = async (
    acceptedInvitation,
    name,
    email,
    emailVerified,
    createdAt,
    updatedAt,
    deviceTokens,
    uid,
    token,
    firebaseToken,
    claims
  ) => {
    console.log(
      `auth_context setUser: acceptedInvitation: ${acceptedInvitation}, name: ${name}, email: ${email}, emailVerified: ${emailVerified}, createdAt: ${createdAt}, updatedAt: ${updatedAt}, deviceTokens: ${deviceTokens}, uid: ${uid}, token: ${token}, firebaseToken: ${firebaseToken}`
    );
    const user = await initialiseUser(
      acceptedInvitation,
      name,
      email,
      emailVerified,
      createdAt,
      updatedAt,
      deviceTokens,
      uid,
      token,
      firebaseToken,
      claims
    );

    dispatch({ type: SET_USER, payload: user });
  };

  const updateUserObj = (updatedUser) => {
    //updated user format: uid, name, email, firebase_token, is_admin, is_staff, email_verified
    dispatch({ type: UPDATE_USER, payload: updatedUser });
  };

  const refreshContext = async (itemToUpdate, updatedValue) => {
    console.log("itemToUpdate:", itemToUpdate);
    console.log("updatedValue:", updatedValue);
    dispatch({
      type: REFRESH_CONTEXT,
      payload: { itemToUpdate, updatedValue },
    });
  };

  const logoutUser = async () => {
    // Logout from firebase
    // console.log("logoutUser currentUser=", currentUser);

    if (currentUser?.auth) {
      console.log("Logging out from Firebase");
      try {
        // Get the device token
        await signOut();
      } catch (error) {
        console.error(
          "Firebase auth signOut failed error code = ",
          error?.code || "",
          " message = ",
          error?.message || ""
        );
      }
    }

    window.localStorage.removeItem("auth_token");

    dispatch({ type: LOGOUT_USER });
  };

  // const getUserRolesHelper = async () => {
  //   const userRolesResp = await getUserRoles(currentUser);
  //   const userStoresResp = await getUserStores(currentUser);
  //   console.log("getUserRolesHelper, userRolesResp: ", userRolesResp);
  //   console.log("getUserRolesHelper, userStoresResp: ", userStoresResp);
  //   setUserRoles(userRolesResp);
  //   setUserStores(userStoresResp);
  // };

  // useEffect(() => {
  //   if (currentUser) {
  //     const unsubscribe = getAuth().onAuthStateChanged(function (user) {
  //       getUserRolesHelper();
  //       // handle it
  //     });
  //     return unsubscribe();
  //   }
  // }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setUser,
        logoutUser,
        loggedInUser,
        refreshContext,
        updateUserObj,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
