import React from "react";
import {
  MenuItem,
  Select,
  Toolbar,
  Skeleton,
  Breadcrumbs,
  Link,
} from "@mui/material";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const SupportBreadCrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let locationArr = location.pathname.split("/");
  locationArr.shift();
  console.log("locationArr:", locationArr);

  const goToPage = (i) => {
    console.log("i", i);
    let tempArr = [...locationArr];
    tempArr = tempArr.slice(0, i + 1);
    tempArr = tempArr.join("/");
    console.log("tempArr:", tempArr);
    navigate(`/${tempArr}`);
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {locationArr.map((loc, i) => {
        return (
          <Link
            key={i}
            color="inherit"
            underline={"hover"}
            style={{ textTransform: "capitalize" }}
            onClick={() => goToPage(i)}>
            Knowledge Base / {loc}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default SupportBreadCrumb;
