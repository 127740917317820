import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  MenuItem,
  Select,
  Toolbar as MuiToolbar,
  Button,
  Box,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  Link,
  RadioGroup,
  IconButton,
  Tabs,
  Tab,
  InputLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Chip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import { useAdmin } from "../../context/admin_context";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import { getStaff, resendPasswordResetEmail } from "../../helpers/helpers";
import StorefrontIcon from "@mui/icons-material/Storefront";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RefreshIcon from "@mui/icons-material/Refresh";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useAuth } from "../../context/auth_context";

const SimpleDialog = ({
  onClose,
  selectedValue,
  open,
  resendPasswordResetEmailHelper,
}) => {
  let navigate = useNavigate();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (id) => {
    if (id === 1) {
      navigate(`/staff/${open?.user?.uid}`, { state: open?.user });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open.visible}>
      <DialogTitle>Actions for {open?.user?.name}:</DialogTitle>
      <List sx={{ pt: 0 }}>
        {!open?.user?.acceptedInvitation && (
          <ListItem
            button
            onClick={() => resendPasswordResetEmailHelper(open?.user?.email)}
            key={1}>
            <ListItemText primary="Resend Invite" />
          </ListItem>
        )}
        <ListItem button onClick={() => handleListItemClick(1)} key={1}>
          <ListItemText primary="Edit profile" />
        </ListItem>
        <ListItem button onClick={() => handleListItemClick(2)} key={2}>
          <ListItemText primary="Edit roles & positions" />
        </ListItem>
        <ListItem button onClick={() => handleListItemClick(3)} key={3}>
          <ListItemText primary="Archive" />
        </ListItem>
      </List>
    </Dialog>
  );
};

const Staff = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    user: null,
  });

  const { currentUser } = useAuth();
  const handleClickOpen = (user) => {
    setModalVisible({ visible: true, user: user });
  };

  const handleClose = () => {
    setModalVisible({ visible: false, user: null });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();

  const { locations, selectedStore, setSelectedStore } = useAdmin();

  const onSelectStore = (e) => {
    setSelectedStore(e.target.value);
  };

  const getStaffHelper = async () => {
    const resp = await getStaff(setIsLoading, enqueueSnackbar);
    if (resp) {
      setUsers(resp);
    }
  };

  useEffect(() => {
    getStaffHelper();
  }, []);

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  const goToStore = (storeId) => {
    navigate(`/registeredstores/${storeId}`);
  };

  const UsersComp = ({ showInvitedOnly = false, showArchivedOnly = false }) => {
    return (
      <Box sx={{ width: "95%", margin: "0 2.5%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Positions</TableCell>
                  <TableCell>Locations</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Registered</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    if (showInvitedOnly && row.acceptedInvitation) {
                      return;
                    }

                    if (showArchivedOnly && !row?.archived) {
                      return;
                    }

                    console.log("row:", row);
                    return (
                      <TableRow
                        key={i}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        style={
                          currentUser?.email === row?.email
                            ? {
                                backgroundColor: "#f7f7f7",
                              }
                            : {}
                        }
                        key={row.code}>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>
                          {row?.claims?.userRoles &&
                            row?.claims?.userRoles?.map((role, i) => {
                              return (
                                <Chip
                                  key={i}
                                  icon={<VerifiedUserIcon />}
                                  label={role}
                                  variant="outlined"
                                  style={{ marginRight: 5 }}
                                />
                              );
                            })}
                        </TableCell>
                        <TableCell style={{ overflow: "hidden" }}>
                          {row?.claims?.userStores &&
                            row?.claims?.userStores?.map((loc, i) => {
                              if (i < 2) {
                                return (
                                  <div key={i}>
                                    <Chip
                                      icon={<StorefrontIcon />}
                                      label={loc}
                                      variant="outlined"
                                      // onClick={() => goToStore(loc)}
                                      style={{ marginRight: 5 }}
                                    />
                                  </div>
                                );
                              } else if (i === row?.claims?.userStores - 1) {
                                return (
                                  <div key={i}>
                                    <Link
                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        console.info("I'm a button.");
                                      }}>
                                      {`and ${
                                        row?.claims?.userStores - 2
                                      } more...`}
                                    </Link>
                                  </div>
                                );
                              }
                            })}
                        </TableCell>
                        <TableCell>{row?.email}</TableCell>
                        <TableCell>
                          {row?.acceptedInvitation ? (
                            <Chip
                              icon={<CheckCircleOutlineIcon />}
                              label="Registered"
                              color="success"
                              variant="outlined"
                            />
                          ) : (
                            <Chip
                              icon={<InfoIcon />}
                              label="&nbsp;&nbsp;Invited&nbsp;&nbsp;&nbsp;&nbsp;"
                              color="warning"
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleClickOpen(row)}>
                            <MoreHorizIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  };

  const resendPasswordResetEmailHelper = async (email) => {
    setIsLoading(true);
    await resendPasswordResetEmail(email, enqueueSnackbar);
    setIsLoading(false);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <div className="nav-container">
          <MuiToolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            <h2
              style={{
                marginLeft: "25%",
                color: "#444",
                fontFamily: "'Heebo', sans-serif",
              }}>
              Staff
            </h2>
            <IconButton
              onClick={() => {
                getStaffHelper();
              }}
              style={{ position: "absolute", right: "15%" }}
              aria-label="expand row">
              <RefreshIcon style={{ fontSize: 30 }} />
            </IconButton>
          </MuiToolbar>
        </div>
        <div className="content-container">
          <SimpleDialog
            open={modalVisible}
            onClose={handleClose}
            resendPasswordResetEmailHelper={resendPasswordResetEmailHelper}
          />
          <div className="top-container">
            <Tabs
              style={{ borderBottom: "1px solid #ccc", width: "95%" }}
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              centered>
              <Tab label="Staff" />
              <Tab label="Invitations" />
              <Tab label="Archived" />
            </Tabs>
            <Button
              onClick={() => {
                navigate(`/staff/adduser`);
              }}
              variant="contained"
              style={{ position: "absolute", right: "2%" }}>
              Add Staff Account &nbsp;{" "}
              <AddCircleOutlineIcon style={{ fontSize: 18 }} />
            </Button>
          </div>

          <div
            className="bottom-container"
            role="tabpanel"
            hidden={tabValue !== 0}>
            {users && <UsersComp />}
          </div>
          <div
            className="bottom-container"
            role="tabpanel"
            hidden={tabValue !== 1}>
            {users && <UsersComp showInvitedOnly={true} />}
          </div>
          <div
            className="bottom-container"
            role="tabpanel"
            hidden={tabValue !== 2}>
            {users && <UsersComp showArchivedOnly={true} />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60px;
    width: 90%;
    margin: 0 5%;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    border-bottom: 0.5px solid #ccc;
    box-shadow: 0px 5px 15px 5px rgba(55, 55, 55, 0.05);
  }

  .container {
    width: 100%;
  }
  .content-container {
    background-color: #f7f7f7;
    width: 100%;
    height: 90%;
    display: grid;
    grid-template-rows: 0.5fr 4fr;

    /* border: 1px solid red; */
  }

  .top-container {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-container {
    /* border: 1px solid green; */
  }

  .users-container {
    height: 100%;
  }

  .users-top: {
    height: 10%;
    border: 1px solid green;
  }

  .users-bottom: {
    height: 90%;
    border: 1px solid green;
  }

  .select-container {
    width: 20%;
    height: 100%;
  }

  .select {
    width: 100%;
    height: 60%;
  }
`;

export default Staff;
