import { Toolbar } from "@mui/material";
import React from "react";

import styled from "styled-components";
import {
  SupportHeader,
  SupportImg,
  SupportImgDouble,
  SupportInfo,
  SupportSubHeader,
  SupportText,
  SupportWarnings,
} from "./components/SupportComponents";
import SupportBreadCrumb from "./SupportBreadCrumb";
import SupportNavigation from "./SupportNavigation";
import TillDiscountsFramed from "../../resources/img/till_discounts_framed.png";
import TillDiscountScreenFramed from "../../resources/img/till_discount_screen_framed.png";
import TillGlobalDiscountTypeFramed from "../../resources/img/till_global_discount_type_framed.png";
import TillCouponScreenFramed from "../../resources/img/till_coupon_screen_framed.png";
import TillScanScreenFramed from "../../resources/img/till_scan_screen_framed.png";
import TillScanErrorFramed from "../../resources/img/till_scan_error_framed.png";
import TillScanError2Framed from "../../resources/img/till_scan_error2_framed.png";

const CouponSupport = () => {
  return (
    <Wrapper>
      <SupportNavigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Select Store
        </InputLabel> */}
            <SupportBreadCrumb />
          </Toolbar>
        </div>
        <div className="content-container">
          <SupportHeader header="Coupons" />

          <SupportSubHeader header="How to add Coupons" />
          <SupportText>
            <SupportWarnings
              text={`Warning: Coupons must be added after adding products`}
            />
            <p>
              There are two ways of adding coupons to items, from the:{" "}
              <u>Home screen</u> or <u>Subtotal Screen</u>. Both do the same
              thing the only difference is that adding coupons from the home
              screen is also used to scan UPC products
            </p>
            <br />
            <p>
              &#8226; Home Screen: To add discounts from the Home Screen,
              navigate to the home screen and press on "Scan Code". This will
              take you to the scanning screen where you can scan the customers
              coupon
              <br />
              <br />
              &#8226; Subtotal Screen: To add discounts from the Subtotal
              Screen, press "Pay" and once on the Subtotal Screen press on
              "Coupons". This screen is where you can view / modify all codes
              scanned for the current order. From this screen, press on "Scan
              Code" and this will take you to the scanning screen where you can
              scan the customers coupon
            </p>
            <SupportInfo text="If a coupon doesn't scan you can manually type in the code in the text input above the camera" />
          </SupportText>

          <SupportImgDouble
            src1={TillCouponScreenFramed}
            alt1="Till Coupon Screen Framed"
            src2={TillScanScreenFramed}
            alt2="Till Scan Screen Framed"
          />

          <SupportSubHeader header="Code not scanning?" />

          <SupportText>
            <p>
              Most Errors regarding coupon scanning will be because you havn't
              got any items in your cart which meet the coupon criteria or
              because the users coupon has expired
            </p>
            <br />
            <SupportWarnings
              text={`Warning: Users coupons refresh every 5min so if you get a "Coupon Invalid" error, ask the user to refresh their coupon on the app and try again`}
            />
          </SupportText>

          <SupportImgDouble
            src1={TillScanErrorFramed}
            alt1="Till Scan Error"
            alt2="Till Scan Error 2"
            src2={TillScanError2Framed}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  /* flex-direction: column; */

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    /* overflow: scroll; */
  }
`;

export default CouponSupport;
