import { Navigate } from "react-router-dom";
import { AdminProvider } from "./context/admin_context";
import { useAuth } from "./context/auth_context";
import { getUserRoles } from "./helpers/helpers";

const PrivateRoute = ({ requiredRoles = false, children }) => {
  console.log("requiredRoles: ", requiredRoles);

  const { currentUser } = useAuth();

  let authToken = window.localStorage.getItem("auth_token");

  if (currentUser?.uid && authToken) {
    //here we check for roles

    if (
      requiredRoles &&
      Array.isArray(requiredRoles) &&
      currentUser?.claims?.userRoles
    ) {
      const userHasPermission = requiredRoles.some((r) =>
        currentUser?.claims?.userRoles.includes(r)
      );

      console.log("user has permission: ", userHasPermission);

      if (userHasPermission) {
        return <AdminProvider>{children}</AdminProvider>;
      } else {
        return <Navigate to="/404" replace />;
      }
    } else {
      return <AdminProvider>{children}</AdminProvider>;
    }
  }

  return <Navigate to="/login" replace />;
};

export default PrivateRoute;
