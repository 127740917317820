import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Button,
  ListItemButton,
  ListItemText,
  List,
  TableCell,
  Table,
  Collapse,
  Toolbar,
  Select,
  TableHead,
  TableRow,
  Divider,
  IconButton,
  Box,
  Typography,
  TableBody,
  TableContainer,
  Paper,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  deleteOption,
  fetchEposGroups,
  fetchOptions,
  fetchProducts,
  addOption,
  updateOption,
  getStoreGroups,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import ImageIcon from "@mui/icons-material/Image";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import DisplayInStores from "../../components/DisplayInStores";

const initialState = {
  optionName: "",
  optionItems: [
    {
      itemName: "",
      priceModifier: 0,
      priceModifierType: "ABSOLUTE",
      displayInStores: ["all_stores"],
    },
  ],
  tags: [""],
  optionId: null,
  // option_type: "single",
};

const AddOptionTemplate = () => {
  const [options, setOptions] = useState(null);
  const [addOptionScreen, setAddOptionScreen] = useState({
    show: false,
    type: "add",
  });
  const [storeGroups, setStoreGroups] = useState(null);
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const [formValue, setFormValue] = useState({ ...initialState });
  const [isLoading, setIsLoading] = useState(false);
  let { storeId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    fetchOptions(setOptions, setIsLoading, enqueueSnackbar);
    getStoreGroups(setStoreGroups, setIsLoading, enqueueSnackbar);
  }, []);

  const deleteOptionAlert = (optionId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this option. This will remove this option from all products that use it."
      ) == true
    ) {
      deleteOption(optionId, setOptions, setIsLoading, enqueueSnackbar);
    }
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.optionName}
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.tags &&
              row.tags.length > 0 &&
              row.tags.map((tag) => {
                return (
                  <Chip
                    label={tag}
                    style={{ marginRight: "5px" }}
                    variant="outlined"
                  />
                );
              })}
          </TableCell>
          <TableCell align="left">{row.optionId}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => deleteOptionAlert(row.optionId)}>
              <DeleteIcon style={{ color: "#f54242" }} />
            </IconButton>
            <IconButton aria-label="expand row" size="small">
              <EditIcon
                onClick={() => {
                  // setEditingVariation(variation);
                  // setVariationValue(variation);
                  // setOpen({ show: true, type: "Update" });
                  setFormValue(row);
                  setAddOptionScreen({ show: true, type: "update" });
                }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "45%", marginRight: "5%" }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Option Items</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.optionItems.map((optItems) => (
                        <TableRow key={optItems}>
                          <TableCell component="th" scope="row">
                            {optItems.itemName}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div style={{ width: "45%", marginRight: "5%" }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Option Tags</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.tags.map((tags) => (
                        <TableRow key={tags}>
                          <TableCell component="th" scope="row">
                            {tags}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        {addOptionScreen.show ? (
          <div className="content-container">
            <div className="form-container">
              <div className="header-container-add">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#777" }}
                  onClick={() => {
                    setAddOptionScreen({ show: false, type: "" });
                  }}>
                  Go Back
                </Button>
                <h2>New Option:</h2>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (addOptionScreen.type === "update") {
                      updateOption(
                        formValue,
                        setIsLoading,
                        setOptions,
                        enqueueSnackbar
                      );
                    } else {
                      addOption(
                        formValue,
                        setIsLoading,
                        setOptions,
                        enqueueSnackbar
                      );
                    }

                    setFormValue({ ...initialState });
                    setAddOptionScreen({ show: false, type: "" });
                  }}>
                  Submit
                </Button>
              </div>
              <Divider />

              <div className="data-container">
                <div className="data-row">
                  <div className="data-col">
                    <h3>Option Name:</h3>
                  </div>
                  <div className="data-col">
                    <TextField
                      value={formValue.optionName}
                      onChange={(e) => {
                        setFormValue((oldS) => {
                          let newState = { ...oldS };
                          newState.optionName = e.target.value;
                          return newState;
                        });
                      }}
                      style={{ width: "90%" }}
                      label="Option Name"
                      variant="outlined"
                      type="search"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <Divider />

                {/* <div className="data-row">
                  <div className="data-col">
                    <h3>Option Type:</h3>
                  </div>
                  <div className="data-col">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formValue.option_type}
                        onChange={(e) => {
                          setFormValue((oldS) => {
                            let newState = { ...oldS };
                            newState.option_type = e.target.defaultValue;
                            return newState;
                          });
                        }}>
                        <FormControlLabel
                          value="single"
                          control={<Radio />}
                          label="Single"
                        />
                        <FormControlLabel
                          value="multiple"
                          control={<Radio />}
                          label="Multiple"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <Divider /> */}

                <div className="item-container">
                  <h3>Option Items:</h3>
                  {formValue.optionItems.map((data, i) => {
                    return (
                      <div className="data-row">
                        <div
                          className="data-col"
                          style={{ justifyContent: "space-around" }}>
                          <button
                            onClick={() => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                let itemOptions = [...newState.optionItems];
                                itemOptions.splice(i, 1);
                                newState.optionItems = itemOptions;
                                return newState;
                              });
                            }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}>
                            <RemoveCircleIcon style={{ color: "#f54242" }} />
                          </button>

                          <h4>Item {i + 1}:</h4>
                        </div>
                        <div className="data-col">
                          <TextField
                            value={formValue.optionItems[i].itemName}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.optionItems[i].itemName =
                                  e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            id="outlined-basic"
                            label="Item Name"
                            variant="outlined"
                            autoComplete="off"
                          />
                        </div>
                        <DisplayInStores
                          setSelectedOptionCopy={setFormValue}
                          selectedOptionCopy={formValue}
                          optionIndex={i}
                          storeGroups={storeGroups}
                        />
                      </div>
                    );
                  })}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setFormValue((oldS) => {
                        let newState = { ...oldS };
                        newState.optionItems.push({
                          itemName: "",
                          priceModifier: 0,
                          priceModifierType: "ABSOLUTE",
                          displayInStores: ["all_stores"],
                        });
                        console.log("newState:", newState);
                        return newState;
                      });
                    }}>
                    Add Option Item
                  </Button>
                </div>
                <Divider />

                <div className="item-container">
                  <h3>Option Tags:</h3>
                  {formValue.tags.map((tag, i) => {
                    return (
                      <div className="data-row">
                        <div
                          className="data-col"
                          style={{ justifyContent: "space-around" }}>
                          <button
                            onClick={() => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                let itemOptions = [...newState.tags];
                                itemOptions.splice(i, 1);
                                newState.tags = itemOptions;
                                return newState;
                              });
                            }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}>
                            <RemoveCircleIcon style={{ color: "#f54242" }} />
                          </button>

                          <h4>Tag {i + 1}:</h4>
                        </div>
                        <div className="data-col">
                          <TextField
                            value={formValue.tags[i]}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.tags[i] = e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            id="outlined-basic"
                            label="Tag"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setFormValue((oldS) => {
                        let newState = { ...oldS };
                        newState.tags.push("");
                        console.log("newState:", newState);
                        return newState;
                      });
                    }}>
                    Add Option Item
                  </Button>
                </div>
                <Divider />
              </div>
            </div>
          </div>
        ) : (
          <div className="content-container">
            <div className="header-container">
              <h2>All Template Options:</h2>
              <Button
                variant="contained"
                onClick={() => setAddOptionScreen({ show: true, type: "add" })}>
                Add new Option
              </Button>
            </div>

            {options && options?.length > 0 && (
              <div className="epos-groups-container">
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Option Name</TableCell>
                        <TableCell>Option Tags</TableCell>
                        <TableCell align="left">Option Id</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {options.map((row) => (
                        <Row key={row.optio_id} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    width: 100%;

    height: 90vh;
    /* display: grid; */
    /* grid-template-rows: 1fr 9fr; */
    /* border: 1px solid red; */
  }
  .header-container {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3%;
  }

  .epos-groups-container {
    width: 100%;
    height: 90%;
    text-align: center;
    overflow: scroll;
    /* border: 1px solid black; */
    padding: 0 2% 2% 2%;
    /* display: flex; */
    /* background-color: #f9f9f9; */
    /* justify-content: space-around;
    align-items: center;
    justify-content: center; */
  }

  .products-container-top {
    height: 10%;
    width: 100%;
    padding: 0 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 6px 10px -1px rgba(0, 0, 0, 0.05);
  }
  .products-container-bottom {
    height: 90%;
    /* border: 1px solid black; */
    width: 100%;
    overflow: scroll;
    padding: 1%;
  }

  .form-container {
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    width: 90%;
    height: 95%;
    margin: 2.5% 5%;
    overflow-y: scroll;
  }

  .header-container-add {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20%;
  }

  .data-container {
    width: 100%;
    height: 90%;
    text-align: center;
    /* border: 1px solid black; */
    padding: 0 2%;
    /* display: flex; */
    /* background-color: #f9f9f9; */
    /* justify-content: space-around;
    align-items: center;
    justify-content: center; */
  }
  .item-container {
    /* border: 1px solid black; */
    margin: 2.5% 0;
  }
  .data-row {
    width: 100%;
    display: flex;
    margin: 2% 0;
  }
  .data-col {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default AddOptionTemplate;
