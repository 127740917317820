import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  TextField,
  Box,
  Button,
  Tabs,
  Tab,
  ButtonGroup,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  Zoom,
} from "@mui/material";

import Loader from "./Loader";
import { useSnackbar } from "notistack";
import { useAdmin } from "../context/admin_context";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import EventIcon from "@mui/icons-material/Event";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import PatternIcon from "@mui/icons-material/Pattern";
import PasswordIcon from "@mui/icons-material/Password";
import InfoIcon from "@mui/icons-material/Info";

const EmployeeProfileComp = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));
  const [selectedListIndex, setSelectedListIndex] = useState(0);

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const { enqueueSnackbar } = useSnackbar();

  const { userId } = useParams();

  let navigate = useNavigate();
  let location = useLocation();

  const user = location?.state;

  console.log("location.state:", location.state);

  const { locations, selectedStore, setSelectedStore } = useAdmin();

  const handleDateChange = (newValue) => {
    setDate(newValue);
  };

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  return (
    <Wrapper>
      <div className="right-top">
        <div className="name-container">
          <h2
            style={{
              fontFamily: "'Heebo', sans-serif",
            }}>
            {user?.name}
          </h2>

          <h4
            style={{
              fontFamily: "'Heebo', sans-serif",
              margin: "2% 0",
            }}>
            Invited on{" "}
            {new Date(user?.createdAt).toLocaleDateString("en-GB", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </h4>

          <ButtonGroup variant="outlined">
            <Button startIcon={<ChatBubbleOutlineIcon />}>Message</Button>
            <Button startIcon={<AccessTimeIcon />}>Shifts</Button>
          </ButtonGroup>
        </div>

        <Tabs
          style={{
            borderBottom: "1px solid #ccc",
            position: "absolute",
            left: "50%",
            bottom: 0,
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          centered>
          <Tab label="Personal Information" />
          <Tab label="Contact Details" />
          <Tab label="Health and Safety" />
          <Tab label="Employment" />
        </Tabs>
      </div>

      <div
        style={{ display: tabValue !== 0 ? "none" : "flex" }}
        className="right-bottom"
        role="tabpanel"
        hidden={tabValue !== 0}>
        <div
          style={{
            height: "10%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}>
          <h2
            style={{
              fontFamily: "'Heebo', sans-serif",
            }}>
            Personal Information
          </h2>
        </div>
        <div
          style={{
            width: "100%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <TextField
            className="text-input"
            id="input-with-sx"
            label="Name"
            variant="outlined"
            value={user?.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text-input"
            id="input-with-sx"
            label="Email "
            variant="outlined"
            value={user?.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                </InputAdornment>
              ),
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date of birth"
              inputFormat="MM/dd/yyyy"
              value={date}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  className="text-input"
                  {...params}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Button style={{ margin: "3% 0" }} variant="contained">
            Save
          </Button>
        </div>
      </div>
      <div
        style={{ display: tabValue !== 1 ? "none" : "flex" }}
        className="right-bottom"
        role="tabpanel"
        hidden={tabValue !== 1}>
        <div
          style={{
            height: "10%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}>
          <h2
            style={{
              fontFamily: "'Heebo', sans-serif",
            }}>
            Contact details
          </h2>
        </div>
        <div
          style={{
            width: "100%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <TextField
            className="text-input"
            id="input-with-sx"
            label="Street Address"
            variant="outlined"
            value={user?.address}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text-input"
            id="input-with-sx"
            label="Phone Number"
            variant="outlined"
            value={user?.phone}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CallIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                </InputAdornment>
              ),
            }}
          />
          <Button style={{ margin: "3% 0" }} variant="contained">
            Save
          </Button>
        </div>
      </div>
      <div
        style={{ display: tabValue !== 2 ? "none" : "flex" }}
        className="right-bottom"
        role="tabpanel"
        hidden={tabValue !== 2}>
        Item Three
      </div>
      <div
        style={{ display: tabValue !== 3 ? "none" : "flex" }}
        className="right-bottom"
        role="tabpanel"
        hidden={tabValue !== 3}>
        <div
          style={{
            height: "10%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}>
          <h2
            style={{
              fontFamily: "'Heebo', sans-serif",
            }}>
            Employment
          </h2>
        </div>
        <div
          style={{
            width: "100%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <TextField
            className="text-input"
            label="Employee number"
            variant="outlined"
            value={user?.address}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PatternIcon
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Unique sequence of numbers and / or letters that identifies the employee inside your organization">
                  <InputAdornment position="start">
                    <InfoIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          <TextField
            className="text-input"
            id="input-with-sx"
            label="Time and Attendance PIN"
            variant="outlined"
            value={user?.phone}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon
                    sx={{ color: "action.active", mr: 1, my: 0.5 }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <FormControl className="text-input">
            <InputLabel id="demo-simple-select-label">
              Employment Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user?.employmentStatus}
              label="Employment Status"
              onChange={handleChange}>
              <MenuItem value={10}>Full-time</MenuItem>
              <MenuItem value={20}>Part-time</MenuItem>
              <MenuItem value={30}>Student</MenuItem>
              <MenuItem value={40}>Seasonal</MenuItem>
              <MenuItem value={50}>In Probation</MenuItem>
              <MenuItem value={60}>Contractual</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date of Hire"
              inputFormat="MM/dd/yyyy"
              value={user?.created_at}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  className="text-input"
                  {...params}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>

          <Button style={{ margin: "3% 0" }} variant="contained">
            Save
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;

  .right-top {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .right-bottom {
    /* border: 1px solid blue; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
  }

  .list-item: {
    border: 1px solid #ccc;
    background-color: black;
  }

  .text-input {
    width: 60%;
    margin: 2% 0;
  }

  .name-container {
    /* padding: 1% 0 2.5% 10%; */
    /* border: 1px solid green; */
  }
`;

export default EmployeeProfileComp;
