import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Paper,
  TableContainer,
  IconButton,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PDFView from "../../components/PDFView";

const REACT_APP_API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

const ViewStoreReports = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [nextReport, setNextReport] = useState({});
  const [reports, setReports] = useState(null);
  const [noMoreReports, setNoMoreReports] = useState(false);

  const [isIinitialLoading, setIsInitialLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  let { urlToFetchReports, urlToFetchSingleReport, urlParams, reportHeader } =
    location.state;

  const getAllReports = async () => {
    if (noMoreReports) {
      setIsLoading(false);
      // Alert.alert("Showing All Repoorts");
      return;
    }
    try {
      setIsLoading(true);
      const authToken = window.localStorage.getItem("auth_token");
      console.log("authToken:", authToken);
      const resp = await axios.post(
        `${REACT_APP_API_ADDRESS}/${urlToFetchReports}`,
        //prettier-ignore
        { "admin_token": authToken, "last_value": nextReport, ...urlParams }
      );

      console.log("resp from getAllReports:", resp.data);

      if (resp.data.length === 0) {
        setNextReport(null);
        setIsLoading(false);
        setIsInitialLoading(false);
        return;
      }

      let reportArray = Array.from(Object.values(resp.data));

      //also have to check if its the last item which is when the array is of length 1

      if (reportArray.length > 0 && reportArray.length < 20) {
        setReports((oldS) => (oldS ? oldS.concat(reportArray) : reportArray));
        setNoMoreReports(true);
        setNextReport({});
      } else {
        reportArray.splice(-1, 1);

        let nextReportItem = reportArray[reportArray.length - 1];

        setReports((oldS) => (oldS ? oldS.concat(reportArray) : reportArray));
        setNextReport(nextReportItem);
      }

      setIsLoading(false);
      setIsInitialLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsInitialLoading(false);
      console.log(error.message);
      console.log(error);
      enqueueSnackbar("Something went wrong when trying to upload the report", {
        variant: "error",
      });
    }
  };

  const getReport = async (reportId) => {
    try {
      setIsLoading(true);
      const authToken = window.localStorage.getItem("auth_token");
      console.log("authToken:", authToken);

      const resp = await axios.post(
        `${REACT_APP_API_ADDRESS}/${urlToFetchSingleReport}`,
        //prettier-ignore
        { "admin_token": authToken, "report_id": String(reportId), ...urlParams }
      );

      console.log("resp from uploadReport:", resp.data);
      setPdfUrl(resp.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
      console.log(error);
      enqueueSnackbar("Something went wrong when trying to get the report", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getAllReports();
  }, []);

  if (isIinitialLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (pdfUrl) {
    return <PDFView pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} />;
  }

  return (
    <Wrapper>
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Select Store
        </InputLabel> */}
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <div className="reports-container">
          <h2 style={{ marginBottom: "3%" }}>{reportHeader}</h2>
          {reports && reports?.length > 0 ? (
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Report Name</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports.map((report, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell component="th" scope="row">
                            {report.report_name.toUpperCase()}
                          </TableCell>
                          <TableCell align="right">
                            {report.date ||
                              new Date(
                                report.timestamp * 1000
                              ).toLocaleString()}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => {
                                getReport(report?.id);
                              }}
                              color="primary"
                              aria-label="get report">
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <h2>No Reports</h2>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;

    height: 90vh;
  }

  .reports-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2%;
  }
`;
export default ViewStoreReports;
