import React, { useEffect, useState } from "react";
import { fetchOptions, fetchProducts } from "../helpers/helpers";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

const ViewProducts = () => {
  const [products, setProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [tags, setTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [screen, setScreen] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();

  const handleToggle = (value) => () => {
    console.log("value:", value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange = (i) => {
    setChecked((oldS) => {
      let newState = [...oldS];
      if (newState[i]) {
        newState[i] = !newState[i];
      } else {
        newState[i] = true;
      }
      return newState;
    });
  };

  useEffect(() => {
    fetchProducts(setProducts, enqueueSnackbar, setIsLoading);
  }, [location]);

  useEffect(() => {
    if (products) {
      let tags = [];
      products.map((opt) => {
        if (opt.tags) {
          opt.tags.map((tag) => {
            if (!tags.includes(tag)) {
              tags.push(tag);
            }
          });
        }
      });
      setTags(tags);
    }
  }, [products]);

  useEffect(() => {
    if (selectedTags.length > 0) {
      const filteredOptGroups = [];
      products.filter((opt) => {
        let isAllTagsInOption = true;
        selectedTags.map((tag) => {
          if (!opt.tags.includes(tag)) {
            isAllTagsInOption = false;
          }
        });

        if (isAllTagsInOption) {
          if (!filteredOptGroups.includes(opt)) {
            filteredOptGroups.push(opt);
          }
        }
      });
      setFilteredProducts(filteredOptGroups);
    } else {
      setFilteredProducts(null);
    }
  }, [selectedTags]);

  const OptionsList = ({ opt, i }) => {
    const labelId = `checkbox-list-label-${opt}`;

    return (
      <ListItem
        key={i}
        divider={i !== products.length - 1}
        //   secondaryAction={
        //     <IconButton edge="end" aria-label="comments">
        //       <CommentIcon />
        //     </IconButton>
        //   }
        disablePadding>
        <ListItemButton role={undefined} onClick={handleToggle(opt.name)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(opt.name) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={opt.name} />
        </ListItemButton>
      </ListItem>
    );
  };

  console.log("checked:", checked);

  return (
    <Wrapper>
      {modalVisible && (
        <Dialog onClose={() => setModalVisible(false)} open={modalVisible}>
          <DialogTitle>
            The following options will be added to this product:
          </DialogTitle>
          <List sx={{ pt: 0, height: "40vh", overflow: "scroll" }}>
            {checked.map((item, i) => (
              <ListItem button onClick={handleToggle(item)} key={item}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": i }}
                  />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              height: "10vh",
            }}>
            <Button
              style={{ width: "25%", backgroundColor: "#777" }}
              variant="contained"
              onClick={() => setModalVisible(false)}>
              Close
            </Button>
            {checked.length > 0 && (
              <Button style={{ width: "25%" }} variant="contained">
                Confirm
              </Button>
            )}
          </div>
        </Dialog>
      )}
      {isLoading && <Loader />}

      {products && tags ? (
        <div
          style={{
            display: "flex",
            // border: "1px solid black",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            paddingTop: "1%",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <ButtonGroup variant="text" size="medium">
            <Button onClick={() => setScreen(0)}>
              Add Products To this Category
            </Button>
            <Button onClick={() => setScreen(1)}>
              View Products in This Category
            </Button>
          </ButtonGroup>

          {screen === 0 && (
            <div className="add-options-container">
              <Autocomplete
                style={{ width: "70%", margin: "1% 0 3% 0", height: "10%" }}
                multiple
                onChange={(event, newValue, reason) => {
                  setSelectedTags(newValue);
                  console.log("newValue:", newValue);
                }}
                id="tags-standard"
                options={tags}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search by Name or Tags"
                    placeholder="Enter name or tags"
                  />
                )}
              />
              {checked.length > 0 && (
                <Button
                  variant="contained"
                  onClick={() => setModalVisible(true)}>
                  Add options to product
                </Button>
              )}
              <div
                style={{
                  //   border: "1px solid black",
                  width: "100%",
                  height: "75%",
                  overflow: "scroll",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  width: "80%",
                  margin: "2.5% 10%",
                  //   paddingTop: "25%",
                }}>
                <List
                  style={{
                    // border: "1px solid red",
                    width: "100%",
                    height: "100%",
                  }}>
                  {filteredProducts ? (
                    <>
                      {filteredProducts.map((opt, i) => (
                        <OptionsList opt={opt} i={i} />
                      ))}
                    </>
                  ) : (
                    <>
                      {products.map((opt, i) => (
                        <OptionsList opt={opt} i={i} />
                      ))}
                    </>
                  )}
                </List>
              </div>
            </div>
          )}

          {screen === 1 && (
            <div className="add-options-container">
              <h2>Products on this screen</h2>
            </div>
          )}
        </div>
      ) : (
        <h2>No Product Options</h2>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  /* border: 3px solid red; */

  .options-container: {
    /* border: 1px solid black; */

    /* height: 100%; */
    /* width: 100%;
    border: 5px solid black;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; */
  }

  .option-items-container {
    width: 10%;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .add-options-container {
    /* border: 1px solid red; */
    overflow: scroll;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    flex-direction: column;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border: 1px solid black;
  }
`;

export default ViewProducts;
