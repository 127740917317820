import React, { useEffect, useState } from "react";
import {
  addProductVariation,
  deleteProductVariation,
  fetchOptions,
  getProductOptions,
  getStoreGroups,
  updateProductOption,
  updateProductVariation,
} from "../helpers/helpers";
import styled from "styled-components";
import {
  TextField,
  DialogTitle,
  Button,
  IconButton,
  Dialog,
  Paper,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  Select,
  MenuItem,
  DialogActions,
  InputAdornment,
  Switch,
  ListItemText,
  Popper,
  Typography,
} from "@mui/material";
import { DataGrid, gridClasses, GridActionsCellItem } from "@mui/x-data-grid";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Checkbox from "@mui/material/Checkbox";
import CommentIcon from "@mui/icons-material/Comment";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import ViewProductOptions from "./ViewProductOptions";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useAdmin } from "../context/admin_context";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/system";

const initialVariationValue = {
  options: [
    { optionItem: [], optionId: "storeGroup", optionName: "storeGroup" },
  ],
  eatInPrice: 0,
  takeawayPrice: 0,
  unitCost: 0,
  externalSku: "",
  stockEnabled: false,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

function isOverflown(element) {
  console.log("element.scrollHeight:", element.scrollHeight);
  console.log("element.clientHeight:", element.clientHeight);
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    if (cellValue.current.clientHeight > cellDiv.current.clientHeight) {
      setShowPopper(true);
    } else {
      setShowPopper(false);
    }

    setAnchorEl(cellValue.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  console.log("value:", value);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        width: "100%",
        height: "50px",
      }}>
      <Box
        ref={cellDiv}
        sx={{
          height: "50px",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />

      <div
        ref={cellValue}
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
          minHeight: "100%",
        }}>
        {value?.map((val, i) => {
          return (
            <p key={i}>
              {val.optKey}:&nbsp;&nbsp;
              <u>
                <b>{val.optValue}</b>
              </u>
            </p>
          );
        })}
      </div>

      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width }}>
          <Paper
            elevation={1}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: "2%",
              flexDirection: "column",
            }}>
            {value?.map((val, i) => {
              return (
                <p key={i}>
                  {val.optKey}:&nbsp;&nbsp;
                  <u>
                    <b>{val.optValue}</b>
                  </u>
                </p>
              );
            })}
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const ViewVariations = ({ product, setProduct }) => {
  const [storeGroups, setStoreGroups] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState({ show: false, type: "" });
  const [samePricing, setSamePricing] = useState(true);
  const [variationInUseDialog, setVariationInUseDialog] = useState({
    show: false,
    data: null,
  });
  const [editingVariation, setEditingVariation] = useState(null);
  const [variationValue, setVariationValue] = useState({
    ...initialVariationValue,
  });

  useEffect(() => {
    getStoreGroups(setStoreGroups, setIsLoading, enqueueSnackbar);
  }, []);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setEditingVariation(null);
    resetVariationValue();
    setOpen({ show: false, type: "" });
  };

  const resetVariationValue = () => {
    setVariationValue((oldS) => {
      let newState = { ...oldS };
      newState.options = [
        {
          optionItem: [],
          optionId: "storeGroup",
          optionName: "storeGroup",
        },
      ];
      newState.price = 0;
      newState.externalSku = "";
      newState.stockEnabled = false;
      return newState;
    });
  };

  const deleteProductVariationFn = async (variationId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this variation. This action can't be undone "
      ) == true
    ) {
      setIsLoading(true);

      let [variation] = product?.variations.filter((vt) => {
        return vt?.variationId === variationId;
      });

      if (!variation) return;

      let variationCopy = { ...variation };
      console.log("variaitonCopy:", variationCopy);

      if (Array.isArray(variationCopy.options[0].optionItem)) {
        variationCopy.options[0].optionItem =
          variationCopy.options[0].optionItem.join("-");
      }

      // if (Array.isArray(variationCopy.options[0].optionItem)) {
      // variationCopy.options[0].optionItem =
      // variationCopy.options[0].optionItem.join("-");
      // }

      variationCopy.eatInPrice = Math.round(
        Number(variationCopy.eatInPrice) * 100
      );
      variationCopy.takeawayPrice = Math.round(
        Number(variationCopy.takeawayPrice) * 100
      );

      const hasDeletedResp = await deleteProductVariation(
        product?.productId,
        variationCopy,
        enqueueSnackbar
      );

      if (hasDeletedResp) {
        if (hasDeletedResp?.variationInUse) {
          setVariationInUseDialog({
            show: true,
            data: hasDeletedResp?.storeStockList,
          });
          enqueueSnackbar(
            "This variation is currently in use and can not be deleted",
            {
              variant: "warning",
            }
          );
        }
        if (hasDeletedResp?.variationDeleted) {
          enqueueSnackbar("Successfully removed variation", {
            variant: "success",
          });
          setProduct((oldS) => {
            let newState = { ...oldS };
            newState.variations = oldS.variations.filter(
              (v) => v?.variationId !== variation?.variationId
            );
            return newState;
          });
          resetVariationValue();
        }
      }
      setIsLoading(false);
    }
  };

  const addProductVariationFn = async (variation) => {
    let variationCopy = { ...variation };
    if (Array.isArray(variationCopy.options[0].optionItem)) {
      variationCopy.options[0].optionItem =
        variationCopy.options[0].optionItem.join("-");
    }

    setIsLoading(true);
    const updatedProduct = await addProductVariation(
      product?.productId,
      variationCopy,
      enqueueSnackbar
    );
    if (updatedProduct) {
      setProduct(updatedProduct);
      handleClose();
    }
    setIsLoading(false);
  };
  const updateProductVariationFn = async (originalVariationId, variation) => {
    let variationCopy = { ...variation };
    if (Array.isArray(variationCopy.options[0].optionItem)) {
      variationCopy.options[0].optionItem =
        variationCopy.options[0].optionItem.join("-");
    }

    setIsLoading(true);
    const updatedProduct = await updateProductVariation(
      product?.productId,
      originalVariationId,
      variationCopy,
      enqueueSnackbar
    );
    if (updatedProduct) {
      setProduct(updatedProduct);
      handleClose();
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    let hasSelectedAllVariations = true;
    console.log("variationValue.options:", variationValue.options);
    console.log("product?.productOptions:", product?.productOptions);
    // singleProdOptionsLen starts at 1 is to account for store being an 'option' too

    if (
      !variationValue.options[0]?.optionItem ||
      variationValue.options[0]?.optionItem?.length === 0
    ) {
      enqueueSnackbar("You have not selected store groups", {
        variant: "error",
      });
      return;
    }

    if (open.type === "Update") {
      //   updateProductVariationFn(variationValue);

      let originalVariationId = editingVariation.variationId;

      let isDuplicate = false;
      //   check to make sure the updated variation is not a duplicate
      product.variations.map((variation) => {
        if (variation.variationId !== originalVariationId) {
          let allOptionsDuplicate = true;
          variation.options.map((opt, i) => {
            if (opt.optionItem !== variationValue.options[i]?.optionItem) {
              allOptionsDuplicate = false;
            }
          });

          isDuplicate = allOptionsDuplicate;
        }
      });

      if (isDuplicate) {
        enqueueSnackbar("This is a duplicate variation", {
          variant: "error",
        });
      } else {
        const variationValueCopy = { ...variationValue };
        const filteredOptions = variationValueCopy.options.filter(
          (val) => val.optionItem !== "any"
        );

        if (filteredOptions && filteredOptions?.length >= 1) {
          // its 1 to account for the storeGroup option
          variationValueCopy.options = filteredOptions;
          console.log("variationValueCopy:", variationValueCopy);

          variationValueCopy.eatInPrice = Math.round(
            variationValueCopy.eatInPrice * 100
          );
          variationValueCopy.takeawayPrice = Math.round(
            variationValueCopy.takeawayPrice * 100
          );
          variationValueCopy.unitCost = Math.round(
            variationValueCopy.unitCost * 100
          );

          console.log("variationValue TO UPDATE:", variationValueCopy);
          updateProductVariationFn(originalVariationId, variationValueCopy);
        } else {
          enqueueSnackbar("At least 1 option must not be 'ANY'", {
            variant: "error",
          });
        }
      }
    } else {
      let singleProdOptionsLen = 1;

      product.productOptions.map((opt) => {
        if (opt?.optionType === "single") {
          singleProdOptionsLen += 1;
        }
      });

      if (variationValue.options.length !== singleProdOptionsLen) {
        enqueueSnackbar("You have not selected all variations", {
          variant: "error",
        });
        return;
      }

      let variationValueCopy = { ...variationValue };

      variationValueCopy.options[0].optionItem =
        variationValueCopy.options[0].optionItem.join("-");

      let isDuplicate = false;

      if ("variations" in product) {
        //   check if this is a duplicate variation
        product.variations.map((variation) => {
          let allOptionsDuplicate = true;
          variation.options.map((opt, i) => {
            // console.log("oldOpt:", opt.optionItem);
            // console.log("newOpt:", variationValueCopy.options[i]?.optionItem);
            if (opt.optionItem !== variationValueCopy.options[i]?.optionItem) {
              allOptionsDuplicate = false;
            }
          });

          isDuplicate = allOptionsDuplicate;
        });
      } else {
        isDuplicate = false;
      }
      if (isDuplicate) {
        enqueueSnackbar("This is a duplicate variation", {
          variant: "error",
        });
      } else {
        // this is where we check if the option is any and if it is we remove from the options array

        const filteredOptions = variationValueCopy.options.filter(
          (val) => val.optionItem !== "any"
        );

        if (filteredOptions && filteredOptions?.length >= 1) {
          // its 1 to account for the storeGroup option as if we include it in the filter above, the filteredOptions override the option
          variationValueCopy.options = filteredOptions;
          console.log("variationValueCopy:", variationValueCopy);
          variationValueCopy.eatInPrice = Math.round(
            variationValueCopy.eatInPrice * 100
          );
          variationValueCopy.takeawayPrice = Math.round(
            variationValueCopy.takeawayPrice * 100
          );
          variationValueCopy.unitCost = Math.round(
            variationValueCopy.unitCost * 100
          );
          addProductVariationFn(variationValueCopy);
        } else {
          enqueueSnackbar("At least 1 option must not be 'ANY'", {
            variant: "error",
          });
        }
      }
    }
  };

  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  const SamePricingSwitch = () => {
    return (
      <Switch
        checked={samePricing}
        onChange={() => {
          console.log("!samePricing:", !samePricing);
          if (!samePricing) {
            setVariationValue((oldS) => {
              let newState = { ...oldS };
              newState.eatInPrice = 0;
              newState.takeawayPrice = 0;
              return newState;
            });
          }
          setSamePricing((oldS) => !oldS);
        }}
      />
    );
  };

  const columns = [
    { field: "index", headerName: "#", width: window.innerWidth / 15 },
    {
      field: "storeGroup",
      headerName: "Store Groups",
      width: window.innerWidth / 10,
      renderCell: (params) => (
        // console.log("params:", params);
        <div>
          {params?.value?.map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
      ),
    },
    {
      field: "options",
      headerName: "Options",
      width: window.innerWidth / 6,
      renderCell: renderCellExpand,
    },
    {
      field: "stockEnabled",
      headerName: "Stock Enabled",
      align: "center",
      width: window.innerWidth / 13,
      renderCell: (stockEnabled) => {
        if (stockEnabled.value) {
          return <CheckCircleIcon color="success" />;
        } else {
          return <CancelIcon color="disabled" />;
        }
      },
    },
    {
      field: "sku",
      headerName: "SKU",
      width: window.innerWidth / 10,
    },
    {
      field: "eatInPrice",
      headerName: "Eat In Price",
      type: "number",
      width: window.innerWidth / 12,
      valueFormatter: (params) => {
        if (params?.value == null) {
          return "";
        }

        return `£ ${params?.value}`;
      },
    },
    {
      field: "takeawayPrice",
      headerName: "Takeaway Price",
      type: "number",
      width: window.innerWidth / 12,
      valueFormatter: (params) => {
        if (params?.value == null) {
          return "";
        }

        return `£ ${params?.value}`;
      },
    },
    {
      type: "actions",
      field: "actions",
      headerName: "",
      width: window.innerWidth / 12,
      getActions: (params) => {
        console.log("paramss:", params);
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() =>
              deleteProductVariationFn(params?.row?.actions?.variationId)
            }
            label="Delete"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => {
              let variationId = params?.row?.actions?.variationId;

              let [variation] = product?.variations.filter((vt) => {
                return vt?.variationId === variationId;
              });

              if (!variation) return;

              let variationCopy = { ...variation };
              console.log("variationCopy:", variationCopy);
              if (typeof variationCopy.options[0].optionItem === "string") {
                variationCopy.options[0].optionItem =
                  variationCopy.options[0].optionItem.split("-");
              }

              setEditingVariation(variationCopy);
              setVariationValue(variationCopy);
              setOpen({ show: true, type: "Update" });
            }}
            label="Edit"
            showInMenu
          />,
        ];
      },
    },
  ];

  const rows = product?.variations?.map((variation, i) => {
    let storeGroupArr = [];
    let options = [];

    console.log("variation:", variation);

    variation.options.map((opt, i) => {
      if (opt?.optionName === "storeGroup") {
        let storeGroupIds = opt?.optionItem;

        if (storeGroupIds === "all_stores") {
          storeGroupArr.push("All Stores");
          return;
        }

        console.log("storeGroupIds:", storeGroupIds);

        if (!Array.isArray(storeGroupIds)) {
          storeGroupIds = storeGroupIds.split("-");
        }

        let storeGroupNames = "";
        console.log("storeGroupIds:", storeGroupIds);
        for (let storeGroupId of storeGroupIds) {
          console.log("storeGroupId:", storeGroupId);

          let [storeGroup] = storeGroups.filter(
            (storeG) => storeG?.storeGroupId === storeGroupId
          );
          if (storeGroup) {
            // storeGroupNames += `(${storeGroup?.storeGroupName}) `;
            storeGroupArr.push(storeGroup?.storeGroupName);
          }
        }
        // storeGroupNames = storeGroupNames.slice(0, -1);
        // return { optKey: "Store Group", optValue: storeGroupNames };
      } else {
        options.push({ optKey: opt.optionName, optValue: opt.optionItem });
      }
    });

    return {
      id: i,
      index: i,
      storeGroup: storeGroupArr,
      options: options,
      stockEnabled: variation?.stockEnabled,
      sku: variation?.externalSku || "N/A",
      eatInPrice: variation?.eatInPrice,
      takeawayPrice: variation?.takeawayPrice,
      actions: variation,
    };
  });
  return (
    <Wrapper>
      <Dialog
        style={{ width: "50%", margin: "0 auto" }}
        fullWidth
        maxWidth
        open={variationInUseDialog.show}
        onClose={() => {
          setVariationInUseDialog({ show: false, data: null });
        }}>
        <DialogTitle> Variation In Use</DialogTitle>
        <div
          style={{
            // border: "1px solid black",
            borderTop: "1px solid gray",
            height: "60vh",
            width: "100%",
            padding: "10% 10% 0 10%",
            borderBottom: "1px dashed gray",
            backgroundColor: "#f7f7f7",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "center",
            overflow: "scroll",
            position: "relative",
          }}>
          <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
            <u>Stores using this variation</u>
          </h4>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell align="right">Store ID</TableCell>
                  <TableCell align="right">PLU</TableCell>
                  <TableCell align="right">SKU</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {variationInUseDialog?.data &&
                  variationInUseDialog.data.length > 0 &&
                  variationInUseDialog.data.map((data, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell component="th" scope="row">
                          {data?.itemName}
                        </TableCell>
                        <TableCell align="right"> {data?.locationId}</TableCell>
                        <TableCell align="right"> {data?.plu}</TableCell>
                        <TableCell align="right"> {data?.sku}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <span
            style={{
              position: "absolute",
              left: "5%",
              bottom: "5%",
              fontSize: "13px",
              padding: "0 10%",
              textAlign: "center",
            }}>
            To delete this variation, delete these instances from each store
            which stocks the variation using the admin app
          </span>
        </div>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setVariationInUseDialog({ show: false, data: null });
            }}
            style={{ width: "30%" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        style={{ width: "70%", margin: "0 auto" }}
        fullWidth
        maxWidth
        open={open.show}
        onClose={handleClose}>
        <DialogTitle>{open.type} Variation</DialogTitle>
        <div
          style={{
            // border: "1px solid black",
            borderTop: "1px solid gray",
            height: "80vh",
            width: "100%",
            display: "grid",
            gridTemplateRows: "1fr 1.3fr 1fr 1fr",
          }}>
          <div
            className="variation-section"
            style={{
              //   border: "1px solid black",
              borderBottom: "1px dashed gray",

              backgroundColor: "#f7f7f7",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              overflow: "scroll",
              position: "relative",
            }}>
            <div
              style={{
                width: "80%",
                //   border: "1px solid black",
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
                <u>Store Group</u>
              </h4>
              <p
                style={{
                  width: "20%",
                  marginLeft: "5%",
                }}>
                Store Group:
              </p>
              <Select
                value={variationValue?.options[0]?.optionItem || []}
                multiple
                renderValue={(selected) => {
                  console.log("selected:", selected);
                  console.log("variationValue:", variationValue);
                  let selectedStoreGroupNames = [];
                  storeGroups.map((storeG) => {
                    if (selected?.indexOf(storeG.storeGroupId) > -1) {
                      selectedStoreGroupNames.push(storeG?.storeGroupName);
                    }
                  });
                  console.log(
                    "selectedStoreGroupNames:",
                    selectedStoreGroupNames
                  );
                  return selectedStoreGroupNames.join(", ");
                }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  setVariationValue((oldS) => {
                    let newState = { ...oldS };
                    let option = newState.options[0];

                    console.log("value:", value);

                    newState.options[0].optionItem =
                      typeof value === "string" ? value.split(",") : value;

                    // if (option?.optionItem) {
                    //   option?.optionItem += `-${e.target.value}`
                    // } else {}
                    // newState.options[0] = {
                    //   optionId: "storeGroup",
                    //   optionItem: e.target.value,
                    //   optionName: "storeGroup",
                    // };
                    return newState;
                  });
                }}
                label="Option Item"
                style={{
                  width: "50%",
                  marginRight: "5%",
                  height: "100%",
                }}
                // onChange={handleChange}
              >
                {variationValue?.options[0]?.optionItem?.indexOf(
                  "all_stores"
                ) === -1 &&
                  storeGroups &&
                  storeGroups.map((storeGroup, i) => {
                    return (
                      <MenuItem key={i} value={storeGroup.storeGroupId}>
                        <Checkbox
                          checked={
                            variationValue?.options[0]?.optionItem?.indexOf(
                              storeGroup.storeGroupId
                            ) > -1
                          }
                        />
                        <ListItemText primary={storeGroup.storeGroupName} />
                      </MenuItem>
                    );
                  })}
              </Select>

              <div
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h5>All Store Groups:</h5>
                <Checkbox
                  checked={
                    variationValue?.options[0]?.optionItem?.indexOf(
                      "all_stores"
                    ) > -1
                  }
                  onChange={(event) => {
                    setVariationValue((oldS) => {
                      let newState = { ...oldS };
                      if (event.target.checked) {
                        newState.options[0].optionItem = ["all_stores"];
                      } else {
                        newState.options[0].optionItem = [];
                      }

                      return newState;
                    });
                  }}
                />
              </div>

              <span
                style={{
                  position: "absolute",
                  left: "5%",
                  bottom: "5%",
                  fontSize: "13px",
                  padding: "0 10%",
                  textAlign: "center",
                }}>
                Store groups is used to define different prices per store group.
                If you want the same price accross all your stores, select "All
                Stores".
              </span>
            </div>
          </div>
          <div
            style={{
              //   border: "1px solid black",
              borderBottom: "1px dashed gray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "scroll",
              position: "relative",
            }}>
            <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
              <u>Variation Options</u>
            </h4>

            <div
              style={{
                height: "75%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                overflow: "scroll",
              }}>
              {product?.productOptions &&
                product.productOptions.map((opt, i) => {
                  console.log("opt:", opt);

                  let val = variationValue?.options[i + 1]?.optionItem || "";

                  if (open?.type === "Update") {
                    val = variationValue?.options[i + 1]?.optionItem || "any";
                    console.log("val:", val);
                  }

                  if (opt.optionType === "single") {
                    return (
                      <div
                        style={{
                          width: "50%",
                          //   border: "1px solid black",
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        key={i + 1}>
                        <p
                          style={{
                            width: "30%",
                            marginLeft: "5%",
                          }}>
                          {opt.optionName}:
                        </p>

                        <Select
                          value={val}
                          onChange={(e) => {
                            setVariationValue((oldS) => {
                              let newState = { ...oldS };
                              newState.options[i + 1] = {
                                optionId: opt.optionId,
                                optionItem: e.target.value,
                                optionName: opt.optionName,
                              };
                              return newState;
                            });
                          }}
                          label="Option Item"
                          style={{
                            width: "60%",
                            marginRight: "5%",
                            height: "100%",
                          }}
                          // onChange={handleChange}
                        >
                          {opt.optionItems.map((optItem, i2) => {
                            return (
                              <MenuItem key={i2} value={optItem.itemName}>
                                {optItem.itemName}
                              </MenuItem>
                            );
                          })}
                          <MenuItem value="any">Any</MenuItem>
                        </Select>
                      </div>
                    );
                  }
                })}
            </div>

            <span
              style={{
                position: "absolute",
                left: "5%",
                bottom: "5%",
                fontSize: "13px",
                padding: "0 10%",
                textAlign: "center",
              }}>
              Variation options are what your variation is made of. They allow
              for different variants and pricing of your product. E.g a Large
              T-shirt and a Small T-shirt.
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#f7f7f7",
              display: "flex",
              borderBottom: "1px dashed gray",
              position: "relative",
              paddingLeft: "5%",
            }}>
            <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
              <u>Variation Pricing</u>
            </h4>

            <div
              style={{
                // border: "1px solid black",
                flex: 1,
                display: "grid",
                gridTemplateRows: "3fr 1fr",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "space-around",
                  justifyContent: "center",
                }}>
                {samePricing ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                    }}>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        margin: "auto 5%",
                      }}>
                      <h4>Unit Cost:</h4>

                      <TextField
                        value={variationValue.unitCost}
                        onChange={(e) => {
                          setVariationValue((oldS) => {
                            let newState = { ...oldS };
                            newState.unitCost = e.target.value;
                            return newState;
                          });
                        }}
                        size="small"
                        id="unit-cost-inp"
                        label="Unit Cost"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        style={{ width: "90%" }}
                      />
                    </div>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        margin: "auto 5%",
                      }}>
                      <h4>Price:</h4>

                      <TextField
                        value={variationValue.eatInPrice}
                        onChange={(e) => {
                          setVariationValue((oldS) => {
                            let newState = { ...oldS };
                            newState.eatInPrice = e.target.value;
                            newState.takeawayPrice = e.target.value;
                            return newState;
                          });
                        }}
                        size="small"
                        id="price-inp"
                        label="Price"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        style={{ width: "90%" }}
                      />
                    </div>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        margin: "auto 5%",
                      }}>
                      <h4>Same Price:</h4>

                      <SamePricingSwitch />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    }}>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        margin: "auto 5%",
                      }}>
                      <h4>Unit Cost:</h4>

                      <TextField
                        value={variationValue.unitCost}
                        onChange={(e) => {
                          setVariationValue((oldS) => {
                            let newState = { ...oldS };
                            newState.unitCost = e.target.value;
                            return newState;
                          });
                        }}
                        size="small"
                        id="unit-cost-inp"
                        label="Unit Cost"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        style={{ width: "90%" }}
                      />
                    </div>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        margin: "auto 5%",
                      }}>
                      <h4>Eat In Price:</h4>

                      <TextField
                        value={variationValue.eatInPrice}
                        onChange={(e) => {
                          setVariationValue((oldS) => {
                            let newState = { ...oldS };
                            newState.eatInPrice = e.target.value;
                            return newState;
                          });
                        }}
                        size="small"
                        id="base-price-inp"
                        label="Base Price"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        style={{ width: "90%" }}
                      />
                    </div>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        margin: "auto 5%",
                      }}>
                      <h4>Takeaway Price:</h4>

                      <TextField
                        value={variationValue.takeawayPrice}
                        onChange={(e) => {
                          setVariationValue((oldS) => {
                            let newState = { ...oldS };
                            newState.takeawayPrice = e.target.value;
                            return newState;
                          });
                        }}
                        size="small"
                        id="base-price-inp"
                        label="Takeaway Price"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        style={{ width: "90%" }}
                      />
                    </div>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        margin: "auto 5%",
                      }}>
                      <h4>Same Price:</h4>

                      <SamePricingSwitch />
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "5px",
                  padding: "0 15%",
                }}>
                <span
                  style={{
                    fontSize: "13px",
                    textAlign: "center",
                  }}>
                  This is how much your customers will pay for this variation.
                  You can choose to set the same price for eat in and takeaway
                  or separate prices.
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              //   border: "1px solid black",
              display: "flex",
              borderBottom: "1px solid gray",
              position: "relative",
            }}>
            <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
              <u>Variation Stock</u>
            </h4>
            <div
              style={{
                // border: "1px solid black",
                flex: 1,
                display: "grid",
                gridTemplateRows: "3fr 1fr",
                paddingLeft: "15%",
              }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr",
                }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    margin: "auto 5%",
                  }}>
                  <h4>Stock Enabled:</h4>

                  <Switch
                    checked={variationValue?.stockEnabled}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      console.log("checked:", checked);
                      setVariationValue((oldS) => {
                        let newState = { ...oldS };
                        newState.stockEnabled = checked;
                        return newState;
                      });
                    }}
                  />
                </div>

                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    margin: "auto 5%",
                  }}>
                  <h4>SKU:</h4>

                  <TextField
                    value={variationValue.externalSku}
                    size="small"
                    disabled={!variationValue?.stockEnabled}
                    onChange={(e) => {
                      setVariationValue((oldS) => {
                        let newState = { ...oldS };
                        newState.externalSku = e.target.value;
                        return newState;
                      });
                    }}
                    id="sku-inp"
                    label="SKU"
                    variant="outlined"
                    helperText="Leave blank for an auto generated SKU"
                    style={{ width: "90%" }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "5px",
                  padding: "0 15%",
                }}>
                <span
                  style={{
                    fontSize: "13px",
                    textAlign: "center",
                  }}>
                  If your variation is another stock item (E.g a large T-shirt)
                  and you want to keep track of stock for this variation, check
                  the checkbox.
                </span>
              </div>
            </div>
          </div>
        </div>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{ backgroundColor: "#f54842", width: "30%" }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{ width: "40%" }}>
            {open.type} Variation
          </Button>
        </DialogActions>
      </Dialog>

      {product ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}>
          <div className="variations-top">
            <Button
              style={{ margin: "1% 0" }}
              variant="contained"
              onClick={() => {
                if (product?.productOptions?.length > 0) {
                  resetVariationValue();
                  setOpen({ show: true, type: "Add" });
                } else {
                  enqueueSnackbar(
                    "You can not add variations to a product with no options",
                    {
                      variant: "warning",
                    }
                  );
                }
              }}>
              Add Variation &nbsp;
              <AddIcon style={{ fontSize: 20 }} />
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "80%",
              }}>
              <InfoIcon color="info" />
              <p style={{ fontSize: "14px", paddingLeft: "2%" }}>
                The first matching variation will be applied.
              </p>
            </div>
          </div>
          <div className="variations-bottom">
            <DataGrid
              rows={rows}
              columns={columns}
              // pageSize={10}
              // rowsPerPageOptions={[2, 5, 7, 10]}
              isRowSelectable={false}
              onCellClick={(e) => {
                e?.preventDefault();
              }}
              style={{ backgroundColor: "white" }}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
            />
          </div>
        </div>
      ) : (
        <h3>No Product Variations</h3>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .variations-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 0.1;
    width: 100%;
  }
  .variations-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    /* border: 1px solid black; */
    flex: 0.9;
  }
  .variation-details-row {
    /* border: 1px solid row; */
    width: 100%;
    display: flex;
  }
`;

export default ViewVariations;
