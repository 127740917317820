import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { deleteProduct } from "../helpers/helpers";
import { useSnackbar } from "notistack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "product_category",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "product",
    numeric: false,
    disablePadding: false,
    label: "Product Tags",
  },
  {
    id: "stockEnabled",
    numeric: false,
    disablePadding: true,
    label: "Stock Enabled",
  },
  {
    id: "eatInPrice",
    numeric: false,
    disablePadding: false,
    label: "Eat In price",
  },
  {
    id: "takeawayPrice",
    numeric: false,
    disablePadding: false,
    label: "Takeaway Price",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div">
        Products
      </Typography>
    </Toolbar>
  );
};

const EnhancedTable = ({
  rows,
  setProducts,
  setIsLoading,
  setProductInUseDialog,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("price");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const deleteSelectedProduct = async (productId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this product",
        "Make sure this product is not in use before you delete it"
      )
    ) {
      setIsLoading(true);

      const hasDeletedResp = await deleteProduct(productId, enqueueSnackbar);
      if (hasDeletedResp) {
        if (hasDeletedResp?.productInUseByEposGroup) {
          setProductInUseDialog({
            show: true,
            data: hasDeletedResp?.eposGroupsList,
            type: "productInUseByEposGroup",
          });
          enqueueSnackbar(
            `This product is currently in use by ${hasDeletedResp?.eposGroupsList?.length} epos group(s) and can not be deleted`,
            {
              variant: "warning",
            }
          );
        }

        if (hasDeletedResp?.productInUseByStore) {
          setProductInUseDialog({
            show: true,
            data: hasDeletedResp?.storeStockList,
            type: "productInUseByStore",
          });
          enqueueSnackbar(
            `This product is currently in use by ${hasDeletedResp?.storeStockList?.length} store(s) and can not be deleted`,
            {
              variant: "warning",
            }
          );
        }
        if (hasDeletedResp?.productDeleted) {
          enqueueSnackbar("Successfully deleted product", {
            variant: "success",
          });
          setProducts((oldS) => {
            let newState = [...oldS];
            newState = oldS.filter((v) => v?.productId !== productId);
            return newState;
          });
        }
      }
      setIsLoading(false);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  console.log("row:", row);
                  return (
                    <TableRow
                      hover
                      role="button"
                      tabIndex={-1}
                      key={row.productId}>
                      <TableCell />
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none">
                        {row.productName}
                      </TableCell>
                      <TableCell scope="row">
                        {row?.tags?.length > 0
                          ? row.tags.map((tag, i) => {
                              if (i !== row?.tags?.length - 1) {
                                return `${tag}, `;
                              } else {
                                return tag;
                              }
                            })
                          : "N/A"}
                      </TableCell>
                      <TableCell scope="row" align="left">
                        {row?.stockEnabled ? (
                          <CheckCircleIcon />
                        ) : (
                          <CancelIcon />
                        )}
                      </TableCell>
                      <TableCell scope="row">
                        £{(row.eatInPrice / 100).toFixed(2)}
                      </TableCell>
                      <TableCell scope="row">
                        £{(row.takeawayPrice / 100).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => navigate(`${row.productId}`)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => {
                              deleteSelectedProduct(row.productId);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
};

export default EnhancedTable;
