import React from "react";
import styled from "styled-components";
import { useAdmin } from "../../context/admin_context";
import Navigation from "../navigation/Navigation";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import { Select, Toolbar, MenuItem } from "@mui/material";
import LocationComp from "./LocationComp";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const Locations = () => {
  const { locations, isLoading } = useAdmin();
  console.log("locations in resiet:", locations);

  let navigate = useNavigate();
  return (
    <Wrapper style={{ backgroundColor: colors.backgroundColor }}>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <div
          className="add-button"
          onClick={() => {
            navigate(`/locations/addlocation`);
          }}>
          <AddIcon style={{ color: "white" }} />
        </div>
        <div className="grid-container">
          {locations?.length > 0 &&
            locations.map((loc, i) => {
              if (loc?.locationId?.toLowerCase() === "head-office") return;
              return <LocationComp key={i} loc={loc} />;
            })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // height: 10vh;
  display: flex;
  width: 100%;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
    /* height: 100vh; */
    /* boredr: 1px solid red; */
  }

  .grid-container {
    padding: 4% 2%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2% 1.1%;
    /* border: 1px solid red; */
  }

  .grid-item {
    height: 45vh;
    width: 25vw;
    /* border: 1px solid red; */
    border-radius: 10px;
    background-color: white;
  }

  .add-button {
    z-index: 999;
    position: fixed;
    top: 85%;
    right: 5%;
    background-color: rgb(56, 116, 203);
    border-radius: 100px;
    width: 7vh;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-button:hover {
    cursor: pointer;
  }
`;

export default Locations;
