import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, CardContent } from "@mui/material";

const VATBreakdownTable = ({ taxData }) => {
  console.log(taxData);
  return (
    <Card sx={{ width: "98%", margin: "0 1% 1% 1%;" }}>
      <CardContent style={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>VAT Rate</TableCell>
                <TableCell>Sales</TableCell>
                <TableCell>VAT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taxData.map((taxD) => (
                <TableRow
                  key={taxD.tax_rate}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {taxD.tax_rate === "total" ? "Total" : `${taxD.tax_rate}%`}
                  </TableCell>
                  <TableCell>£{taxD.total_price.toFixed(2)}</TableCell>
                  <TableCell>£{taxD.total_tax}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default VATBreakdownTable;
