import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Paper,
  TableContainer,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
const DevicesTable = ({ devices, removeDeviceFn }) => {
  const confirmUnregisterDevice = async (deviceToUnregister) => {
    if (
      window.confirm(
        "Are you sure you want to DELETE this device and all it's data"
      )
    ) {
      removeDeviceFn(deviceToUnregister);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Device Name</TableCell>
            <TableCell>Device ID</TableCell>
            <TableCell>Location ID</TableCell>
            <TableCell>Manufacturer</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map((device, i) => {
            return (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                <TableCell component="th" scope="row">
                  {device.device_name || "N/A"}
                </TableCell>
                <TableCell>{device.device_id}</TableCell>
                <TableCell>{device.location_id || "N/A"}</TableCell>
                <TableCell>{device.manufacturer || "N/A"}</TableCell>
                <TableCell align="right">
                  {device.location_id && device.device_name && (
                    <IconButton color="primary">
                      <VisibilityIcon />
                    </IconButton>
                  )}
                  {device.location_id && device.device_name && (
                    <IconButton
                      onClick={() => confirmUnregisterDevice(device)}
                      color="error">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevicesTable;
