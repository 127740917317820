import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Button,
  Toolbar,
  Select,
  Divider,
  MenuItem,
  TextField,
  FormControl,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  DialogActions,
  TableBody,
  InputAdornment,
  InputLabel,
  FormHelperText,
  Menu,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  addProduct,
  fetchProducts,
  getTaxGroups,
  validateNewProductFields,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import EnhancedTable from "../../components/Table";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { ButtonGroup } from "rsuite";
import { useSnackbar } from "notistack";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

const initialState = {
  productName: "",
  eatInPrice: 0,
  takeawayPrice: 0,
  unitCost: 0,
  productDescription: "",
  stockEnabled: false,
  externalSku: "",
  attributes: [{ name: "product_category", value: "" }],
  eatInTaxGroup: "",
  takeawayTaxGroup: "",
  tags: [],
};

const Products = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [addProductScreen, setAddProductScreen] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [taxLoading, setIsTaxLoading] = useState(true);
  const [filterValue, setFilterValue] = useState("");

  const [taxGroups, setTaxGroups] = useState(null);
  const [formValue, setFormValue] = useState(initialState);
  const [samePrice, setSamePrice] = useState(true);
  const [productInUseDialog, setProductInUseDialog] = useState({
    show: false,
    data: null,
    type: "",
  });
  const [filterBy, setFilterBy] = useState({
    id: "productName",
    title: "Name",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (filterValue?.length > 0) {
      const filteredData = products.filter((prod) =>
        prod[filterBy.id]?.toLowerCase()?.includes(filterValue.toLowerCase())
      );
      setFilteredProducts(filteredData);
    } else {
      setFilteredProducts(null);
    }
  }, [filterValue]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();
  const fetchProductsHelper = () => {
    fetchProducts(setProducts, enqueueSnackbar, setIsLoading);
  };

  const SamePriceSwitching = () => {
    return (
      <Switch
        checked={samePrice}
        onChange={() => {
          console.log("!samePrice:", !samePrice);
          if (!samePrice) {
            setFormValue((oldS) => {
              let newState = { ...oldS };
              newState.takeawayPrice = 0;
              newState.eatInPrice = 0;
              return newState;
            });
          }
          setSamePrice((oldS) => !oldS);
        }}
      />
    );
  };

  useEffect(() => {
    fetchProductsHelper();
    getTaxGroups(setTaxGroups, setIsTaxLoading, enqueueSnackbar);
  }, []);

  const addProductHelper = async (formValueCopy) => {
    const isValid = validateNewProductFields(formValueCopy, enqueueSnackbar);
    if (isValid) {
      setIsLoading(true);
      const productResp = await addProduct(formValueCopy, enqueueSnackbar);
      if (productResp) {
        if (productResp?.productAdded && productResp?.product) {
          enqueueSnackbar(`Successfully added product`, { variant: "success" });
          setProducts((oldS) => {
            let newState = [...oldS, productResp?.product];
            return newState;
          });
          setFormValue(initialState);
          setAddProductScreen(false);
        }

        if (productResp?.externalSkuTaken) {
          enqueueSnackbar(
            `The SKU you entered is in use by another product. Please enter a different SKU`,
            {
              variant: "error",
            }
          );
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <Dialog
        style={{ width: "50%", margin: "0 auto" }}
        fullWidth
        maxWidth
        open={productInUseDialog.show}
        onClose={() => {
          setProductInUseDialog({ show: false, data: null, type: "" });
        }}>
        <DialogTitle>Product In Use</DialogTitle>
        <div
          style={{
            // border: "1px solid black",
            borderTop: "1px solid gray",
            height: "60vh",
            width: "100%",
            padding: "10% 10% 0 10%",
            borderBottom: "1px dashed gray",
            backgroundColor: "#f7f7f7",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "center",
            overflow: "scroll",
            position: "relative",
          }}>
          {productInUseDialog.type === "productInUseByEposGroup" ? (
            <>
              <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
                <u>Epos Groups Using this product</u>
              </h4>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Epos Group Name</TableCell>
                      <TableCell align="left">
                        Instances of product in epos group
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productInUseDialog?.data &&
                      productInUseDialog.data.length > 0 &&
                      productInUseDialog.data.map((data, i) => {
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}>
                            <TableCell component="th" scope="row">
                              {data?.epos_group_name}
                            </TableCell>
                            <TableCell align="left">
                              {data?.product_in_epos_group_count}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <span
                style={{
                  position: "absolute",
                  left: "5%",
                  bottom: "5%",
                  fontSize: "13px",
                  padding: "0 10%",
                  textAlign: "center",
                }}>
                To delete this product, remove all instances of this product
                from the epos groups listed above
              </span>
            </>
          ) : (
            <>
              <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
                <u>Stores Using this product</u>
              </h4>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item Name</TableCell>
                      <TableCell align="right">Store ID</TableCell>
                      <TableCell align="right">PLU</TableCell>
                      <TableCell align="right">SKU</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productInUseDialog?.data &&
                      productInUseDialog.data.length > 0 &&
                      productInUseDialog.data.map((data, i) => {
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}>
                            <TableCell component="th" scope="row">
                              {data?.itemName}
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              {data?.locationId}
                            </TableCell>
                            <TableCell align="right"> {data?.plu}</TableCell>
                            <TableCell align="right"> {data?.sku}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <span
                style={{
                  position: "absolute",
                  left: "5%",
                  bottom: "5%",
                  fontSize: "13px",
                  padding: "0 10%",
                  textAlign: "center",
                }}>
                To delete this product, delete these instances from each store
                which stocks the product using the admin app
              </span>
            </>
          )}
        </div>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setProductInUseDialog({ show: false, data: null, type: "" });
            }}
            style={{ width: "30%" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <IconButton
              onClick={fetchProductsHelper}
              style={{ position: "absolute", right: "15%" }}
              aria-label="expand row">
              <RefreshIcon style={{ fontSize: 30 }} />
            </IconButton>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        {addProductScreen ? (
          <div className="content-container">
            <div className="form-container">
              <div className="header-container-add">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#777",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    setFormValue(initialState);
                    setAddProductScreen(false);
                  }}>
                  Go Back
                </Button>
                <h2>New Product:</h2>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFormValue(initialState);
                  }}>
                  Reset
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    let formValueCopy = { ...formValue };
                    formValueCopy.eatInPrice = Math.round(
                      formValueCopy.eatInPrice * 100
                    );
                    formValueCopy.takeawayPrice = Math.round(
                      formValueCopy.takeawayPrice * 100
                    );
                    formValueCopy.unitCost = Math.round(
                      formValueCopy.unitCost * 100
                    );

                    console.log("formValueCopy:", formValueCopy);

                    addProductHelper(formValueCopy);
                  }}>
                  Submit
                </Button>
              </div>
              <Divider />

              <div className="data-container">
                <div className="item-container">
                  <h3>Product Information:</h3>
                  <div className="data-row">
                    <div className="data-col">
                      <h3 className="row-title">Product Name:</h3>
                    </div>
                    <div className="data-col">
                      <TextField
                        required
                        value={formValue.productName}
                        onChange={(e) => {
                          setFormValue((oldS) => {
                            let newState = { ...oldS };
                            newState.productName = e.target.value;
                            return newState;
                          });
                        }}
                        style={{ width: "90%" }}
                        label="Name"
                        variant="outlined"
                        type="search"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="data-col">
                      <h3 className="row-title"> Product Description:</h3>
                    </div>
                    <div className="data-col">
                      <TextField
                        value={formValue.productDescription}
                        onChange={(e) => {
                          setFormValue((oldS) => {
                            let newState = { ...oldS };
                            newState.productDescription = e.target.value;
                            return newState;
                          });
                        }}
                        multiline
                        style={{ width: "90%" }}
                        label="Description"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
                <Divider />

                <div className="item-container">
                  <h3>Product Pricing:</h3>
                  {samePrice ? (
                    <>
                      <div className="data-row">
                        <div className="data-col">
                          <h3 className="row-title">Price:</h3>
                        </div>
                        <div className="data-col">
                          <TextField
                            required
                            value={formValue.eatInPrice}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.eatInPrice = e.target.value;
                                newState.takeawayPrice = e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            label="Price"
                            variant="outlined"
                            type="number"
                            autoComplete="off"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="data-col">
                          <h3 className="row-title">Same Price:</h3>
                        </div>
                        <div className="data-col">
                          <SamePriceSwitching />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="data-row">
                        <div className="data-col">
                          <h3 className="row-title">Eat In Price:</h3>
                        </div>
                        <div className="data-col">
                          <TextField
                            required
                            value={formValue.eatInPrice}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.eatInPrice = e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            label="Price"
                            variant="outlined"
                            type="number"
                            autoComplete="off"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>

                      <div className="data-row">
                        <div className="data-col">
                          <h3 className="row-title">Takeaway Price:</h3>
                        </div>
                        <div className="data-col">
                          <TextField
                            required
                            value={formValue.takeawayPrice}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.takeawayPrice = e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            label="Price"
                            variant="outlined"
                            type="number"
                            autoComplete="off"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="data-col">
                          <h3 className="row-title">Same Price:</h3>
                        </div>
                        <div className="data-col">
                          <SamePriceSwitching />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="data-row">
                    <div className="data-col">
                      <h3 className="row-title">Unit Cost:</h3>
                    </div>
                    <div className="data-col">
                      <TextField
                        required
                        value={formValue.unitCost}
                        onChange={(e) => {
                          setFormValue((oldS) => {
                            let newState = { ...oldS };
                            newState.unitCost = e.target.value;
                            return newState;
                          });
                        }}
                        style={{ width: "90%" }}
                        label="Unit Cost"
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <Divider />

                <div className="item-container">
                  <h3>Product Stock:</h3>

                  <div className="data-row">
                    <div className="data-col">
                      <h3 className="row-title">Stock Enabled:</h3>
                    </div>
                    <div className="data-col">
                      <Switch
                        value={formValue?.stockEnabled}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setFormValue((oldS) => {
                            let newState = { ...oldS };
                            newState.stockEnabled = checked;
                            return newState;
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="data-col">
                      <h3 className="row-title">SKU:</h3>
                    </div>
                    <div className="data-col">
                      <TextField
                        disabled={!formValue.stockEnabled}
                        value={formValue.externalSku}
                        onChange={(e) => {
                          setFormValue((oldS) => {
                            let newState = { ...oldS };
                            newState.externalSku = e.target.value;
                            return newState;
                          });
                        }}
                        style={{ width: "90%" }}
                        label="SKU"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        helperText="Leave blank for an auto generated SKU"
                      />
                    </div>
                  </div>
                </div>
                <Divider />

                <div className="item-container">
                  <h3>Product Attributes:</h3>
                  {formValue.attributes.map((attr, i) => {
                    if (attr?.name === "product_category") {
                      return (
                        <div className="data-row">
                          <div
                            className="data-col"
                            style={{ justifyContent: "space-around" }}>
                            <h4>Product Category Attribute:</h4>
                          </div>
                          <div className="data-col">
                            <FormControl style={{ width: "90%" }} >
                              <InputLabel required>Product Category</InputLabel>
                              <Select
                                required
                                value={formValue.attributes[i].value}
                                label="Product Category"
                                onChange={(e) => {
                                  setFormValue((oldS) => {
                                    let newState = { ...oldS };
                                    newState.attributes[i].value =
                                      e.target.value;
                                    return newState;
                                  });
                                }}>
                                <MenuItem value={"food"}>Food</MenuItem>
                                <MenuItem value={"drink"}>Drink</MenuItem>
                                <MenuItem value={"retail"}>Retail</MenuItem>
                                <MenuItem value={"deals"}>Deals</MenuItem>
                              </Select>
                              <FormHelperText>
                                This Attribute is used to apply discounts and
                                for product reporting.
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="data-row">
                          <div
                            className="data-col"
                            style={{ justifyContent: "space-around" }}>
                            <button
                              onClick={() => {
                                setFormValue((oldS) => {
                                  let newState = { ...oldS };
                                  let productAttributes = [
                                    ...newState.attributes,
                                  ];
                                  productAttributes.splice(i, 1);
                                  newState.attributes = productAttributes;
                                  return newState;
                                });
                              }}
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}>
                              <RemoveCircleIcon style={{ color: "#f54242" }} />
                            </button>

                            <h4>Attribute {i + 1}:</h4>
                          </div>
                          <div className="data-col">
                            <TextField
                              value={formValue.attributes[i].name}
                              onChange={(e) => {
                                setFormValue((oldS) => {
                                  let newState = { ...oldS };
                                  newState.attributes[i].name = e.target.value;
                                  return newState;
                                });
                              }}
                              style={{ width: "40%", marginRight: "5%" }}
                              id="outlined-basic"
                              label="Attribute Name"
                              variant="outlined"
                            />
                            <TextField
                              value={formValue.attributes[i].value}
                              onChange={(e) => {
                                setFormValue((oldS) => {
                                  let newState = { ...oldS };
                                  newState.attributes[i].value = e.target.value;
                                  return newState;
                                });
                              }}
                              style={{ width: "40%" }}
                              id="outlined-basic"
                              label="Attribute Value"
                              variant="outlined"
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setFormValue((oldS) => {
                        let newState = { ...oldS };
                        newState.attributes.push({ name: "", value: "" });
                        console.log("newState:", newState);
                        return newState;
                      });
                    }}>
                    Add Attribute
                  </Button>
                </div>
                <Divider />

                <div className="item-container">
                  <h3>Product Tax:</h3>
                  <div className="data-row">
                    <div className="data-col">
                      <h3 className="row-title">Eat in tax group:</h3>
                    </div>

                    <div className="data-col">
                      <FormControl style={{ width: "90%" }}>
                        <InputLabel
                          required
                          id="demo-simple-select-helper-label">
                          Tax Group
                        </InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValue.eatInTaxGroup}
                          label="Tax Rate"
                          onChange={(event) => {
                            console.log("event:", event);
                            setFormValue((oldS) => {
                              let newState = { ...oldS };
                              newState.eatInTaxGroup = event.target.value;
                              return newState;
                            });
                          }}>
                          {taxGroups &&
                            taxGroups.map((group, i) => {
                              return (
                                <MenuItem value={group?.taxId}>
                                  {group?.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="data-col">
                      <h3 className="row-title">Takeaway tax group:</h3>
                    </div>

                    <div className="data-col">
                      <FormControl style={{ width: "90%" }}>
                        <InputLabel
                          required
                          id="demo-simple-select-helper-label">
                          Tax Group
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper-label"
                          value={formValue.takeawayTaxGroup}
                          label="Tax Rate"
                          onChange={(event) => {
                            console.log("event:", event);
                            setFormValue((oldS) => {
                              let newState = { ...oldS };
                              newState.takeawayTaxGroup = event.target.value;
                              return newState;
                            });
                          }}>
                          {taxGroups &&
                            taxGroups.map((group, i) => {
                              return (
                                <MenuItem value={group?.taxId}>
                                  {group?.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <Divider />
                <div className="item-container">
                  <h3>Product Tags:</h3>
                  {formValue.tags.map((tag, i) => {
                    return (
                      <div className="data-row">
                        <div
                          className="data-col"
                          style={{ justifyContent: "space-around" }}>
                          <button
                            onClick={() => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                let itemOptions = [...newState.tags];
                                itemOptions.splice(i, 1);
                                newState.tags = itemOptions;
                                return newState;
                              });
                            }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}>
                            <RemoveCircleIcon style={{ color: "#f54242" }} />
                          </button>

                          <h4>Tag {i + 1}:</h4>
                        </div>
                        <div className="data-col">
                          <TextField
                            value={formValue.tags[i]}
                            onChange={(e) => {
                              setFormValue((oldS) => {
                                let newState = { ...oldS };
                                newState.tags[i] = e.target.value;
                                return newState;
                              });
                            }}
                            style={{ width: "90%" }}
                            id="outlined-basic"
                            label="Tag"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setFormValue((oldS) => {
                        let newState = { ...oldS };
                        newState.tags.push("");
                        console.log("newState:", newState);
                        return newState;
                      });
                    }}>
                    Add Tag
                  </Button>
                </div>
                <Divider />
              </div>
            </div>
          </div>
        ) : (
          <div className="content-container">
            <div className="add-btn-container">
              <Button
                style={{ margin: "2% 0" }}
                variant="contained"
                component="span"
                onClick={() => setAddProductScreen(true)}>
                Add New product
              </Button>
            </div>

            {products && products?.length > 0 ? (
              <div className="products-container">
                <div className="products-container-top">
                  <TextField
                    style={{ width: "100%" }}
                    label="Filter"
                    id="outlined-start-adornment"
                    sx={{ m: 1, width: "25ch" }}
                    value={filterValue}
                    variant="filled"
                    onChange={(e) => {
                      const text = e.target.value;
                      setFilterValue(text);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {filterBy?.title}:
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            className="filter-icon-container"
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                            }}>
                            <span style={{ fontSize: "10px", color: "#888" }}>
                              Filter By
                            </span>
                            <FilterListIcon fontSize={"30px"} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Menu
                    id="filter-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "filter-select-button",
                    }}>
                    <MenuItem
                      onClick={() => {
                        setFilterBy({ id: "productId", title: "Product ID" });
                        handleClose();
                      }}>
                      Product ID
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setFilterBy({
                          id: "productName",
                          title: "Product Name",
                        });
                        handleClose();
                      }}>
                      Product Name
                    </MenuItem>
                  </Menu>
                </div>
                <div className="products-container-bottom">
                  {filteredProducts ? (
                    <EnhancedTable
                      rows={filteredProducts}
                      setProducts={setFilteredProducts}
                      setIsLoading={setIsLoading}
                      setProductInUseDialog={setProductInUseDialog}
                    />
                  ) : (
                    <EnhancedTable
                      rows={products}
                      setProducts={setProducts}
                      setIsLoading={setIsLoading}
                      setProductInUseDialog={setProductInUseDialog}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="products-container">
                <h2 style={{ textAlign: "center" }}>No Products Found</h2>
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;

    /* height: 90vh; */
    /* height: 80%; */
    overflow: scroll;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid red; */
  }

  .products-container {
    width: 100%;

    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 2% 2% 2%;
  }

  .products-container-top {
    width: 100%;
    display: flex;
    flex: 0.1;
    justify-content: flex-start;
    align-items: center;
  }
  .products-container-bottom {
    flex: 0.9;
    /* border: 1px solid black; */
    width: 100%;
    overflow: scroll;
    padding: 1%;
  }

  .add-btn-container {
    display: flex;
    /* border: 1px solid black; */
    height: 10%;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 10%;
  }

  .form-container {
    background-color: white;
    border-radius: 10px;
    /* border: 1px solid black; */
    width: 90%;
    height: 95%;
    margin: 2.5% 5%;
    overflow-y: scroll;
  }

  .header-container-add {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20%;
  }

  .data-container {
    width: 100%;
    height: 90%;
    text-align: center;
    /* border: 1px solid black; */
    padding: 0 2%;
    /* display: flex; */
    /* background-color: #f9f9f9; */
    /* justify-content: space-around;
    align-items: center;
    justify-content: center; */
  }
  .item-container {
    /* border: 1px solid black; */
    margin: 2.5% 0;
  }
  .data-row {
    width: 100%;
    display: flex;
    margin: 2% 0;
  }
  .data-col {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row-title {
    width: 100%;
    text-align: left;
    padding-left: 20%;
  }
  .filter-icon-container {
    display: flex;
    flex-direction: column;
    margin-right: 10%;
  }
`;

export default Products;
