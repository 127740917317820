import React, { useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Collapse,
  Box,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  let navigate = useNavigate();
  return (
    <Wrapper>
      <div className="top">
        <h1>Naked Coffee Admin Portal</h1>
      </div>
      <div className="bottom">
        <Button variant="contained" onClick={() => navigate("/login")}>
          Login
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  border: 1px solid black;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`;

export default HomePage;
