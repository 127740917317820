import {
  SET_USER,
  LOGOUT_USER,
  REFRESH_CONTEXT,
  LOGIN_STORE,
  LOGOUT_STORE,
  UPDATE_USER,
  LOCK_TILL,
} from "../actions";

const authReducer = (state, action) => {
  // console.log(
  //   'authReducer state=',
  //   state,
  //   'action=',
  //   action,
  //   'action.payload=',
  //   action.payload,
  // );

  switch (action.type) {
    case SET_USER:
      // uid, name, email, isAdmin, database
      const payload = action?.payload;

      const newState = {
        acceptedInvitation: payload?.acceptedInvitation,
        name: payload?.name,
        email: payload?.email,
        emailVerified: payload?.emailVerified,
        createdAt: payload?.createdAt,
        updatedAt: payload?.updatedAt,
        deviceTokens: payload?.deviceTokens,
        uid: payload?.uid,
        token: payload?.token,
        firebaseToken: payload?.firebaseToken,
        claims: payload?.claims,
      };

      // console.log("authReducer SET_USER returning ", newState);
      return newState;

    case UPDATE_USER:
      console.log("action?.payload?", action?.payload);

      const updatedStateObj = {
        uid: action?.payload?.uid || "",
        name: action?.payload?.name || "",
        email: action?.payload?.email || "",
        isAdmin: action?.payload?.is_admin,
        isStaff: action?.payload?.is_staff,
        emailVerified: action?.payload?.email_verified || false,
        auth: state?.auth,
        storeId: state?.storeId || "",
      };

      console.log("authReducer UPDATE_USER returning ", updatedStateObj);

      return updatedStateObj;

    case LOGIN_STORE:
      // uid, name, email, isAdmin, database

      const newStoreState = {
        storeName: action?.payload?.storeName,
        storeId: action?.payload?.storeId,
        deviceId: action?.payload?.deviceId,
        storeToken: action?.payload?.storeToken,
      };

      console.log("authReducer LOGIN_STORE returning ", newStoreState);
      return newStoreState;

    // case UPDATE_USER_DETAILS:
    //   const updatedState = state;
    //   updatedState[action.payload.itemToUpdate] = action.payload.UpdatedValue;
    //   console.log("updatedState:", updatedState);
    //   console.log("authReducer UPDATE_USER_DETAILS returning ", updatedState);

    //   return;

    case REFRESH_CONTEXT:
      const updatedState = { ...state };
      console.log(action.payload);
      updatedState[action.payload.itemToUpdate] = action.payload.updatedValue;
      console.log(updatedState);
      return updatedState;

    case LOGOUT_USER:
      return {};

    case LOGOUT_STORE:
      return {};

    case LOCK_TILL:
      return { is_till_locked: true };

    default:
      throw new Error(`No matching action type ${action.type}`);
  }
};

export default authReducer;
