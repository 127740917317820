import React, { useState } from "react";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { generateReadableSkuString } from "../../helpers/helpers";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const WarehouseTransferList = ({
  selectedSkus,
  setSelectedSkus,
  globalSkus,
  setGlobalSkus,
  globalSkusData,
  skuStockData,
  setSkuStockData,
}) => {
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, globalSkus);
  const rightChecked = intersection(checked, selectedSkus);

  const handleToggle = (value) => () => {
    console.log("value:", value);

    // setSkuStockData((oldS) => {
    //   let newState = { ...oldS };
    //   if (value in newState) {
    //     delete newState[value];
    //   } else {
    //     newState[value] = { stockLevel: 0, warningLevel: 0 };
    //   }
    //   delete newState[value];
    //   return newState;
    // });

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setSelectedSkus(selectedSkus.concat(leftChecked));
    setGlobalSkus(not(globalSkus, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setGlobalSkus(globalSkus.concat(rightChecked));
    setSelectedSkus(not(selectedSkus, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          console.log("akldf:", globalSkusData[value]);
          const skuData = globalSkusData[value];
          let string = generateReadableSkuString(skuData);
          console.log("string:", string);
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}>
                {/* <ListItemIcon> */}
                <Checkbox
                  onClick={handleToggle(value)}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
                {/* </ListItemIcon> */}
                <ListItemText
                  id={labelId}
                  primary={`${string}`}
                  secondary={value}
                />
              </div>

              {selectedSkus.includes(value) && (
                <div style={{ width: "100%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Stock Level"
                    variant="outlined"
                    type="number"
                    value={skuStockData[value]?.stockLevel}
                    onChange={(e) => {
                      let text = e.target.value;
                      setSkuStockData((oldS) => {
                        let newState = { ...oldS };
                        console.log("newState:", newState);
                        console.log("text:", text);
                        if (value in newState) {
                          newState[value].stockLevel = +text;
                        } else {
                          newState = {
                            ...newState,
                            [value]: { stockLevel: +text },
                          };
                        }

                        return newState;
                      });
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Warning Level"
                    variant="outlined"
                    type="number"
                    value={skuStockData[value]?.warningLevel}
                    onChange={(e) => {
                      let text = e.target.value;
                      setSkuStockData((oldS) => {
                        let newState = { ...oldS };
                        if (value in newState) {
                          newState[value].warningLevel = +text;
                        } else {
                          newState = {
                            ...newState,
                            [value]: { warningLevel: +text },
                          };
                        }
                        return newState;
                      });
                    }}
                  />
                </div>
              )}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Wrapper>
      <div className="container">
        <div>{customList("Choices", globalSkus)}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected selectedSkus">
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected globalSkus">
            &lt;
          </Button>
        </div>
        <div item>{customList("Chosen", selectedSkus)}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .container {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
  }
  .transfer-buttons: {
    display: flex;
    border: 1px solid red;
  }
`;

export default WarehouseTransferList;
