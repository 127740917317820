import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
const Error = ({ homeLink }) => {
  let navigate = useNavigate();
  return (
    <Wrapper>
      <h2
        style={{
          textTransform: "lowercase",
        }}>
        404 Error Page not found
      </h2>
      <div
        onClick={() => navigate("/")}
        to={homeLink}
        className="error-page-btn">
        Back Home
      </div>
      {/* <p>Location {useLocation().pathname} base name is {process.env.REACT_APP_BASENAME}</p> */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: #f7f7f7;

  h2 {
    font-size: 3rem;
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 2rem;
    }
  }

  .error-page-btn {
    text-decoration: none;
    text-transform: lowercase;
    padding: 1rem 2rem;
    background-color: white;
    border-radius: 2rem;
    color: #ed1e79;
    border: 1px solid #ed1e79;
    font-size: 1rem;
    border-radius: 2rem;
    padding: 1rem 2rem;
    transition: all 0.3s;
  }

  .error-page-btn:hover {
    transform: translateY(3px) scale(0.97);
    color: white;
    background-color: #ed1e79;
  }
`;

export default Error;
