import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Button,
  ListItemButton,
  ListItemText,
  List,
  ListItem,
  Toolbar,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import {
  fetchEposGroups,
  fetchProducts,
  fetchSingleCategory,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ViewOptions from "../../components/ViewOptions";
import ViewProducts from "../../components/ViewProducts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

const SingleCategory = ({ categoryId }) => {
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   let { categoryId } = useParams();

  const { locations } = useAdmin();

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    fetchSingleCategory(categoryId, setCategory, setIsLoading);
  }, [categoryId]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  return (
    <Wrapper>
      {categoryId ? (
        <>
          {category ? (
            <div className="tab-container">
              <div className="tab-container-top">
                <h2>{category?.name}</h2>
                <Tabs
                  style={{
                    boxShadow: "0px 15px 15px -1px rgba(0,0,0,0.06)",
                    borderBottom: "1px solid #ccc",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  centered
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example">
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Products" {...a11yProps(1)} />
                  <Tab label="Copy Product" {...a11yProps(2)} />
                </Tabs>
              </div>
              <div className="tab-container-bottom">
                <TabPanel value={value} index={0} style={{ height: "100%" }}>
                  <div className="category-info-container">
                    <TextField
                      id="outlined-basic"
                      label="Category Name"
                      variant="outlined"
                      defaultValue={category?.name || ""}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Category ID"
                      variant="outlined"
                      defaultValue={category?.product_id || ""}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Category Name"
                      variant="outlined"
                      defaultValue={category?.name || ""}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Base Price"
                      variant="outlined"
                      defaultValue={category?.base_price || ""}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} style={{ height: "100%" }}>
                  <ViewProducts />
                </TabPanel>
              </div>
            </div>
          ) : (
            <h3>Something went wrong trying to get category</h3>
          )}
        </>
      ) : (
        <h3>Select a category from tree</h3>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  /* border: 1px solid black; */
  align-items: center;
  justify-content: center;

  .tab-container {
    /* border: 1px solid black; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .tab-container-top {
    /* border: 1px solid black; */
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .tab-container-bottom {
    height: 85%;
    width: 100%;
    background-color: #f7f7f7;

    /* display: flex; */
  }

  .category-info-container {
    height: 100%;
    width: 90%;
    margin: 0 5%;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`;

export default SingleCategory;
