import React, { useState, useEffect } from "react";
import { Toolbar } from "@mui/material";
import styled from "styled-components";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Navigation from "../navigation/Navigation";
import { getRegisteredDevices, unregisterDevice } from "../../helpers/helpers";
import { useSnackbar } from "notistack";
import DevicesTable from "./DevicesTable";
import Loader from "../../components/Loader";

const RegisteredDevices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [registeredDevices, setRegisteredDevices] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const getRegisteredDevicesHelper = async () => {
    setIsLoading(true);
    const resp = await getRegisteredDevices(enqueueSnackbar);
    if (resp) {
      setRegisteredDevices(resp);

      setIsLoading(false);
    }
  };

  const removeDeviceFn = async (deviceToUnregister) => {
    setIsLoading(true);
    const resp = await unregisterDevice(deviceToUnregister, enqueueSnackbar);
    if (resp) {
      setRegisteredDevices((oldS) => {
        let newState = oldS.filter(
          (d) => d?.device_id !== deviceToUnregister?.device_id
        );
        return newState;
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getRegisteredDevicesHelper();
  }, []);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
          </Toolbar>
        </div>
        <div className="devices-container">
          <h2 style={{ marginBottom: "2%" }}>
            <u>Registered Devices</u>
          </h2>

          {registeredDevices ? (
            <DevicesTable
              devices={registeredDevices}
              removeDeviceFn={removeDeviceFn}
            />
          ) : (
            <h3>No registered devices</h3>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;

    height: 90vh;
  }

  .devices-container {
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
export default RegisteredDevices;
