import { useState, useEffect } from "react";
import ScreenRoutes from "./routes";
import { AuthProvider } from "./context/auth_context";
import { initialiseUser, loginUserByToken } from "./helpers/helpers";
import { LinearProgress } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  const [loggedInUser, setLoggedInUser] = useState({});
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    (async () => {
      window.localStorage.setItem("show_ec_logs", "false");
      const resp = await loginUserByToken();
      console.log("resp", resp);

      const newUserAuthToken = resp.token;
      console.log("newUserToken:", newUserAuthToken);
      if (newUserAuthToken) {
        window.localStorage.setItem("auth_token", newUserAuthToken);
      }

      const initialLoggedInUser = await initialiseUser(
        resp?.acceptedInvitation,
        resp?.name,
        resp?.email,
        resp?.emailVerified,
        resp?.createdAt,
        resp?.updatedAt,
        resp?.deviceTokens,
        resp?.uid,
        resp?.token,
        resp?.firebaseToken,
        resp?.claims
      );

      console.log("Setting initialLoggedInUser to ", initialLoggedInUser);
      setLoggedInUser(initialLoggedInUser);
      setIsAppReady(true);
    })();
  }, []);

  if (!isAppReady) {
    return (
      <div
        style={{
          width: "80vw",
          marginLeft: "10vw",
          marginRight: "10vw",
          height: "100vh",
          // border: "1px solid black",
        }}>
        <div style={{ marginTop: "48vh" }}>
          <h1 style={{ textAlign: "center" }}>Initializing App...</h1>
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <SnackbarProvider>
      <AuthProvider loggedInUser={loggedInUser}>
        <DndProvider backend={HTML5Backend}>
          <ScreenRoutes />
        </DndProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default App;
