import React from "react";
import styled from "styled-components";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Logo from "../../resources/img/logo.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LocationComp = ({ loc }) => {
  console.log("loc:", loc);
  const Map = ReactMapboxGl({
    accessToken:
      "pk.eyJ1IjoibWFyY29zaGFyZHkiLCJhIjoiY2tqazc5aHlrMGJocjJzczI1bXRsOGdmdSJ9.dccrdrzWSes0GriTu0fKWw",
  });

  let navigate = useNavigate();

  return (
    <Wrapper>
      <div className="top">
        <h2>{loc.locationId}</h2>
      </div>
      <div className="middle">
        {loc?.latitude && loc?.longitude ? (
          <Map
            center={[loc.longitude, loc.latitude]}
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: "100%",
              width: "80%",
              margin: "0 10%",
              borderRadius: 10,
            }}>
            <Marker coordinates={[loc.longitude, loc.latitude]} anchor="bottom">
              <img src={Logo} style={{ width: 30 }} alt="logo" />
            </Marker>
          </Map>
        ) : (
          <div
            style={{
              height: "100%",
              width: "80%",
              margin: "0 10%",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #ccc",
            }}>
            <h4>No MapView Available 🙁</h4>
          </div>
        )}
      </div>
      <div className="bottom">
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/locations/${loc.locationId}`);
          }}>
          Manage
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 45vh;
  width: 25vw;
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 4px 4px rgba(201, 201, 201, 0.1);

  .top {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .middle {
    height: 60%;
  }
  .bottom {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default LocationComp;
