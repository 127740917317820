import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Navigation from "../navigation/Navigation";
import { MenuItem, Select, Toolbar, TextField, Button } from "@mui/material";

import { useAdmin } from "../../context/admin_context";
import SalesComp from "./SalesComp";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { calculateTimestamps } from "../../helpers/helpers";
import { enGB } from "date-fns/locale";

const SalesScreen = () => {
  const [showSales, setShowSales] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);
  const [timePeriod, setTimePeriod] = useState({
    startTimestamp: null,
    endTimestamp: null,
  });

  const { locations, selectedStore, setSelectedStore } = useAdmin();

  useEffect(() => {
    const timeStamps = calculateTimestamps("today");
    setSelectedStore("HEAD-OFFICE");
    setTimePeriod({
      startTimestamp: timeStamps.startTimestamp,
      endTimestamp: timeStamps.endTimestamp,
    });
  }, []);

  useEffect(() => {
    if (
      timePeriod.startTimestamp &&
      timePeriod.endTimestamp &&
      selectedStore &&
      !hasRendered
    ) {
      console.warn("fetching sales");
      setShowSales(true);
      setHasRendered(true);
    }
  }, [timePeriod, selectedStore]);

  const onSelectStore = (e) => {
    setSelectedStore(e.target.value);
  };

  return (
    <Wrapper>
      <Navigation />
      <div className="container">
        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "grid",
              gridTemplateColumns: "0.4fr 1fr 0.5fr 1fr 1fr 0.8fr",
              height: "10vh",
            }}>
            <div className="top-col">
              <h4 style={{ color: "#444" }}>Store:</h4>
            </div>
            <div className="top-col">
              <Select
                style={{ width: "98%", margin: "1%" }}
                id="store-dropdown"
                value={selectedStore || ""}
                label="Age"
                onChange={onSelectStore}>
                <MenuItem value={"HEAD-OFFICE"}>All Stores</MenuItem>
                {locations &&
                  locations?.map((location, i) => {
                    return (
                      <MenuItem key={i} value={location.locationId}>
                        {location?.locationName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>

            <div className="top-col">
              <h4>Time Period:</h4>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={enGB}>
              <div className="top-col">
                <DateTimePicker
                  inputFormat="DD/MM/YY  hh:mm"
                  label="Start Date"
                  showTodayButton={true}
                  value={timePeriod.startTimestamp}
                  onChange={(val) => {
                    setTimePeriod((oldS) => {
                      let newState = { ...oldS };
                      newState.startTimestamp = val;
                      return newState;
                    });
                  }}
                  renderInput={(params) => (
                    <TextField style={{ margin: "1%" }} {...params} />
                  )}
                />
              </div>
              <div className="top-col">
                <DateTimePicker
                  label="End Date"
                  showTodayButton={true}
                  // inputFormat="ddd DD MMM YY  hh:mm"
                  inputFormat="DD/MM/YY  hh:mm"
                  value={timePeriod.endTimestamp}
                  onChange={(val) => {
                    setTimePeriod((oldS) => {
                      let newState = { ...oldS };
                      newState.endTimestamp = val;
                      return newState;
                    });
                  }}
                  renderInput={(params) => (
                    <TextField style={{ margin: "1%" }} {...params} />
                  )}
                />
              </div>
            </LocalizationProvider>
            <div className="top-col">
              {showSales ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowSales(false);
                  }}>
                  Go Back
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    // console.log("timePeriod", timePeriod);
                    if (!selectedStore) {
                      alert("Please select a store to view sales for");
                      return;
                    }
                    setShowSales(true);
                  }}>
                  Get Sales
                </Button>
              )}
            </div>
          </Toolbar>
        </div>

        {showSales ? (
          <SalesComp timePeriod={timePeriod} />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              // border: "1px solid black",
            }}>
            <h4 style={{ marginRight: "5%", color: "#444" }}>
              Select a store and time period to view sales for
            </h4>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // height: 10vh;
  display: flex;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .loading-container {
    margin: 2.5vw;
    display: flex;
  }
  .loader {
    margin: 2%;
    border-radius: 15px;
    overflow: hidden;
  }
  .top-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default SalesScreen;
