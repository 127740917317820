import React, { useEffect, useState, useRef } from "react";
import {
  ListItemButton,
  ListItem,
  ListItemText,
  Button,
  Divider,
} from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import { useSnackbar } from "notistack";

const ItemTypes = {
  LIST: "list",
};

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};
export const DnDItemOptions = ({
  id,
  opt,
  i,
  index,
  moveCard,
  setRawSelectedOptionCopy,
  selectedOption,
  setSelectedOption,
  productOptionsCopy,
  resetProductOption,
  updateProductOptionsDisplayOrderFn,
  hasEdited,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.LIST,
    drop: () => {
      console.log("DROPPEDDD");
      updateProductOptionsDisplayOrderFn(productOptionsCopy);
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.LIST,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  console.log("i:", i);
  console.log("selectedOption.index:", selectedOption.index);

  console.log("i === selectedOption.index:", i === selectedOption.index);

  return (
    <>
      <ListItem
        ref={ref}
        key={i}
        data-handler-id={handlerId}
        divider
        style={
          i === selectedOption.index
            ? { backgroundColor: "rgba(25,25,25,0.1)" }
            : null
        }
        onClick={() => {
          if (hasEdited) {
            enqueueSnackbar(`You have unsaved changes`, {
              variant: "warning",
              action: () => {
                return (
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={resetProductOption}>
                    Reset
                  </Button>
                );
              },
            });
            // resetProductOption();
          } else {
            let option = { ...opt };
            option.index = i;
            console.log("setting selected option to:", option);
            setSelectedOption(option);
            setRawSelectedOptionCopy(JSON.parse(JSON.stringify(option)));
          }
        }}>
        <ListItemButton role={"list"} dense>
          <ListItemText primary={opt.optionName} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};
