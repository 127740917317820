import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAdmin } from "../../context/admin_context";
import styled from "styled-components";
import {
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableContainer,
  Toolbar,
  TextField,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  InputAdornment,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Navigation from "../navigation/Navigation";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import _ from "lodash";
import {
  addProductOptions,
  addProductVariation,
  deleteProductVariation,
  fetchEposGroups,
  fetchProducts,
  fetchSingleCategory,
  formatProductForRequest,
  formatProductResp,
  getAproduct,
  getTaxGroups,
  removeOptionFromProduct,
  removeOptionGroupFromProduct,
  updateProduct,
  updateProductOption,
  updateProductOptionsDisplayOrder,
  updateProductVariation,
} from "../../helpers/helpers";
import Loader from "../../components/Loader";
import { colors } from "../../constants/constants";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ViewOptions from "../../components/ViewOptions";
import ViewProducts from "../../components/ViewProducts";
import { useSnackbar } from "notistack";
import ViewVariations from "../../components/ViewVariations";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import RefreshIcon from "@mui/icons-material/Refresh";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

const SingleProduct = () => {
  const [product, setProduct] = useState(null);
  const [rawProduct, setRawProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [inputDisabled, setInputDisabled] = useState([true, true, true, true]);
  const [value, setValue] = useState(0);
  const [taxGroups, setTaxGroups] = useState(null);

  const [attributeModalVisible, setAttributeModalVisible] = useState(false);
  const [optionInUserDialog, setOptionInUseDialog] = useState({
    show: false,
    data: null,
  });
  const [newProductAttribute, setNewProductAttribute] = useState({
    name: "",
    value: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let { productId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  let location = useLocation();

  const getAproductHelper = async () => {
    getAproduct(
      productId,
      setProduct,
      setRawProduct,
      setIsLoading,
      enqueueSnackbar
    );
  };

  useEffect(() => {
    getAproductHelper();
    getTaxGroups(setTaxGroups, setIsLoading, enqueueSnackbar);
  }, []);

  useEffect(() => {
    if (_.isEqual(product, rawProduct)) {
      setHasChanged(false);

      // Alert.alert("is equal")
    } else {
      setHasChanged(true);
      // Alert.alert("is not equal")
    }
  }, [product]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  if (isLoading) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader />
      </div>
    );
  }

  const confirmProductOptions = (selectedOptions) => {
    console.log("productId", productId);
    console.log("selectedOptions", selectedOptions);
    addProductOptions(
      productId,
      selectedOptions,
      setProduct,
      setIsLoading,
      enqueueSnackbar
    );
  };
  const removeOptionGroupFromProductFn = (optionId, optionIndex) => {
    console.log("optionId", optionId);
    if (
      window.confirm(
        "Are you sure you want to delete this option group from this product. This action can't be undone "
      ) == true
    ) {
      removeOptionGroupFromProductHelper(optionId, optionIndex);
    }
  };

  const removeOptionGroupFromProductHelper = async (optionId, optionIndex) => {
    setIsLoading(true);
    const deleteOptionResp = await removeOptionGroupFromProduct(
      productId,
      optionId,
      optionIndex,
      enqueueSnackbar
    );

    if (deleteOptionResp) {
      if (deleteOptionResp?.optionInUse) {
        setOptionInUseDialog({
          show: true,
          data: deleteOptionResp?.variationsUsingOption,
        });
        enqueueSnackbar(
          `This option is currently in use by ${deleteOptionResp?.variationsUsingOption?.length} variation and can not be deleted`,
          {
            variant: "warning",
          }
        );
      }
      if (deleteOptionResp?.optionDeleted) {
        enqueueSnackbar("Successfully deleted product", {
          variant: "success",
        });

        console.log("deleteOptionResp: ", deleteOptionResp);
        const formattedProduct = formatProductResp(deleteOptionResp?.product);
        console.log("formattedProduct: ", formattedProduct);
        setProduct(formattedProduct);
      }
    }
    setIsLoading(false);
  };

  const removeOptionFromProductFn = async (optionId, optionIndex) => {
    console.log("optionId", optionId);
    console.log("optionIndex", optionIndex);
    if (
      window.confirm(
        "Are you sure you want to delete this option from this product. This action can't be undone "
      ) == true
    ) {
      await removeOptionFromProduct(
        productId,
        optionId,
        optionIndex,
        setProduct,
        setIsLoading,
        enqueueSnackbar
      );
    }
  };

  const updateProductOptionsDisplayOrderFn = (updatedOptions) => {
    updateProductOptionsDisplayOrder(
      productId,
      updatedOptions,
      setProduct,
      setIsLoading,
      enqueueSnackbar
    );
  };

  const updateProductHelper = async (updatedProduct) => {
    console.log("updatedProduct:", updatedProduct);
    const formattedProduct = formatProductForRequest(updatedProduct);

    updateProduct(
      formattedProduct,
      setRawProduct,
      setProduct,
      setIsLoading,
      enqueueSnackbar
    );
  };

  const removeAttributeFn = (index) => {
    if (
      window.confirm(
        "Are you sure you want to delete this attribute. This action can't be undone "
      ) == true
    ) {
      let updatedProduct = { ...product };
      updatedProduct.attributes.splice(index, 1);
      updateProductHelper(updatedProduct);
    }
  };

  const updateProductFn = () => {
    let updatedProduct = { ...product };

    if ("attributes" in updatedProduct) {
      updatedProduct.attributes.push(newProductAttribute);
    } else {
      updatedProduct.attributes = [newProductAttribute];
    }

    setAttributeModalVisible(false);
    setNewProductAttribute({
      name: "",
      value: "",
    });

    updateProductHelper(updatedProduct);
  };

  console.log("product?.stockEnabled:", product?.stockEnabled);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <Dialog
          style={{ width: "50%", margin: "0 auto" }}
          fullWidth
          maxWidth
          open={optionInUserDialog.show}
          onClose={() => {
            setOptionInUseDialog({ show: false, data: null });
          }}>
          <DialogTitle>Product In Use</DialogTitle>
          <div
            style={{
              // border: "1px solid black",
              borderTop: "1px solid gray",
              height: "60vh",
              width: "100%",
              padding: "10% 10% 0 10%",
              borderBottom: "1px dashed gray",
              backgroundColor: "#f7f7f7",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "center",
              overflow: "scroll",
              position: "relative",
            }}>
            <h4 style={{ position: "absolute", left: "2%", top: "2%" }}>
              <u>Variations Using this option</u>
            </h4>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Variation ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {optionInUserDialog?.data &&
                    optionInUserDialog.data.length > 0 &&
                    optionInUserDialog.data.map((data, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell component="th" scope="row">
                            {data}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <span
              style={{
                position: "absolute",
                left: "5%",
                bottom: "5%",
                fontSize: "13px",
                padding: "0 10%",
                textAlign: "center",
              }}>
              To delete this option, delete these instances from each variation
              which uses this option
            </span>
          </div>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOptionInUseDialog({ show: false, data: null });
              }}
              style={{ width: "30%" }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={attributeModalVisible}
          onClose={() => setAttributeModalVisible(false)}>
          <DialogTitle>Add New Attribute</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Attribute Group"
              type="name"
              value={newProductAttribute.name}
              onChange={(e) => {
                setNewProductAttribute((oldS) => ({
                  ...oldS,
                  name: e.target.value,
                }));
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Attribute Value"
              value={newProductAttribute.value}
              onChange={(e) => {
                setNewProductAttribute((oldS) => ({
                  ...oldS,
                  value: e.target.value,
                }));
              }}
              type="name"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAttributeModalVisible(false)}>
              Cancel
            </Button>
            <Button onClick={updateProductFn}>Confirm</Button>
          </DialogActions>
        </Dialog>

        <div className="nav-container">
          <Toolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            <h2 style={{ marginLeft: "30%" }}>Viewing Product</h2>
            <IconButton
              onClick={getAproductHelper}
              style={{ position: "absolute", right: "15%" }}
              aria-label="expand row">
              <RefreshIcon style={{ fontSize: 30 }} />
            </IconButton>
            {hasChanged && (
              <IconButton
                onClick={() => {
                  updateProductHelper({ ...product });
                }}
                style={{ position: "absolute", right: "10%" }}
                aria-label="expand row">
                <SaveAsIcon style={{ fontSize: 30 }} />
              </IconButton>
            )}
          </Toolbar>
        </div>
        <div className="content-container">
          {product ? (
            <>
              <div className="tab-container-top">
                <h2 style={{ margin: "2.5vh 0" }}>{product?.productName}</h2>
                <Tabs
                  style={{
                    // boxShadow: "0px 15px 15px -1px rgba(0,0,0,0.06)",
                    borderBottom: "1px solid #ccc",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  centered
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example">
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Options" {...a11yProps(1)} />
                  <Tab label="Variations" {...a11yProps(2)} />
                  <Tab label="Copy Product" {...a11yProps(3)} />
                </Tabs>
              </div>

              <div className="tab-container-bottom">
                <TabPanel value={value} index={0} style={{ margin: "3% 0" }}>
                  <Accordion
                    style={{
                      width: "80%",
                      marginLeft: "10%",
                      marginRight: "10%",
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <div
                        style={{
                          height: "5vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}>
                        <h2>
                          <u>Product Information</u>
                        </h2>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="product-info-container">
                        <div className="product-info-row">
                          <TextField
                            style={{ flex: 1, margin: "0 2%" }}
                            id="outlined-basic-1"
                            label="Product ID"
                            fullWidth
                            disabled
                            variant="outlined"
                            value={product?.productId}
                            onChange={(e) => {
                              const text = e.target.value;
                              setProduct((oldS) => {
                                let newState = { ...oldS };
                                newState.productId = text;
                                return newState;
                              });
                            }}
                          />
                          <TextField
                            style={{ flex: 1, margin: "0 2%" }}
                            id="outlined-basic-2"
                            label="Product Name"
                            variant="outlined"
                            fullWidth
                            value={product?.productName}
                            onChange={(e) => {
                              const text = e.target.value;
                              setProduct((oldS) => {
                                let newState = { ...oldS };
                                newState.productName = text;
                                return newState;
                              });
                            }}
                          />
                          <TextField
                            style={{ flex: 2, margin: "0 2%" }}
                            id="outlined-basic-2"
                            label="Product Description"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={product?.productDescription}
                            onChange={(e) => {
                              const text = e.target.value;
                              setProduct((oldS) => {
                                let newState = { ...oldS };
                                newState.productDescription = text;
                                return newState;
                              });
                            }}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    style={{
                      width: "80%",
                      marginLeft: "10%",
                      marginRight: "10%",
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <div
                        style={{
                          height: "5vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}>
                        <h2>
                          <u>Product Stock</u>
                        </h2>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="product-info-container">
                        <div className="product-info-row">
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              margin: "0 2%",
                            }}>
                            <h4>Stock Enabled</h4>
                            <Switch
                              checked={product?.stockEnabled}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setProduct((oldS) => {
                                  let newState = { ...oldS };
                                  newState.stockEnabled = checked;
                                  return newState;
                                });
                              }}
                            />
                          </div>
                          <TextField
                            style={{ flex: 1, margin: "0 2%" }}
                            id="outlined-basic-2"
                            label="Product SKU"
                            variant="outlined"
                            fullWidth
                            disabled={!product?.stockEnabled}
                            value={product?.externalSku}
                            onChange={(e) => {
                              const text = e.target.value;
                              setProduct((oldS) => {
                                let newState = { ...oldS };
                                newState.externalSku = text;
                                return newState;
                              });
                            }}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    style={{
                      width: "80%",
                      marginLeft: "10%",
                      marginRight: "10%",
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <div
                        style={{
                          height: "5vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}>
                        <h2>
                          <u>Product Pricing</u>
                        </h2>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="product-info-container">
                        <div className="product-info-row">
                          <TextField
                            style={{
                              flex: 1,
                              margin: "0 2%",
                            }}
                            id="outlined-basic-3"
                            label="Unit Cost"
                            variant="outlined"
                            value={product?.unitCost}
                            type="number"
                            onChange={(e) => {
                              const text = e.target.value;
                              setProduct((oldS) => {
                                let newState = { ...oldS };
                                newState.unitCost = text;
                                return newState;
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            style={{ flex: 1, margin: "0 2%" }}
                            id="outlined-basic-3"
                            label="Takeaway Price"
                            variant="outlined"
                            value={product?.takeawayPrice}
                            type="number"
                            onChange={(e) => {
                              const text = e.target.value;
                              setProduct((oldS) => {
                                let newState = { ...oldS };
                                newState.takeawayPrice = text;
                                return newState;
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            style={{ flex: 1, margin: "0 2%" }}
                            id="outlined-basic-3"
                            label="Eat In Price"
                            variant="outlined"
                            value={product?.eatInPrice}
                            type="number"
                            onChange={(e) => {
                              const text = e.target.value;
                              setProduct((oldS) => {
                                let newState = { ...oldS };
                                newState.eatInPrice = text;
                                return newState;
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    style={{
                      width: "80%",
                      marginLeft: "10%",
                      marginRight: "10%",
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <div
                        style={{
                          height: "5vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}>
                        <h2>
                          <u>Product Tax</u>
                        </h2>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="product-info-container">
                        <div className="product-info-row">
                          <FormControl style={{ flex: 1, margin: "0 2%" }}>
                            <InputLabel required id="eat-in-tax-group-label">
                              Takeaway Tax Group
                            </InputLabel>
                            <Select
                              labelId="eat-in-tax-group-label"
                              id="eat-in-tax-group-select"
                              variant="standard"
                              value={product.takeawayTaxGroup}
                              label="Tax Rate"
                              onChange={(event) => {
                                console.log("event:", event);
                                setProduct((oldS) => {
                                  let newState = { ...oldS };
                                  newState.takeawayTaxGroup =
                                    event.target.value;
                                  return newState;
                                });
                              }}>
                              {taxGroups &&
                                taxGroups.map((group, i) => {
                                  return (
                                    <MenuItem value={group?.taxId}>
                                      {group?.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                          <FormControl style={{ flex: 1, margin: "0 2%" }}>
                            <InputLabel required id="eat-in-tax-group-label">
                              Eat In Tax Group
                            </InputLabel>
                            <Select
                              labelId="eat-in-tax-group-label"
                              id="eat-in-tax-group-select"
                              variant="standard"
                              value={product.eatInTaxGroup}
                              // label="Tax Rate"
                              onChange={(event) => {
                                console.log("event:", event);
                                setProduct((oldS) => {
                                  let newState = { ...oldS };
                                  newState.eatInTaxGroup = event.target.value;
                                  return newState;
                                });
                              }}>
                              {taxGroups &&
                                taxGroups.map((group, i) => {
                                  return (
                                    <MenuItem value={group?.taxId}>
                                      {group?.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    style={{
                      width: "80%",
                      marginLeft: "10%",
                      marginRight: "10%",
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <div
                        style={{
                          height: "5vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}>
                        <h2>
                          <u>Product Attributes</u>
                        </h2>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="attributes-container">
                        <FormHelperText>
                          Attributes are used to apply discounts and for sales
                          reporting.
                        </FormHelperText>
                        {product?.attributes ? (
                          <div style={{ width: "100%" }}>
                            {product?.attributes.map((attribute, i) => {
                              return (
                                <div key={i} className="attribute-container">
                                  <h4>Attribute {i + 1}:</h4>
                                  <TextField
                                    id="outlined-basic"
                                    label="Attribute Group"
                                    variant="outlined"
                                    defaultValue={attribute?.name || ""}
                                  />
                                  <TextField
                                    id="outlined-basic"
                                    label="Attribute Value"
                                    variant="outlined"
                                    defaultValue={attribute?.value || ""}
                                  />
                                  <IconButton
                                    onClick={() => removeAttributeFn(i)}>
                                    <DeleteIcon style={{ color: "red" }} />
                                  </IconButton>
                                </div>
                              );
                            })}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "20px 0",
                              }}>
                              <Button
                                onClick={() => setAttributeModalVisible(true)}
                                variant="contained"
                                style={{
                                  width: 200,
                                }}>
                                Add Attribute
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}>
                            <h2>This Product has no attributes</h2>
                            <Button
                              onClick={() => setAttributeModalVisible(true)}
                              variant="contained"
                              style={{ width: 200, marginTop: 10 }}>
                              Add Attribute
                            </Button>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </TabPanel>
                <TabPanel value={value} index={1} style={{ height: "100%" }}>
                  <ViewOptions
                    removeOptionGroupFromProductFn={
                      removeOptionGroupFromProductFn
                    }
                    removeOptionFromProductFn={removeOptionFromProductFn}
                    confirmProductOptions={confirmProductOptions}
                    productId={productId}
                    setProduct={setProduct}
                    updateProductOptionsDisplayOrderFn={
                      updateProductOptionsDisplayOrderFn
                    }
                    product={product}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} style={{ height: "100%" }}>
                  <ViewVariations product={product} setProduct={setProduct} />
                </TabPanel>
                <TabPanel value={value} index={3} style={{ height: "100%" }}>
                  <ViewVariations product={product} setProduct={setProduct} />
                </TabPanel>
              </div>
            </>
          ) : (
            <h3>Something went wrong trying to get product</h3>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 10vh;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60%;
    width: 20vw;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
  }
  .content-container {
    display: flex;
    width: 100%;
    height: 90vh;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #f7f7f7;
    /* border: 1px solid red; */
  }

  .tab-container-top {
    width: 100%;
    /* height: 20%; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: #fff;
  }

  .tab-container-bottom {
    /* height: 85vh; */
    width: 100%;
    height: 100%;
    /* display: flex; */
  }

  .product-info-container {
    /* height: 20vh; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-d */
  }
  .product-info-row {
    /* height: 10vh; */
    width: 100%;
    padding: 5% 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .attributes-container {
    /* height: 80%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .attribute-container {
    width: 80%;
    margin: 0 10%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f7f7f7;
    border-radius: 20px;
    margin-top: 1vh;
  }

  .product-row {
    min-height: 20vh;
    width: 80%;
    min-height: 200px;
    margin: 0 10% 5% 10%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-tems: center;
    align-items: center;
    background-color: #fff;
    /* justify-content: space-around; */
  }
  .product-row-bottom {
    width: 100%;
  }
`;

export default SingleProduct;
