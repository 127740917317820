import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  Toolbar as MuiToolbar,
  Button,
  Box,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  Link,
  RadioGroup,
  IconButton,
  Tabs,
  Tab,
  InputLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Chip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getCustomers } from "../../helpers/helpers";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import RefreshIcon from "@mui/icons-material/Refresh";
import LocationBreadCrumb from "../../components/LocationBreadCrumb";
import Navigation from "../navigation/Navigation";
import StorefrontIcon from "@mui/icons-material/Storefront";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import InfoIcon from "@mui/icons-material/Info";

import Loader from "../../components/Loader";

const Customers = () => {
  const [page, setPage] = useState(0);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [isLoading, setIsLoading] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [numOfCustomers, setNumOfCustomers] = useState(null);

  const rowsPerPage = 75;

  const { enqueueSnackbar } = useSnackbar();

  const handleChangePage = async (event, newPage) => {
    if (newPage > page && newPage > lastFetchedPage) {
      let lastCustomer = customers.at(-1)?.created_at;
      console.log("lastCustomer:", lastCustomer);
      console.log("newPage:", newPage);
      const respData = await getCustomers(enqueueSnackbar, lastCustomer);
      if (respData) {
        setLastFetchedPage(newPage);
        setCustomers((oldS) => {
          let newState = [...oldS, ...respData.customers];
          return newState;
        });
        setPage(newPage);
      }
    } else {
      setPage(newPage);
    }
  };

  const getCustomersHelper = async () => {
    setIsLoading(true);
    const respData = await getCustomers(enqueueSnackbar);
    if (respData) {
      setCustomers(respData.customers);
      setNumOfCustomers(respData.num_of_customers);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomersHelper();
  }, []);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Navigation />

      <div className="container">
        <div className="nav-container">
          <MuiToolbar
            className="navBar"
            style={{
              // backgroundColor: colors.color1,
              display: "flex",
              height: "10vh",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}>
            <LocationBreadCrumb />
            <h2
              style={{
                marginLeft: "25%",
                color: "#444",
                fontFamily: "'Heebo', sans-serif",
              }}>
              Customers
            </h2>
            <IconButton
              onClick={() => {
                getCustomers(setCustomers, setIsLoading, enqueueSnackbar);
              }}
              style={{ position: "absolute", right: "15%" }}
              aria-label="expand row">
              <RefreshIcon style={{ fontSize: 30 }} />
            </IconButton>
          </MuiToolbar>
        </div>
        <div className="content-container">
          <Box sx={{ width: "95%", margin: "0 2.5%" }}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ height: "80vh" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Joined</TableCell>
                      <TableCell>Last Seen</TableCell>
                      <TableCell>UID</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers &&
                      customers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          return (
                            <TableRow
                              key={i}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}>
                              <TableCell>{row?.name}</TableCell>
                              <TableCell>
                                {new Date(row?.created_at).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </TableCell>
                              <TableCell>
                                {new Date(row?.updated_at).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </TableCell>
                              <TableCell style={{ overflow: "hidden" }}>
                                {row?.uid}
                              </TableCell>
                              <TableCell>{row?.email}</TableCell>

                              <TableCell>
                                <IconButton>
                                  <MoreHorizIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[75]}
                component="div"
                count={numOfCustomers || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </Paper>
          </Box>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .nav-container {
    // position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    background-color: rgb(247, 250, 253);
    // border: 1px solid black;
  }

  .select {
    height: 60px;
    width: 90%;
    margin: 0 5%;
    &:focus {
      outline: none;
      display: none;
    }
  }
  .navBar {
    border-bottom: 0.5px solid #ccc;
    box-shadow: 0px 5px 15px 5px rgba(55, 55, 55, 0.05);
  }

  .container {
    width: 100%;
  }
  .content-container {
    background-color: #f7f7f7;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* border: 1px solid red; */
  }
`;

export default Customers;
