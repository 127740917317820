import React, { useState } from "react";
import { Form } from "rsuite";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { TextField, Button, InputAdornment } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Loader from "../components/Loader";
import { useSnackbar } from "notistack";
import { useAuth } from "../context/auth_context";
import axios from "axios";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    newPassword1: "",
    newPassword2: "",
  });

  const { setUser } = useAuth();

  const REACT_APP_API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  let { state } = useLocation();

  const { oobCode, continueUrl, lang } = state;

  console.log("actionCode type:", oobCode);
  console.log("continueUrl:", continueUrl);
  console.log("lang:", lang);

  const handleResetPassword = async () => {
    if (
      formValue?.newPassword1?.length === 0 ||
      formValue?.newPassword2?.length === 0
    ) {
      enqueueSnackbar("New password must not be empty", {
        variant: "warning",
      });
      return;
    }

    if (formValue?.newPassword1 !== formValue?.newPassword2) {
      enqueueSnackbar("Passwords do not match", {
        variant: "warning",
      });
      return;
    }

    setIsLoading(true);

    let firebaseConfig = {
      apiKey: "AIzaSyAaB_bnG6wr6JEInc6wWC5PH9gQC2ck5w4", // Copy this key from the web initialization
      // snippet found in the Firebase console.
    };
    const appName = "app" + String(new Date().getTime());
    const app = firebase.initializeApp(firebaseConfig, appName);
    const auth = app.auth();

    auth
      .verifyPasswordResetCode(oobCode)
      .then((email) => {
        console.log(email);
        const newPassword = formValue.newPassword1;
        console.log("newPass:", newPassword);
        auth
          .confirmPasswordReset(oobCode, newPassword)
          .then((resp) => {
            console.log("resp reset pass:", resp);
            // Password reset has been confirmed and new password updated.
            // TODO: Display a link back to the app, or sign-in the user directly
            setIsLoading(false);
            enqueueSnackbar("Successfully changed your password", {
              variant: "success",
            });
            //delete app before pushing
            app.delete();

            handleLoginSubmit(email, newPassword);
            // history.push(`${baseName}/login`);
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            // perform login funtction to obtain token and then send this to app via url parameter
          })
          .catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            app.delete();
            setIsLoading(false);
            console.log(error?.message);
            enqueueSnackbar(error?.message, {
              variant: "error",
            });
          });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        app.delete();
        setIsLoading(false);
        console.log("error:", error);
        enqueueSnackbar(
          "Invalid or expired link. Please ask your admin to reset your password",
          {
            variant: "error",
          }
        );
      });
  };

  const handleLoginSubmit = async (email, pass) => {
    try {
      setIsLoading(true);

      const resp = await axios.post(
        `${REACT_APP_API_ADDRESS}/login_user_by_email_password`,
        { email: email, password: pass, loggedInFromInvite: true }
      );

      console.log("login_user resp object: ", resp);

      if (resp.status === 200) {
        const authToken = resp.data.token;

        // save token to local storage
        window.localStorage.setItem("auth_token", authToken);

        setIsLoading(false);

        // save user and token to context

        await setUser(
          resp?.data?.acceptedInvitation,
          resp?.data?.name,
          resp?.data?.email,
          resp?.data?.emailVerified,
          resp?.data?.createdAt,
          resp?.data?.updatedAt,
          resp?.data?.deviceTokens,
          resp?.data?.uid,
          resp?.data?.token,
          resp?.data?.firebaseToken,
          resp?.data?.claims
        );

        navigate("/sales", { replace: true });

        //show success alert
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      enqueueSnackbar("Something went wrong when trying to log you in", {
        variant: "error",
      });
    }
  };

  const handleChange = (value) => {
    setFormValue(value);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}

      <div className="row-top">
        <h2>Reset Password</h2>
      </div>
      <div className="row-bottom">
        {/* <Box sx={{ "& > :not(style)": { m: 1 } }}> */}
        <TextField
          id="newPassword"
          label=" Enter Your New Password"
          type="password"
          autoComplete
          onChange={(e) => {
            let text = e.target.value;
            setFormValue((oldS) => {
              let newState = { ...oldS };
              newState.newPassword1 = text;
              return newState;
            });
          }}
          value={formValue.newPassword1}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />

        <TextField
          id="newPassword2"
          label="Confirm Your New Password"
          type="password"
          onChange={(e) => {
            let text = e.target.value;
            setFormValue((oldS) => {
              let newState = { ...oldS };
              newState.newPassword2 = text;
              return newState;
            });
          }}
          value={formValue.newPassword2}
          autoComplete
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        {/* </Box> */}
        <Button variant="contained" onClick={handleResetPassword}>
          Confirm
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15vh 0;

  .row-top {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row-bottom {
    flex: 0.8;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
`;

export default ResetPassword;
