import React, { useEffect, useState, useCallback } from "react";
import {
  fetchOptions,
  fetchProducts,
  getStoreGroups,
  updateProductOption,
} from "../helpers/helpers";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  FormControl,
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Radio,
  FormControlLabel,
  RadioGroup,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "./Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import update from "immutability-helper";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DnDItemOptions } from "./DnDItemOptions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useAdmin } from "../context/admin_context";
import DisplayInStores from "./DisplayInStores";
import GlobalDisplayInStores from "./GlobalDisplayInStores";

const ViewProductOptions = ({
  productOptions,
  setProduct,
  productId,
  updateProductOptionsDisplayOrderFn,
  removeOptionGroupFromProductFn,
  removeOptionFromProductFn,
}) => {
  const [storeGroups, setStoreGroups] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasEdited, setHasEdited] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    ...productOptions[0],
  });
  const [rawSelectedOptionCopy, setRawSelectedOptionCopy] = useState(
    JSON.parse(JSON.stringify(productOptions[0]))
  );
  const [productOptionsCopy, setProductOptionsCopy] = useState(
    JSON.parse(JSON.stringify(productOptions))
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getStoreGroups(setStoreGroups, setIsLoading, enqueueSnackbar);
  }, []);

  useEffect(() => {
    if (_.isEqual(selectedOption, rawSelectedOptionCopy)) {
      setHasEdited(false);
      // Alert.alert("is equal")
    } else {
      setHasEdited(true);
      // Alert.alert("is not equal")
    }
  }, [selectedOption]);

  const resetProductOption = () => {
    let selectedOptionIndex = 0;
    if (selectedOption?.index) {
      selectedOptionIndex = selectedOption?.index;
    }
    setSelectedOption({ ...productOptions[selectedOptionIndex] });
    setRawSelectedOptionCopy(
      JSON.parse(JSON.stringify(productOptions[selectedOptionIndex]))
    );
    setProductOptionsCopy(JSON.parse(JSON.stringify(productOptions)));
  };

  const updateProductOptionFn = async (updatedOption) => {
    updatedOption.optionItems.map((item) => {
      console.log("updateProductOptionFn item: ", item);
      if (item?.priceModifierType !== "PERCENT") {
        item.priceModifier = Number(item.priceModifier) * 100;
      } else {
        item.priceModifier = Number(item.priceModifier);
      }
    });
    console.log("updatedOption:", updatedOption);
    setIsLoading(true);
    const resp = await updateProductOption(
      productId,
      updatedOption,
      enqueueSnackbar
    );

    if (resp) {
      let selectedOptionIndex = resp?.productOptions[updatedOption.index];
      console.log("selectedOptionIndex:", selectedOptionIndex);
      setSelectedOption(selectedOptionIndex);
      setProductOptionsCopy(JSON.parse(JSON.stringify(resp?.productOptions)));
      setRawSelectedOptionCopy(JSON.parse(JSON.stringify(selectedOptionIndex)));
      setHasEdited(false);

      console.log("resp:", resp);
      setProduct(resp);
    }
    setIsLoading(false);
  };

  console.log("selectedOption:", selectedOption?.optionType);

  const addOption = () => {
    setSelectedOption((oldS) => {
      let newState = { ...oldS };
      newState?.optionItems.push({
        itemName: "",
        priceModifierType: "ABSOLUTE",
        priceModifier: "0",
        displayInStores: ["all_stores"],
      });
      return newState;
    });
    // setSelectedOption((oldS) => {
    //   let newState = { ...oldS };
    //   newState?.optionItems.push({
    //     itemName: "",
    //     priceModifierType: "ABSOLUTE",
    //     priceModifier: "0",
    //   });
    //   return newState;
    // });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    console.log("dragIndex:", dragIndex);
    console.log("hoverIndex:", hoverIndex);
    setProductOptionsCopy((prevOpt) => {
      return update(prevOpt, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevOpt[dragIndex]],
        ],
      });
    });
  }, []);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <div className="product-options-container">
        <div className="col-left">
          <div
            style={{
              borderBottom: "1px dashed #ccc",
              backgroundColor: "#f7f7f7",
              height: "15%",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <h3>Options:</h3>
          </div>

          <List>
            {productOptionsCopy.map((opt, i) => {
              return (
                <DnDItemOptions
                  i={i}
                  opt={opt}
                  key={`till-dnd-${i}`}
                  index={i}
                  id={`till-dnd-${i}`}
                  moveCard={moveCard}
                  updateDisplayOrderFn={() => {}}
                  setSelectedOption={setSelectedOption}
                  setRawSelectedOptionCopy={setRawSelectedOptionCopy}
                  selectedOption={selectedOption}
                  productOptionsCopy={productOptionsCopy}
                  resetProductOption={resetProductOption}
                  hasEdited={hasEdited}
                  updateProductOptionsDisplayOrderFn={
                    updateProductOptionsDisplayOrderFn
                  }
                />
              );
            })}
          </List>
        </div>
        <div className="col-right">
          <div className="col-right-top">
            <div className="col-right-top-col">
              {/* <h3>Option Name:</h3> */}
              <TextField
                style={{ width: "80%" }}
                label="Option Name"
                variant="outlined"
                value={selectedOption.optionName}
                onChange={(e) => {
                  setSelectedOption((oldS) => {
                    let newState = { ...oldS };
                    newState.optionName = e.target.value;
                    return newState;
                  });
                }}
              />
            </div>
            <div className="col-right-top-col">
              {/* <h3>Option Name:</h3> */}
              <FormControl fullWidth>
                <InputLabel id="option-type-select-label">
                  Option Type
                </InputLabel>
                <Select
                  labelId="option-type-select-label"
                  id="option-type-select"
                  value={selectedOption?.optionType || ""}
                  // renderValue={(selected) => {
                  //   console.log("selected:", selected);
                  //   if (selected.length === 0) {
                  //     return <em>Placeholder</em>;
                  //   }

                  //   return selected;
                  // }}
                  label="Option Type"
                  onChange={(e) => {
                    setSelectedOption((oldS) => {
                      let newState = { ...oldS };
                      newState.optionType = e.target.value;
                      return newState;
                    });
                  }}>
                  <MenuItem value={"single"}>Single</MenuItem>
                  <MenuItem value={"multiple"}>Multiple</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-right-top-col">
              <div style={{ paddingTop: "4%", paddingBottom: "4%" }}>
                <GlobalDisplayInStores
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  storeGroups={storeGroups}
                />
              </div>
            </div>
            <div className="col-right-top-col">
              {/* <h3>Option Name:</h3> */}
              {hasEdited && (
                <Button
                  variant="contained"
                  onClick={() => {
                    updateProductOptionFn(selectedOption);
                  }}>
                  Update
                </Button>
              )}
              <Button
                style={{ backgroundColor: "#f54842" }}
                variant="contained"
                onClick={() => {
                  removeOptionGroupFromProductFn(
                    selectedOption.optionId,
                    selectedOption.index
                  );
                }}>
                Remove Option
              </Button>
            </div>
          </div>
          <div className="col-right-bottom">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Item Name:</TableCell>
                    <TableCell>Option Type:</TableCell>
                    <TableCell>Price Modifier Type</TableCell>
                    <TableCell>Available Stores</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedOption?.optionItems.map((opt, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          label="Item Name"
                          variant="outlined"
                          value={opt.itemName}
                          onChange={(e) => {
                            setSelectedOption((oldS) => {
                              let newState = { ...oldS };
                              newState.optionItems[i].itemName = e.target.value;
                              return newState;
                            });
                          }}
                        />
                      </TableCell>

                      <TableCell align="left">
                        <Select
                          style={{ width: "80%" }}
                          value={opt.priceModifierType}
                          label="Price Modifier Type"
                          id="price-modifier-select"
                          onChange={(e) => {
                            setSelectedOption((oldS) => {
                              let newState = { ...oldS };
                              newState.optionItems[i].priceModifierType =
                                e.target.value;
                              return newState;
                            });
                          }}>
                          <MenuItem value={"PERCENT"}>%</MenuItem>
                          <MenuItem value={"ABSOLUTE"}>£</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          style={{ width: "80%" }}
                          id="outlined-basic"
                          label="Price Modifier"
                          variant="outlined"
                          step={0.01}
                          value={opt.priceModifier}
                          onChange={(e) => {
                            setSelectedOption((oldS) => {
                              let newState = { ...oldS };
                              newState.optionItems[i].priceModifier =
                                e.target.value;
                              return newState;
                            });
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {opt.priceModifierType === "ABSOLUTE"
                                  ? "£"
                                  : "%"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <DisplayInStores
                          setSelectedOption={setSelectedOption}
                          selectedOption={selectedOption}
                          optionIndex={i}
                          storeGroups={storeGroups}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => {
                            let currSelectedOption = { ...selectedOption };
                            removeOptionFromProductFn(
                              selectedOption.optionId,
                              i
                            );
                          }}>
                          <DeleteIcon style={{ color: "red" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}>
                <IconButton
                  onClick={addOption}
                  vari
                  aria-label="fingerprint"
                  color="primary">
                  <AddCircleIcon style={{ fontSize: "40" }} />
                </IconButton>
              </div>
            </TableContainer>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  /* border: 3px solid red; */

  .product-options-container {
    border: 1px solid #ccc;
    height: 96%;
    width: 96%;
    margin: 2%;
    border-radius: 5px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 4fr;
    overflow: hidden;
  }

  .col-left {
    border-right: 1px solid #ccc;
    overflow-y: scroll;

    /* border: 1px solid black; */
  }
  .col-right {
    overflow-y: scroll;

    /* border: 1px solid black; */
  }

  .col-right-top {
    border-bottom: 1px dashed #ccc;
    background-color: #f7f7f7;
    min-height: 15%;
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 1.5fr;
  }
  .col-right-bottom {
    /* border: 1px solid black; */
  }

  .col-right-top-col {
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
`;

export default ViewProductOptions;
